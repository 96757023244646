import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from 'mdi-material-ui/Alert';
import GlassesIcon from 'mdi-material-ui/Glasses';
import EditIcon from 'mdi-material-ui/Pencil';
import PrescriptionIcon from 'mdi-material-ui/TextBoxCheck';
import { PersonRequirementExpiration } from '../../../entities/Requirement';
import { useAuth } from '../../../hooks/store/useAuth';
import { useExpiration } from '../../../hooks/store/useExpiration';
import { useTableHiddenColumns } from '../../../hooks/store/useTableHiddenColumns';
import { expirationsRenewSection, expirationsSection as section } from '../../../utils/appSections';
import { RowActionsWrapper, formatDateForSort, useTableOptions } from '../../../utils/table';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';
import RequirementLabel from '../../RequirementLabel';
import TabbedComponents from '../../TabbedComponents';
import PersonRequirementEditDialog from '../Staff/Details/PersonRequirementEditDialog';
import FilterByPerson from './FilterByPerson';
import FilterByRequirement from './FilterByRequirement';

export type SubmitHandle = {
  submit: () => void;
};

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3 / 4),
  },
  reqName: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: theme.spacing(1),
    },

    '& strong': {
      marginRight: theme.spacing(1 / 2),
    },
  },
  email: {
    margin: 2,
  },
  batchRequirementsUpdateButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(4),
  },
}));

export default function Expirations(): JSX.Element {
  const classes = useStyles();
  const { isAdmin } = useAuth();
  const filterByRequirementRef = useRef<SubmitHandle>(null);
  const filterByPersonRef = useRef<SubmitHandle>(null);

  const tabs = useMemo(
    () => [
      {
        title: 'Requisito',
        slug: '/expirations/by_requirement',
        children: <FilterByRequirement ref={filterByRequirementRef} />,
      },
      {
        title: 'Dipendente',
        slug: '/expirations/by_person',
        children: <FilterByPerson ref={filterByPersonRef} />,
      },
    ],
    [filterByRequirementRef, filterByPersonRef]
  );
  const { queryExpirationResults, queryExpirationRequest } = useExpiration();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('expirations_table');
  const [selectedRequirement, setSelectedRequirement] = useState(null as null | PersonRequirementExpiration);

  const handleEditDialogClose = useCallback(() => {
    setSelectedRequirement(null);
    if (filterByRequirementRef.current) {
      filterByRequirementRef.current.submit();
    }
    if (filterByPersonRef.current) {
      filterByPersonRef.current.submit();
    }
  }, [setSelectedRequirement, filterByRequirementRef, filterByPersonRef]);

  const { tableOptions } = useTableOptions(
    {
      tableBodyMaxHeight: 'calc(100vh - 360px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    'Digital Roster - export scadenze.csv'
  );

  const columns = useMemo(
    () => [
      {
        label: 'Requisito',
        name: 'requirement',
        options: {
          display: !hiddenColumns?.['requirement'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => {
            const { requirement } = queryExpirationResults[dataIndex];
            return (
              <span className={classes.reqName}>
                <strong>{requirement.name}</strong>
                {requirement.opticalRequirement && ` ${requirement.opticalRequirement.name}`}
                {requirement.code && ` ${requirement.code}`}
                {requirement.needsGlasses && (
                  <Tooltip title="Necessita lenti correttive" placement="top" arrow>
                    <GlassesIcon />
                  </Tooltip>
                )}
                {requirement.detailsHR && (
                  <Tooltip title="Dettagli c/o HR" placement="top" arrow>
                    <AlertIcon />
                  </Tooltip>
                )}
              </span>
            );
          },
        },
      },
      { label: 'Matricola', name: 'id', options: { display: !hiddenColumns?.['id'] } },
      { label: 'Cognome', name: 'lastName', options: { display: !hiddenColumns?.['lastName'] } },
      { label: 'Nome', name: 'name', options: { display: !hiddenColumns?.['name'] } },
      {
        label: 'Scadenza',
        name: 'expireAt',
        options: {
          display: !hiddenColumns?.['expireAt'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <RequirementLabel requirement={queryExpirationResults[dataIndex].requirement} showDate hideIcon />
          ),
        },
      },
      {
        label: 'Numero mansioni',
        name: 'dutyCount',
      },
      {
        label: 'Anticipo notifiche',
        name: 'notificationsDays',
        options: { display: !hiddenColumns?.['notificationsDays'], sort: false },
      },
      { label: 'Note', name: 'notes', options: { display: !hiddenColumns?.['notes'], sort: false } },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <RowActionsWrapper style={{ whiteSpace: 'nowrap' }}>
              {isAdmin && (
                <Tooltip title="Modifica" placement="top" arrow>
                  <IconButton onClick={() => setSelectedRequirement(queryExpirationResults[dataIndex])}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {queryExpirationResults[dataIndex].requirement.opticalRequirement && (
                <Tooltip title="Dichiarazione rispondenza visiva" placement="top" arrow>
                  <IconButton
                    component={Link}
                    to={`/person/${queryExpirationResults[dataIndex].person.id}/visit-prescription`}
                  >
                    <PrescriptionIcon />
                  </IconButton>
                </Tooltip>
              )}
            </RowActionsWrapper>
          ),
        },
      },
    ],
    [classes.reqName, setSelectedRequirement, hiddenColumns, isAdmin, queryExpirationResults]
  );

  const data = useMemo(
    () =>
      queryExpirationResults.map((row) => ({
        requirement: `${row.requirement.name} ${row.requirement.opticalRequirement?.name || ''} ${
          row.requirement.code
        }`,
        id: row.person.id,
        lastName: row.person.lastname,
        name: row.person.name,
        expireAt: formatDateForSort(row.requirement.expireAt),
        notificationsDays: `${row.requirement.notificationsDays} giorni prima`,
        notes: row.requirement.notes,
        dutyCount: row.dutyCount,
      })),
    [queryExpirationResults]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false} sx={{ position: 'relative' }}>
        <TabbedComponents components={tabs} />
        {isAdmin && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            component={Link}
            to={expirationsRenewSection.path}
            className={classes.batchRequirementsUpdateButton}
          >
            {expirationsRenewSection.shortName}
          </Button>
        )}
        <RequestResult
          submitted={Boolean(queryExpirationRequest.params.personID || queryExpirationRequest.params.requirementID)}
          request={queryExpirationRequest}
          empty={queryExpirationResults.length === 0}
          emptyTitle="Nessun risultato"
          emptyDescription="Non sono state trovati dati corrispondenti ai filtri selezionati"
        >
          <MUIDataTable title={section.shortName} columns={columns} data={data} options={tableOptions} />
          {selectedRequirement && (
            <PersonRequirementEditDialog
              open={Boolean(selectedRequirement)}
              handleClose={handleEditDialogClose}
              person={selectedRequirement.person}
              requirement={selectedRequirement.requirement}
            />
          )}
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
