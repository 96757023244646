import { RequestError, RequestState } from '@top-solution/utils';
import { Skill } from '../../entities/Skill';

export const READ_SKILL_LIST_REQUEST = 'READ_SKILL_LIST_REQUEST';
export const READ_SKILL_LIST_SUCCESS = 'READ_SKILL_LIST_SUCCESS';
export const READ_SKILL_LIST_FAILURE = 'READ_SKILL_LIST_FAILURE';

const initialState = {
  list: [] as Skill[],
  requests: {
    readList: {
      inProgress: false,
      error: null,
      lastUpdate: null,
    } as RequestState,
  },
};

export type SkillState = typeof initialState;

export interface ReadSkillRequestAction {
  type: typeof READ_SKILL_LIST_REQUEST;
}
export interface ReadSkillSuccessAction {
  type: typeof READ_SKILL_LIST_SUCCESS;
  list: Array<Skill>;
}
export interface ReadSkillFailureAction {
  type: typeof READ_SKILL_LIST_FAILURE;
  error: RequestError;
}

type ActionType = ReadSkillRequestAction | ReadSkillSuccessAction | ReadSkillFailureAction;

export default function skillReducer(state = initialState, action: ActionType): SkillState {
  switch (action.type) {
    case READ_SKILL_LIST_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_SKILL_LIST_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_SKILL_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
