import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useAppSettings, useAppSettingsResource } from '../../hooks/store/useAppSettings';
import HeaderBar from './HeaderBar';
import MenuDrawer from './MenuDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    flexGrow: 1,
    overflow: 'auto',
  },
  main: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

type AppLayoutProps = {
  children?: Array<React.ReactNode> | React.ReactNode;
};

export default function AppLayout(props: AppLayoutProps): JSX.Element {
  const { children } = props;
  const classes = useStyles();
  const { setAppSettings } = useAppSettings();
  const [appSettings] = useAppSettingsResource();

  return (
    <div className={classes.root}>
      <HeaderBar
        miniDrawer={appSettings.miniDrawer}
        toggleDrawerSize={() => setAppSettings({ ...appSettings, miniDrawer: !appSettings.miniDrawer })}
      />
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <main className={classes.main}>{children}</main>
      </div>
      <MenuDrawer miniDrawer={appSettings.miniDrawer} />
    </div>
  );
}
