import { StampShape } from '../entities/Stamp';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class StampShapeRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/stampShapes', token);
  }
  async getList(): Promise<StampShape[]> {
    const { data } = await this.axios.get<StampShape[]>('/');
    return data;
  }
}
