import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from 'mdi-material-ui/Alert';
import GlassesIcon from 'mdi-material-ui/Glasses';
import EditIcon from 'mdi-material-ui/Pencil';
import AddIcon from 'mdi-material-ui/Plus';
import PrescriptionIcon from 'mdi-material-ui/TextBoxCheck';
import DeleteIcon from 'mdi-material-ui/TrashCan';

import { Person } from '../../../../entities/Person';
import { PersonRequirement } from '../../../../entities/Requirement';
import { useAuth } from '../../../../hooks/store/useAuth';
import { useTableHiddenColumns } from '../../../../hooks/store/useTableHiddenColumns';
import { useTableOptions, formatDateForSort, RowActionsWrapper } from '../../../../utils/table';
import RequirementLabel from '../../../RequirementLabel';
import PersonRequirementAddDialog from './PersonRequirementAddDialog';
import PersonRequirementDeleteDialog from './PersonRequirementDeleteDialog';
import PersonRequirementEditDialog from './PersonRequirementEditDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3 / 4),
    zIndex: 1,
  },
  downloadVisitPrescriptionButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(10),
    zIndex: 1,
  },
  reqName: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: theme.spacing(1),
    },

    '& strong': {
      marginRight: theme.spacing(1 / 2),
    },
  },
  emailColumn: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  email: {
    margin: 2,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

type PersonRequirementListProps = {
  person: Person | null;
};

export default function PersonRequirementList(props: PersonRequirementListProps): JSX.Element {
  const classes = useStyles();
  const { person } = props;
  const { isAdmin } = useAuth();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('person_requirements_table');
  const [selectedRequirement, setSelectedRequirement] = useState(null as null | PersonRequirement);
  const [requirementToDelete, setRequirementToDelete] = useState(null as null | PersonRequirement);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { tableOptions } = useTableOptions(
    {
      elevation: 0,
      tableBodyMaxHeight: 'calc(100vh - 342px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    person
      ? `${person.id} ${person.lastname} ${person.name} - export requisiti.csv`
      : 'Digital Roster - export requisiti.csv'
  );

  const handleEditClick = useCallback(
    (dataIndex: number) => {
      if (person?.requirements) {
        setSelectedRequirement(person?.requirements[dataIndex]);
      }
    },
    [person]
  );
  const handleEditDialogClose = useCallback(() => setSelectedRequirement(null), []);
  const handleAddDialogClick = useCallback(() => setAddDialogOpen(true), []);
  const handleAddDialogClose = useCallback(() => setAddDialogOpen(false), []);

  const handleDeleteClick = useCallback(
    (dataIndex: number) => {
      if (person?.requirements) {
        setRequirementToDelete(person?.requirements[dataIndex]);
      }
    },
    [person]
  );
  const handleDeleteDialogClose = useCallback(() => setRequirementToDelete(null), []);

  const columns = useMemo(
    () => [
      {
        label: 'Requisito',
        name: 'requirement',
        options: {
          display: !hiddenColumns?.['requirement'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => {
            if (person?.requirements) {
              const requirement = person.requirements[dataIndex];
              return (
                <span className={classes.reqName}>
                  <strong>{requirement.name}</strong>
                  {requirement.opticalRequirement && ` ${requirement.opticalRequirement.name}`}
                  {requirement.code && ` ${requirement.code}`}
                  {requirement.needsGlasses && (
                    <Tooltip title="Necessita lenti correttive" placement="top" arrow>
                      <GlassesIcon />
                    </Tooltip>
                  )}
                  {requirement.detailsHR && (
                    <Tooltip title="Dettagli c/o HR" placement="top" arrow>
                      <AlertIcon />
                    </Tooltip>
                  )}
                </span>
              );
            }
          },
        },
      },
      {
        label: 'Scadenza',
        name: 'expireAt',
        options: {
          display: !hiddenColumns?.['expireAt'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => {
            if (person?.requirements) {
              return <RequirementLabel requirement={person.requirements[dataIndex]} showDate hideIcon />;
            }
          },
        },
      },
      {
        label: 'Destinatari notifiche',
        name: 'notifications',
        options: {
          sort: false,
          display: !hiddenColumns?.['notifications'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => {
            if (person?.requirements) {
              return person.requirements[dataIndex]?.notifications?.map((email, index) => (
                <Chip label={email} key={index} variant="outlined" className={classes.email} />
              )) as React.ReactNode[];
            }
          },
        },
      },

      {
        label: 'Anticipo notifiche',
        name: 'notificationsDays',
        options: { sort: false, display: !hiddenColumns?.['notificationsDays'] },
      },
      { label: 'Note', name: 'notes', options: { sort: false, display: !hiddenColumns?.['notes'] } },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => {
            if (isAdmin && person?.requirements) {
              return (
                <RowActionsWrapper className={classes.noWrap}>
                  <Tooltip title="Modifica" placement="top" arrow>
                    <IconButton onClick={() => handleEditClick(dataIndex)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Elimina" placement="top" arrow>
                    <IconButton onClick={() => handleDeleteClick(dataIndex)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </RowActionsWrapper>
              );
            }
          },
        },
      },
    ],
    [hiddenColumns, person, classes.reqName, classes.email, classes.noWrap, isAdmin, handleEditClick, handleDeleteClick]
  );

  const data = useMemo(
    () =>
      person?.requirements?.map((requirement) => ({
        requirement: `${requirement.name} ${requirement.opticalRequirement?.name || ''} ${requirement.code || ''}`,
        expireAt: formatDateForSort(requirement.expireAt),
        notificationsDays: `${requirement.notificationsDays} giorni prima`,
        notes: requirement.notes,
      })) || [],
    [person]
  );

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAddDialogClick}
          className={classes.addButton}
        >
          Nuovo requisito
        </Button>
      )}
      {person?.requirements && person.requirements.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PrescriptionIcon />}
          className={classes.downloadVisitPrescriptionButton}
          component={Link}
          to={`/person/${person?.id}/visit-prescription`}
        >
          Dichiarazione rispondenza visiva
        </Button>
      )}
      {!person?.requirements || person.requirements.length === 0 ? (
        <Alert severity="info" variant="outlined">
          Non sono stati trovati requisiti per questo utente
        </Alert>
      ) : (
        <MUIDataTable title="" columns={columns} data={data} options={tableOptions} />
      )}
      {selectedRequirement && person && (
        <PersonRequirementEditDialog
          open={Boolean(selectedRequirement)}
          handleClose={handleEditDialogClose}
          person={person}
          requirement={selectedRequirement}
        />
      )}
      {requirementToDelete && person && (
        <PersonRequirementDeleteDialog
          open={Boolean(requirementToDelete)}
          handleClose={handleDeleteDialogClose}
          person={person}
          requirement={requirementToDelete}
        />
      )}
      {addDialogOpen && person && (
        <PersonRequirementAddDialog open={addDialogOpen} handleClose={handleAddDialogClose} person={person} />
      )}
    </>
  );
}
