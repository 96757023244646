import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import DownloadIcon from 'mdi-material-ui/Download';
import { useAuth } from '../../../hooks/store/useAuth';

const url = '/api/v1/report';

export default function DownloadReportListItem(): JSX.Element {
  const { token } = useAuth();
  const [error, setError] = useState('');
  const [inProgress, setInProgress] = useState(false);

  async function download(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (token) {
      setInProgress(true);
      setError('');
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `application/vnd.ms-excel`,
        },
      });
      if (response.status >= 400) {
        setError(`Error: ${response.status} ${response.statusText}`);
      } else {
        const data: Blob = await response.blob();
        const a = document.createElement('a');
        a.href = URL.createObjectURL(data);
        a.download = `Report Digital Roster.xlsx`;
        a.click();
      }
      setInProgress(false);
    }
  }

  return (
    <>
      <Tooltip title="Download report" placement="left" arrow>
        <ListItem button disabled={inProgress} onClick={download}>
          <ListItemIcon>{inProgress ? <CircularProgress size={24} /> : <DownloadIcon />}</ListItemIcon>
          <ListItemText>Report</ListItemText>
        </ListItem>
      </Tooltip>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
