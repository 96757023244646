import React from 'react';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import { useNotificationsEmailListResource } from '../../../hooks/store/useNotificationsEmailList';
import { manageNotifications as section } from '../../../utils/appSections';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';
import ManageNotificationsForm from './ManageNotificationsForm';

export default function ManageNotifications(): JSX.Element {
  const [notificationsEmailList, readQualityNotificationsEmailListRequest] = useNotificationsEmailListResource();
  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth="lg">
        <RequestResult request={readQualityNotificationsEmailListRequest} submitted={true}>
          {readQualityNotificationsEmailListRequest.lastUpdate && (
            <ManageNotificationsForm initialValues={notificationsEmailList} />
          )}
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
