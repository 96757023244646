import React from 'react';
import { Select, SelectProps } from '@top-solution/mui-inputs';

import { StampType } from '../../entities/Stamp';
import { useStampTypesListResource } from '../../hooks/store/useStampTypes';

type StampTypeSelectProps = Omit<SelectProps<string, StampType>, 'options' | 'request' | 'renderOption'>;

export default function StampTypeSelect(props: StampTypeSelectProps): JSX.Element {
  const { label, ...otherProps } = props;
  const [options, request] = useStampTypesListResource();

  return (
    <Select
      {...otherProps}
      label={label || 'Tipo'}
      options={options}
      request={request}
      renderOption={({ name }) => name}
    />
  );
}
