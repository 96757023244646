import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MiniMenuIcon from 'mdi-material-ui/DotsVertical';
import FullMenuIcon from 'mdi-material-ui/FormatListBulleted';

import { Logo } from '../../utils/Logo';
import Spacer from '../../utils/Spacer';

type HeaderBarProps = {
  miniDrawer: boolean;
  toggleDrawerSize: () => void;
};

export default function HeaderBar(props: HeaderBarProps): JSX.Element {
  const { miniDrawer, toggleDrawerSize } = props;
  return (
    <AppBar sx={{ zIndex: 'modal' }}>
      <Toolbar sx={{ marginRight: -2.5 }}>
        <Button size="large" color="inherit" startIcon={<Logo fontSize="large" />} component={Link} to="/">
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            Digital Roster
          </Typography>
        </Button>
        <Spacer />
        <IconButton edge="start" color="inherit" aria-label="Toggle drawer" onClick={toggleDrawerSize}>
          {miniDrawer ? <FullMenuIcon /> : <MiniMenuIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
