import { Plant } from '../entities/Plant';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class PlantRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/plants', token);
  }
  async getList(): Promise<Plant[]> {
    const { data } = await this.axios.get<Plant[]>('/');
    return data;
  }
}
