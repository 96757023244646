import React, { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from 'mdi-material-ui/Plus';
import DetailsIcon from 'mdi-material-ui/TextBoxSearch';

import { useAuth } from '../../../hooks/store/useAuth';
import { useStamp } from '../../../hooks/store/useStamp';
import { useTableHiddenColumns } from '../../../hooks/store/useTableHiddenColumns';
import { stampsSection as section } from '../../../utils/appSections';
import { useTableOptions, RowActionsWrapper, formatDateForSort, formatDateForDisplay } from '../../../utils/table';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';
import StampShapeComponent from '../../StampShape';
import TabbedComponents from '../../TabbedComponents';
import AddStampDialog from './AddStampDialog';
import FilterByOwner from './FilterByOwner';
import FilterByShape from './FilterByShape';
import StampSuspensionComponent from './StampSuspensionComponent';

const useStyles = makeStyles((theme) => ({
  filtersWrapper: {
    position: 'relative',
  },
  addButton: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(1),
  },
}));

const tabs = [
  { title: 'Forma e numero', slug: '/stamps/by_shape', children: <FilterByShape /> },
  { title: 'Assegnazione', slug: '/stamps/by_owner', children: <FilterByOwner /> },
];

export default function Stamps(): JSX.Element {
  const classes = useStyles();
  const { stampList, queryStampListRequest } = useStamp();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('stamps_table');
  const { isAdmin } = useAuth();
  const [addModalOpen, setAddModalOpen] = useState(false);

  const { tableOptions } = useTableOptions(
    {
      tableBodyMaxHeight: 'calc(100vh - 357px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    'Digital Roster - export ricerca timbri.csv'
  );

  const columns = useMemo(() => {
    const columns = [
      { label: 'Tipo', name: 'type', options: { display: !hiddenColumns?.['type'] } },
      {
        label: 'Forma',
        name: 'shape',
        options: {
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => <StampShapeComponent shape={stampList[dataIndex].shape} />,
          display: !hiddenColumns?.['shape'],
        },
      },
      { label: 'Numero', name: 'code', options: { display: !hiddenColumns?.['code'] } },
      { label: 'Q.tà archivio', name: 'archivedQty', options: { display: !hiddenColumns?.['archivedQty'] } },
      {
        label: 'Q.tà assegnata',
        name: 'ownerQty',
        options: {
          customBodyRenderLite: (dataIndex: number) => stampList[dataIndex].owner?.qty || '–',
          display: !hiddenColumns?.['ownerQty'],
        },
      },
      {
        label: 'Possessore',
        name: 'owner',
        options: {
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => <StampSuspensionComponent stamp={stampList[dataIndex]} />,
          display: !hiddenColumns?.['owner'],
        },
      },
      {
        label: 'Data assegnazione',
        name: 'ownedFrom',
        options: {
          customBodyRenderLite: (dataIndex: number) => formatDateForDisplay(stampList[dataIndex].owner?.from),
          display: !hiddenColumns?.['ownedFrom'],
        },
      },
      {
        label: 'Data restituzione',
        name: 'ownedTo',
        options: {
          customBodyRenderLite: (dataIndex: number) =>
            formatDateForDisplay(stampList[dataIndex].owner?.to || stampList[dataIndex].suspension?.from),
          display: !hiddenColumns?.['ownedTo'],
        },
      },
      { label: 'Note', name: 'notes', options: { sort: false, display: !hiddenColumns?.['notes'] } },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <RowActionsWrapper>
              <Tooltip title="Dettagli" placement="top" arrow>
                <IconButton component={Link} to={`/stamp/${stampList[dataIndex].id}`}>
                  <DetailsIcon />
                </IconButton>
              </Tooltip>
            </RowActionsWrapper>
          ),
        },
      },
    ];
    if (!isAdmin) {
      columns.splice(6, 2);
    }
    return columns;
  }, [hiddenColumns, isAdmin, stampList]);

  const data = useMemo(
    () =>
      stampList.map((stamp) => ({
        id: stamp.id,
        type: stamp.type.name,
        shape: stamp.shape.id,
        code: stamp.code,
        archivedQty: typeof stamp.archivedQty === 'undefined' ? '–' : stamp.archivedQty,
        ownerQty: stamp.owner?.qty || 0,
        owner: stamp.owner ? `${stamp.owner.person.lastname} ${stamp.owner.person.name}` : '–',
        ownedFrom: formatDateForSort(stamp.owner?.from),
        ownedTo: formatDateForSort(stamp.owner?.to),
        notes: stamp.suspension?.notes || stamp.owner?.notes || '–',
      })),
    [stampList]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false}>
        <div className={classes.filtersWrapper}>
          <TabbedComponents components={tabs} />
          {isAdmin && (
            <Tooltip title="Nuovo timbro" placement="left" arrow onClick={() => setAddModalOpen(true)}>
              <Fab color="secondary" size="medium" className={classes.addButton}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
        <RequestResult
          submitted={Boolean(queryStampListRequest.params.shape || queryStampListRequest.params.owner)}
          request={queryStampListRequest}
          empty={stampList.length === 0}
          emptyTitle="Nessun risultato"
          emptyDescription="Non sono stati trovati timbri corrispondenti ai filtri selezionati"
        >
          <MUIDataTable title={section.shortName} columns={columns} data={data} options={tableOptions} />
        </RequestResult>
      </Container>
      {addModalOpen && <AddStampDialog open={addModalOpen} handleClose={() => setAddModalOpen(false)} />}
    </AppLayout>
  );
}
