import { Repository } from '@top-solution/utils';

export default class CommonDataRepository extends Repository {
  constructor(resourceURL: string, token: string) {
    super({
      apiURL: `${process.env.REACT_APP_AUTH_API}/api`,
      resourceURL,
      axiosConfig: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  }
}
