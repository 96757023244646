import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import OpticalRequirementRepository from '../../api/OpticalRequirementRepository';
import { OpticalRequirement } from '../../entities/Requirement';
import { RootState } from '../../store/reducers';
import {
  ReadOpticalRequirementListFailureAction,
  ReadOpticalRequirementListRequestAction,
  ReadOpticalRequirementListSuccessAction,
  READ_opticalRequirement_LIST_FAILURE,
  READ_opticalRequirement_LIST_REQUEST,
  READ_opticalRequirement_LIST_SUCCESS,
} from '../../store/reducers/opticalRequirement';
import { useAuth } from './useAuth';

type UseOpticalRequirement = {
  opticalRequirementList: OpticalRequirement[];
  readOpticalRequirementList: () => Promise<void>;
  readOpticalRequirementListRequest: RequestState;
};

export function useOpticalRequirement(): UseOpticalRequirement {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new OpticalRequirementRepository(token), [token]);

  const opticalRequirementList = useSelector((state: RootState) => state.opticalRequirement.list);

  const readOpticalRequirementListRequest = useSelector(
    (state: RootState) => state.opticalRequirement.requests.readList
  );

  const readOpticalRequirementList = useCallback(() => {
    dispatch<ReadOpticalRequirementListRequestAction>({ type: READ_opticalRequirement_LIST_REQUEST });
    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadOpticalRequirementListSuccessAction>({ type: READ_opticalRequirement_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadOpticalRequirementListFailureAction>({
          type: READ_opticalRequirement_LIST_FAILURE,
          error: new RequestError(error),
        });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    opticalRequirementList,
    readOpticalRequirementList,
    readOpticalRequirementListRequest,
  };
}

export function useOpticalRequirementListResource(): [
  OpticalRequirement[],
  RequestState,
  RequestError | null,
  () => void
] {
  const { opticalRequirementList, readOpticalRequirementList, readOpticalRequirementListRequest } =
    useOpticalRequirement();

  return useResource<typeof opticalRequirementList, RequestState>(
    'OpticalRequirementList',
    opticalRequirementList,
    readOpticalRequirementList,
    readOpticalRequirementListRequest
  );
}
