import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { it } from 'date-fns/locale';
import { createBrowserHistory } from 'history';
import 'typeface-roboto';
import { create } from 'jss';
import secondary from '@material-ui/core/colors/blueGrey';
import primary from '@material-ui/core/colors/teal';
import CssBaseline from '@material-ui/core/CssBaseline';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ThemeProvider, createMuiTheme, StylesProvider, jssPreset } from '@material-ui/core/styles';
import '@material-ui/lab/themeAugmentation';
import DateFnsAdapter from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { MuiInputsConfig, MuiInputsConfigProvider } from '@top-solution/mui-inputs';
import { ResourceProvider } from '@top-solution/utils';
import { AuthenticatedRoute } from './components/auth';
import AuthCallback from './components/auth/AuthCallback';
import { GlobalErrorBoundary } from './components/Error';
import Expirations from './components/pages/Expirations';
import RenewRequirement from './components/pages/Expirations/RenewRequirement/RenewRequirement';
import Home from './components/pages/Home';
import ManageNotifications from './components/pages/Notifications/ManageNotifications';
import Roster from './components/pages/Roster';
import Staff from './components/pages/Staff';
import StaffDetails from './components/pages/Staff/Details';
import EditManagers from './components/pages/Staff/EditManagers/EditManagers';
import Training from './components/pages/Staff/Training';
import VisitPrescription from './components/pages/Staff/VisitPrescription';
import Stamps from './components/pages/Stamps';
import StampDetails from './components/pages/Stamps/Details';
import store from './store';
import { manageNotifications } from './utils/appSections';
import { defaultMinDate } from './utils/date';

// eslint-disable-next-line
console.log(`🐓 ${process.env.REACT_APP_NAME} (v. ${process.env.REACT_APP_VERSION}) 🐓`)

if (process.env.REACT_APP_DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    datacenter: 'eu',
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });
}

const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary,
    secondary,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});

const muiInputsConfig: MuiInputsConfig = {
  datePicker: {
    inputFormat: 'dd/MM/yyyy',
    minDate: defaultMinDate,
  },
};

const jss = create({ plugins: [...jssPreset().plugins] });

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <StylesProvider jss={jss}>
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={it}>
        <MuiInputsConfigProvider config={muiInputsConfig}>
          <ResourceProvider>
            <Provider store={store}>
              <Router history={history}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <GlobalErrorBoundary>
                    <Switch>
                      <AuthenticatedRoute exact path="/" component={Home} />
                      <AuthenticatedRoute exact path="/stamps/:tab?" component={Stamps} />
                      <AuthenticatedRoute path="/stamp/:id" component={StampDetails} />
                      <AuthenticatedRoute exact admin path="/staff/editManagers" component={EditManagers} />
                      <AuthenticatedRoute path="/staff/:tab?" component={Staff} />
                      <AuthenticatedRoute exact path="/person/:id/visit-prescription" component={VisitPrescription} />
                      <AuthenticatedRoute path="/person/:id/:tab?" component={StaffDetails} />
                      <AuthenticatedRoute exact path="/roster/:tab?" component={Roster} />
                      <AuthenticatedRoute exact admin path="/expirations/renew" component={RenewRequirement} />
                      <AuthenticatedRoute exact path="/expirations/:tab?" component={Expirations} />
                      <AuthenticatedRoute exact path="/training" component={Training} />
                      <AuthenticatedRoute
                        exact
                        admin={manageNotifications.admin}
                        path={manageNotifications.path}
                        component={ManageNotifications}
                      />
                      <Route path="/auth" component={AuthCallback} />
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </GlobalErrorBoundary>
                </ThemeProvider>
              </Router>
            </Provider>
          </ResourceProvider>
        </MuiInputsConfigProvider>
      </LocalizationProvider>
    </StylesProvider>
  </StyledEngineProvider>,
  document.querySelector('#root')
);
