import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import UITextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from 'mdi-material-ui/Close';
import { ProgressButton, TextField, QtyInput } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Stamp } from '../../../entities/Stamp';
import { useAuth } from '../../../hooks/store/useAuth';
import { useStamp } from '../../../hooks/store/useStamp';
import { formatDateShort } from '../../../utils/date';
import Spacer from '../../../utils/Spacer';
import { useTableOptions, formatDateForDisplay, formatDateForSort } from '../../../utils/table';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import FormRow from '../../layout/FormRow';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';
import StampShapeComponent from '../../StampShape';
import ReassignStampDialog from './AssignStampDialog';
import SuspendStampDialog from './SuspendStampDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsRow: {
    marginBottom: 0,

    '& > button, .ProgressButton': {
      flex: 'unset',
    },
  },
  qtyInput: {
    maxWidth: 140,
  },
  detailsWrapper: {
    marginBottom: theme.spacing(1),
  },
  stampSuspensionAlert: {
    marginTop: theme.spacing(2),
  },
}));

type RouteParams = {
  id: string;
};

type StampDetailsForm = {
  archivedQty: number;
  assignedQty: number;
  notes: string;
};

const initialValues = {
  archivedQty: 0,
  assignedQty: 0,
  notes: '',
};

export default function StampDetails(): JSX.Element {
  const classes = useStyles();
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [suspensionDialogOpen, setSuspensionDialogOpen] = useState(false);
  const {
    stampDetailsById,
    readStampDetails,
    readStampDetailsRequest,
    updateStamp,
    updateStampRequest,
    updateStampClear,
  } = useStamp();
  const params = useParams<RouteParams>();
  const id = useMemo(() => parseInt(params.id), [params.id]);
  const stamp = useMemo(() => stampDetailsById[id], [id, stampDetailsById]);
  const { isAdmin } = useAuth();
  const { tableOptions } = useTableOptions({ search: false, download: false, viewColumns: false });

  const schema = useMemo(
    () =>
      validate.object().shape({
        archivedQty: validate.number().min(0).max(100),
        assignedQty: validate
          .number()
          .min(stamp?.owner ? 1 : 0)
          .max(100),
        notes: validate.string(),
      }),
    [stamp]
  );
  const handleSubmit = useCallback(
    (values: StampDetailsForm) => {
      if (stamp) {
        updateStamp({
          ...stamp,
          archivedQty: values.archivedQty,
          owner: stamp.owner
            ? {
                ...stamp.owner,
                qty: values.assignedQty,
                notes: values.notes || '',
              }
            : undefined,
        } as Stamp);
      }
    },
    [stamp, updateStamp]
  );
  const { form, setTouched, setValue, replaceValues, onSubmit } = useForm<StampDetailsForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  useEffect(() => {
    if (stamp) {
      replaceValues({
        archivedQty: stamp.archivedQty || 0,
        assignedQty: stamp.owner?.qty || 0,
        notes: stamp.owner?.notes || '',
      });
      updateStampClear();
    } else if (!stamp && !readStampDetailsRequest.error && !readStampDetailsRequest.inProgress) {
      readStampDetails(id);
    }
  }, [
    id,
    stamp,
    readStampDetails,
    readStampDetailsRequest.error,
    readStampDetailsRequest.inProgress,
    stampDetailsById,
    updateStampClear,
    replaceValues,
  ]);

  const handleAssignedQtyChange = (value: number | null) => {
    if (value !== null) {
      replaceValues({
        archivedQty: form.values.archivedQty - (value - form.values.assignedQty),
        assignedQty: value,
      });
    }
  };

  const handleAssignClick = () => {
    setAssignDialogOpen(true);
  };

  const handleSuspendClick = () => {
    setSuspensionDialogOpen(true);
  };

  const handleAssignDialogClose = () => {
    setAssignDialogOpen(false);
  };

  const handleSuspensionDialogClose = () => {
    setSuspensionDialogOpen(false);
  };

  const isSuspended = () => {
    return Boolean(
      stamp?.suspension &&
        (stamp.suspension.reason.duration === -1 || (stamp.suspension.to && new Date() < stamp.suspension.to))
    );
  };

  const columns = useMemo(() => {
    const columns = [
      { label: 'Possessore', name: 'owner' },
      { label: 'Quantità', name: 'qty' },
      {
        label: 'Data assegnazione',
        name: 'ownedFrom',
        options: {
          customBodyRenderLite: (dataIndex: number) => formatDateForDisplay(stamp?.history?.[dataIndex].from || null),
        },
      },
      {
        label: 'Data restituzione',
        name: 'ownedTo',
        options: {
          customBodyRenderLite: (dataIndex: number) => formatDateForDisplay(stamp?.history?.[dataIndex].to || null),
        },
      },
      { label: 'Note', name: 'notes', options: { sort: false } },
    ];
    if (!isAdmin) {
      columns.splice(2, 2);
    }
    return columns;
  }, [isAdmin, stamp]);

  const data = useMemo(
    () =>
      stamp?.history?.map((record) => ({
        qty: record.qty,
        owner: `${record.person.lastname} ${record.person.name}`,
        notes: record.notes || '–',
        ownedFrom: formatDateForSort(record.from),
        ownedTo: formatDateForSort(record.to),
      })) || [],
    [stamp]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{stamp ? `Timbro ${stamp.shape.id} ${stamp.code}` : 'Dettagli timbro'} | Digital Roster</title>
      </Helmet>
      <Container>
        <RequestResult request={readStampDetailsRequest} submitted={Boolean(readStampDetailsRequest.id)}>
          {stamp && (
            <>
              <Card className={classes.detailsWrapper}>
                <CardHeader
                  title={
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Dettagli timbro <StampShapeComponent shape={stamp.shape} /> {stamp.code} ({stamp.type.name})
                      <Spacer />
                      <IconButton component={Link} to={'/stamps'}>
                        <CloseIcon />
                      </IconButton>
                    </Typography>
                  }
                  className={classes.header}
                />
                <CardContent>
                  <FormRow>
                    <QtyInput
                      label="Quantità archiviata"
                      className={classes.qtyInput}
                      value={form.values.archivedQty}
                      min={0}
                      max={100}
                      disabled={!isAdmin || isSuspended()}
                      onChange={(value) => setValue(value, 'archivedQty')}
                      onBlur={() => setTouched('archivedQty')}
                      error={form.errors.archivedQty && form.touched.archivedQty}
                      helperText={form.touched.archivedQty && form.errors.archivedQty?.message}
                    />
                    {stamp.owner && (
                      <>
                        <QtyInput
                          label="Quantità assegnata"
                          value={form.values.assignedQty}
                          onChange={handleAssignedQtyChange}
                          onBlur={() => setTouched('assignedQty')}
                          min={1}
                          max={form.values.assignedQty + form.values.archivedQty}
                          className={classes.qtyInput}
                          disabled={!isAdmin}
                          error={form.errors.assignedQty && form.touched.assignedQty}
                          helperText={form.touched.assignedQty && form.errors.assignedQty?.message}
                        />
                        <UITextField
                          label="Possessore"
                          value={`${stamp.owner.person.lastname} ${stamp.owner.person.name}`}
                          style={{ maxWidth: '36ch' }}
                          disabled
                        />
                        {isAdmin && (
                          <UITextField
                            label="Data assegnazione"
                            value={stamp.owner.from ? formatDateShort(stamp.owner.from) : ''}
                            style={{ maxWidth: '18ch' }}
                            disabled
                          />
                        )}
                        <TextField
                          label="Note"
                          value={form.values.notes}
                          disabled={!isAdmin}
                          onChange={(value) => setValue(value, 'notes')}
                          onBlur={() => setTouched('notes')}
                          disableClearable
                        />
                      </>
                    )}
                  </FormRow>
                  {isAdmin && !isSuspended() && (
                    <FormRow className={classes.buttonsRow}>
                      <Button variant="contained" color="secondary" onClick={handleAssignClick}>
                        {!stamp.owner ? 'Assegna' : 'Riassegna'}
                      </Button>
                      {!stamp.suspension && (
                        <Button variant="contained" color="secondary" onClick={handleSuspendClick}>
                          Sospendi
                        </Button>
                      )}
                      <Spacer />
                      <ProgressButton
                        variant="contained"
                        color="primary"
                        className="ProgressButton"
                        disabled={
                          !form.isValid ||
                          (form.values.archivedQty === stamp.archivedQty &&
                            (!stamp.owner ||
                              (form.values.assignedQty === stamp.owner.qty && form.values.notes === stamp.owner.notes)))
                        }
                        onClick={onSubmit}
                        inProgress={updateStampRequest.inProgress}
                      >
                        Aggiorna
                      </ProgressButton>
                    </FormRow>
                  )}
                  <ErrorAlert error={updateStampRequest.error} />
                  {stamp.suspension && (
                    <Alert severity="warning" className={classes.stampSuspensionAlert}>
                      <AlertTitle>
                        Timbro {stamp.suspension.reason.duration === -1 ? 'revocato' : 'sospeso'}
                        {stamp.suspension.from ? ` il ${formatDateShort(stamp.suspension.from)}` : ''}
                      </AlertTitle>
                      <strong>Motivo:</strong> {stamp.suspension.reason.name}
                      {stamp.suspension.reason.duration > -1 && stamp.suspension.to && (
                        <>
                          <br />
                          <strong>Disponibile dal: </strong>
                          {formatDateShort(stamp.suspension.to)}
                        </>
                      )}
                      {stamp.suspension.notes ? (
                        <>
                          <br />
                          <strong>Note: </strong>
                          {stamp.suspension.notes}
                        </>
                      ) : null}
                    </Alert>
                  )}
                </CardContent>
              </Card>
              {stamp.history && (
                <MUIDataTable title="Storico possesso" columns={columns} data={data} options={tableOptions} />
              )}
              {assignDialogOpen && (
                <ReassignStampDialog stamp={stamp} open={assignDialogOpen} handleClose={handleAssignDialogClose} />
              )}
              {suspensionDialogOpen && (
                <SuspendStampDialog
                  stamp={stamp}
                  open={suspensionDialogOpen}
                  handleClose={handleSuspensionDialogClose}
                />
              )}
            </>
          )}
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
