import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ObjectWithId, RequestError, RequestState } from '@top-solution/utils';
import StampRepository, { QueryStampsParams } from '../../api/StampRepository';
import { Stamp, StampAssignment, StampSuspension } from '../../entities/Stamp';
import { RootState } from '../../store/reducers';
import { ReadPersonDetailsClearAction, READ_PERSON_DETAILS_CLEAR } from '../../store/reducers/person';
import {
  AssignStampClearAction,
  AssignStampFailureAction,
  AssignStampRequestAction,
  AssignStampSuccessAction,
  ASSIGN_STAMP_CLEAR,
  ASSIGN_STAMP_FAILURE,
  ASSIGN_STAMP_REQUEST,
  ASSIGN_STAMP_SUCCESS,
  CreateStampClearAction,
  CreateStampFailureAction,
  CreateStampRequestAction,
  CreateStampSuccessAction,
  CREATE_STAMP_CLEAR,
  CREATE_STAMP_FAILURE,
  CREATE_STAMP_REQUEST,
  CREATE_STAMP_SUCCESS,
  QueryStampClearAction,
  QueryStampFailureAction,
  QueryStampRequestAction,
  QueryStampSuccessAction,
  QUERY_STAMP_CLEAR,
  QUERY_STAMP_FAILURE,
  QUERY_STAMP_REQUEST,
  QUERY_STAMP_SUCCESS,
  ReadStampDetailsFailureAction,
  ReadStampDetailsRequestAction,
  ReadStampDetailsSuccessAction,
  READ_STAMP_DETAILS_FAILURE,
  READ_STAMP_DETAILS_REQUEST,
  READ_STAMP_DETAILS_SUCCESS,
  SuspendStampClearAction,
  SuspendStampFailureAction,
  SuspendStampRequestAction,
  SuspendStampSuccessAction,
  SUSPEND_STAMP_CLEAR,
  SUSPEND_STAMP_FAILURE,
  SUSPEND_STAMP_REQUEST,
  SUSPEND_STAMP_SUCCESS,
  UpdateStampClearAction,
  UpdateStampFailureAction,
  UpdateStampRequestAction,
  UpdateStampSuccessAction,
  UPDATE_STAMP_CLEAR,
  UPDATE_STAMP_FAILURE,
  UPDATE_STAMP_REQUEST,
  UPDATE_STAMP_SUCCESS,
} from '../../store/reducers/stamp';
import { useAuth } from './useAuth';

type UseStamp = {
  stampList: Stamp[];
  stampDetailsById: {
    [id: number]: Stamp;
  };
  queryStampListRequest: RequestState & {
    params: QueryStampsParams;
  };
  createStampRequest: {
    stamp: Stamp | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  readStampDetailsRequest: {
    id: number | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  suspendStampRequest: {
    id: number | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  assignStampRequest: {
    id: number | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  updateStampRequest: {
    id: number | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  queryStampList: (params: QueryStampsParams) => Promise<void>;
  createStamp: (stamp: Stamp) => Promise<ObjectWithId | undefined>;
  readStampDetails: (id: number) => Promise<Stamp | undefined>;
  suspendStamp: (id: number, suspension: StampSuspension) => Promise<Stamp | undefined>;
  assignStamp: (id: number, assignment: StampAssignment) => Promise<Stamp | undefined>;
  updateStamp: (stamp: Stamp) => Promise<Stamp | undefined>;
  createStampClear: () => void;
  suspendStampClear: () => void;
  assignStampClear: () => void;
  updateStampClear: () => void;
};

export function useStamp(): UseStamp {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new StampRepository(token), [token]);

  const stampList = useSelector((state: RootState) => state.stamp.list);
  const stampDetailsById = useSelector((state: RootState) => state.stamp.details);

  const queryStampListRequest = useSelector((state: RootState) => state.stamp.requests.query);
  const createStampRequest = useSelector((state: RootState) => state.stamp.requests.create);
  const readStampDetailsRequest = useSelector((state: RootState) => state.stamp.requests.readDetails);
  const suspendStampRequest = useSelector((state: RootState) => state.stamp.requests.suspend);
  const assignStampRequest = useSelector((state: RootState) => state.stamp.requests.assign);
  const updateStampRequest = useSelector((state: RootState) => state.stamp.requests.update);

  const queryStampList = useCallback(
    (params: QueryStampsParams) => {
      dispatch<QueryStampRequestAction>({ type: QUERY_STAMP_REQUEST, params });

      const read = async () => {
        try {
          const list = await api.getList(params);
          dispatch<QueryStampSuccessAction>({ type: QUERY_STAMP_SUCCESS, list });
        } catch (error) {
          dispatch<QueryStampFailureAction>({ type: QUERY_STAMP_FAILURE, error: new RequestError(error) });
        }
      };

      return read();
    },
    [api, dispatch]
  );

  const createStamp = useCallback(
    (stamp: Stamp) => {
      dispatch<CreateStampRequestAction>({ type: CREATE_STAMP_REQUEST, stamp });

      const create = async () => {
        try {
          const data = await api.create(stamp);
          dispatch<CreateStampSuccessAction>({ type: CREATE_STAMP_SUCCESS, data });
          dispatch<QueryStampClearAction>({ type: QUERY_STAMP_CLEAR });
          return data;
        } catch (error) {
          dispatch<CreateStampFailureAction>({ type: CREATE_STAMP_FAILURE, error: new RequestError(error) });
        }
      };

      return create();
    },
    [api, dispatch]
  );

  const readStampDetails = useCallback(
    (id: number) => {
      dispatch<CreateStampClearAction>({ type: CREATE_STAMP_CLEAR });
      dispatch<ReadStampDetailsRequestAction>({ type: READ_STAMP_DETAILS_REQUEST, id });

      const create = async () => {
        try {
          const stamp = await api.getDetails(id);
          dispatch<ReadStampDetailsSuccessAction>({ type: READ_STAMP_DETAILS_SUCCESS, stamp });
          return stamp;
        } catch (error) {
          dispatch<ReadStampDetailsFailureAction>({ type: READ_STAMP_DETAILS_FAILURE, error: new RequestError(error) });
        }
      };

      return create();
    },
    [api, dispatch]
  );

  const suspendStamp = useCallback(
    (id: number, suspension: StampSuspension) => {
      dispatch<SuspendStampClearAction>({ type: SUSPEND_STAMP_CLEAR });
      dispatch<SuspendStampRequestAction>({ type: SUSPEND_STAMP_REQUEST, id });

      const create = async () => {
        try {
          const stamp = await api.suspend(id, suspension);
          dispatch<SuspendStampSuccessAction>({ type: SUSPEND_STAMP_SUCCESS, stamp });
          dispatch<QueryStampClearAction>({ type: QUERY_STAMP_CLEAR });
          dispatch<ReadPersonDetailsClearAction>({ type: READ_PERSON_DETAILS_CLEAR });
          return stamp;
        } catch (error) {
          dispatch<SuspendStampFailureAction>({ type: SUSPEND_STAMP_FAILURE, error: new RequestError(error) });
        }
      };

      return create();
    },
    [api, dispatch]
  );

  const assignStamp = useCallback(
    (id: number, assignment: StampAssignment) => {
      dispatch<AssignStampClearAction>({ type: ASSIGN_STAMP_CLEAR });
      dispatch<AssignStampRequestAction>({ type: ASSIGN_STAMP_REQUEST, id });

      const create = async () => {
        try {
          const stamp = await api.assign(id, assignment);
          dispatch<AssignStampSuccessAction>({ type: ASSIGN_STAMP_SUCCESS, stamp });
          dispatch<QueryStampClearAction>({ type: QUERY_STAMP_CLEAR });
          dispatch<ReadPersonDetailsClearAction>({ type: READ_PERSON_DETAILS_CLEAR });
          return stamp;
        } catch (error) {
          dispatch<AssignStampFailureAction>({ type: ASSIGN_STAMP_FAILURE, error: new RequestError(error) });
        }
      };

      return create();
    },
    [api, dispatch]
  );

  const updateStamp = useCallback(
    (stamp: Stamp) => {
      dispatch<UpdateStampClearAction>({ type: UPDATE_STAMP_CLEAR });
      dispatch<UpdateStampRequestAction>({ type: UPDATE_STAMP_REQUEST, id: stamp.id });

      const create = async () => {
        try {
          const data = await api.update(stamp);
          dispatch<UpdateStampSuccessAction>({ type: UPDATE_STAMP_SUCCESS, stamp: data });
          dispatch<QueryStampClearAction>({ type: QUERY_STAMP_CLEAR });
          return data;
        } catch (error) {
          dispatch<UpdateStampFailureAction>({ type: UPDATE_STAMP_FAILURE, error: new RequestError(error) });
        }
      };

      return create();
    },
    [api, dispatch]
  );

  const createStampClear = useCallback(() => {
    dispatch<CreateStampClearAction>({ type: CREATE_STAMP_CLEAR });
  }, [dispatch]);

  const suspendStampClear = useCallback(() => {
    dispatch<SuspendStampClearAction>({ type: SUSPEND_STAMP_CLEAR });
  }, [dispatch]);

  const assignStampClear = useCallback(() => {
    dispatch<AssignStampClearAction>({ type: ASSIGN_STAMP_CLEAR });
  }, [dispatch]);

  const updateStampClear = useCallback(() => {
    dispatch<UpdateStampClearAction>({ type: UPDATE_STAMP_CLEAR });
  }, [dispatch]);

  return {
    stampList,
    stampDetailsById,
    queryStampList,
    queryStampListRequest,
    createStamp,
    createStampRequest,
    createStampClear,
    readStampDetails,
    readStampDetailsRequest,
    suspendStamp,
    suspendStampRequest,
    suspendStampClear,
    assignStamp,
    assignStampRequest,
    assignStampClear,
    updateStamp,
    updateStampRequest,
    updateStampClear,
  };
}
