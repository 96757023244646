import { Person } from '../entities/Person';
import { Qualification } from '../entities/Qualification';
import {
  AbstractRequirement,
  IRequirement,
  OpticalRequirement,
  PersonRequirement,
  PersonRequirementExpiration,
} from '../entities/Requirement';
import { formatISODate, parseISODate } from '../utils/date';
import DigitalRosterRepository from './DigitalRosterRepository';

export type QueryExpirationParams = {
  personID?: string | null;
  requirementID?: AbstractRequirement['id'] | null;
  from?: string | null;
  qualificationID?: Qualification['id'] | null;
  to?: string | null;
  plant?: string | null;
};

export type PersonRequirementExpirationResponse = {
  expireAt: string;
  notes?: string;
  needsGlasses?: boolean;
  detailsHR?: boolean;
  code?: string;
  notificationsDays: number;
  personalRequirement: PersonRequirement;
  opticalRequirement: OpticalRequirement;
  person: Person;
  requirement: IRequirement;
  id: number;
  name: string;
  dutyCount: number;
};

function parsePersonRequirementExpirationResponse(
  expiration: PersonRequirementExpirationResponse
): PersonRequirementExpiration {
  return {
    dutyCount: expiration.dutyCount,
    person: expiration.person,
    requirement: {
      ...expiration.requirement,
      code: expiration.code,
      expireAt: parseISODate(expiration.expireAt) as Date,
      needsGlasses: expiration.needsGlasses,
      detailsHR: expiration.detailsHR,
      notes: expiration.notes,
      notificationsDays: expiration.notificationsDays,
      opticalRequirement: expiration.opticalRequirement,
    },
  };
}

export type ExpirationBatchUpdateItem = {
  personId: Person['id'];
  requirement: Pick<
    PersonRequirement,
    'id' | 'expireAt' | 'notificationsDays' | 'opticalRequirement' | 'needsGlasses' | 'detailsHR' | 'notes' | 'code'
  >;
};

export type ExpirationBatchUpdateParams = ExpirationBatchUpdateItem[];

export default class ExpirationRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/deadlines', token);
  }

  async query(query: QueryExpirationParams): Promise<PersonRequirementExpiration[]> {
    let url = '/';
    let params;
    if (query.personID) {
      url = `/byPerson/${query.personID}`;
      params = { from: query.from, to: query.to };
    } else if (query.requirementID) {
      url = `/byRequirement/${query.requirementID}`;
      params = { plant: query.plant, from: query.from, to: query.to, qualificationID: query.qualificationID };
    }
    const { data } = await this.axios.get<Array<PersonRequirementExpirationResponse>>(url, { params });
    return data.map(parsePersonRequirementExpirationResponse);
  }

  async batchUpdate(params: ExpirationBatchUpdateParams): Promise<void> {
    return this.axios.patch(
      '/',
      params.map(({ requirement: { opticalRequirement, expireAt, ...requirement }, ...deadline }) => ({
        ...deadline,
        requirement: {
          ...requirement,
          opticalRequirementId: opticalRequirement?.id,
          expireAt: formatISODate(expireAt),
        },
      }))
    );
  }
}
