import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
});

export default function Spacer(): JSX.Element {
  const classes = useStyles();
  return <span className={classes.grow} />;
}
