import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import StampSuspensionReasonRepository from '../../api/StampSuspensionReasonRepository';
import { StampSuspensionReason } from '../../entities/Stamp';
import { RootState } from '../../store/reducers';
import {
  ReadStampSuspensionReasonFailureAction,
  ReadStampSuspensionReasonRequestAction,
  ReadStampSuspensionReasonSuccessAction,
  READ_STAMP_SUSPENSION_REASON_FAILURE,
  READ_STAMP_SUSPENSION_REASON_REQUEST,
  READ_STAMP_SUSPENSION_REASON_SUCCESS,
} from '../../store/reducers/stampSuspensionReason';
import { useAuth } from './useAuth';

type UseStampSuspensionReason = {
  stampSuspensionReasonList: StampSuspensionReason[];
  readStampSuspensionReasonListRequest: RequestState;
  readStampSuspensionReasonList: () => Promise<void>;
};

export function useStampSuspensionReason(): UseStampSuspensionReason {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new StampSuspensionReasonRepository(token), [token]);

  const stampSuspensionReasonList = useSelector((state: RootState) => state.stampSuspensionReason.list);

  const readStampSuspensionReasonListRequest = useSelector(
    (state: RootState) => state.stampSuspensionReason.requests.readList
  );

  const readStampSuspensionReasonList = useCallback(() => {
    dispatch<ReadStampSuspensionReasonRequestAction>({ type: READ_STAMP_SUSPENSION_REASON_REQUEST });

    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadStampSuspensionReasonSuccessAction>({ type: READ_STAMP_SUSPENSION_REASON_SUCCESS, list });
      } catch (error) {
        dispatch<ReadStampSuspensionReasonFailureAction>({
          type: READ_STAMP_SUSPENSION_REASON_FAILURE,
          error: new RequestError(error),
        });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    stampSuspensionReasonList,
    readStampSuspensionReasonList,
    readStampSuspensionReasonListRequest,
  };
}

export function useStampSuspensionReasonListResource(): [
  StampSuspensionReason[],
  RequestState,
  RequestError | null,
  () => void
] {
  const { stampSuspensionReasonList, readStampSuspensionReasonList, readStampSuspensionReasonListRequest } =
    useStampSuspensionReason();

  return useResource<typeof stampSuspensionReasonList, RequestState>(
    'StampSuspensionReasonList',
    stampSuspensionReasonList,
    readStampSuspensionReasonList,
    readStampSuspensionReasonListRequest
  );
}
