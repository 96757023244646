import React, { useCallback } from 'react';
import { endOfToday } from 'date-fns';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressButton, TextField, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../../entities/Person';
import { usePerson } from '../../../../hooks/store/usePerson';
import { defaultMinDate } from '../../../../utils/date';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import FormRow from '../../../layout/FormRow';

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

type EndWorkPersonForm = {
  from: Date;
  confirm: string;
};

type EndWorkPersonDialogProps = {
  open: boolean;
  handleClose: (suspended: boolean) => void;
  person: Person;
};

const confirmText = 'fine lavoro';

const initialValues = {
  from: endOfToday(),
  confirm: '',
};

const schema = validate.object().shape({
  from: validate.date().min(defaultMinDate).max(endOfToday()).required(),
  confirm: validate.string().equals([confirmText]).required(),
});

export default function EndWorkPersonDialog(props: EndWorkPersonDialogProps): JSX.Element {
  const classes = useStyles();
  const { open, handleClose, person } = props;
  const { updatePerson, updatePersonRequest, updatePersonClear } = usePerson();

  const handleSubmit = useCallback(
    async (values: EndWorkPersonForm) => {
      updatePerson({
        ...person,
        endAt: values.from,
      } as Person);
      handleClose(true);
    },
    [handleClose, person, updatePerson]
  );

  const { form, setValue, setTouched, onSubmit } = useForm<EndWorkPersonForm>({ initialValues, schema, handleSubmit });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        updatePersonClear();
      }
    },
    [handleClose, updatePersonClear]
  );

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Fine rapporto di lavoro</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <DatePicker
            label="Data fine lavoro"
            value={form.values.from}
            onChange={(value) => setValue(value, 'from')}
            onBlur={() => setTouched('from')}
            error={form.errors.from && form.touched.from}
            helperText={form.touched.from && form.errors.from?.message}
          />
        </FormRow>
        <Alert severity={'error'}>
          <AlertTitle>Attenzione</AlertTitle>
          Per continuare digita “{confirmText}”:
          <TextField
            value={form.values.confirm}
            onChange={(value) => setValue(value, 'confirm')}
            onBlur={() => setTouched('confirm')}
            error={form.errors.confirm && form.touched.confirm}
            disableClearable
            fullWidth
          />
        </Alert>
        <ErrorAlert error={updatePersonRequest.error} className={classes.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={updatePersonRequest.inProgress}
        >
          Procedi
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
