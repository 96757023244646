import React from 'react';
import { Autocomplete, AutocompleteProps } from '@top-solution/mui-inputs';

import { Qualification } from '../../entities/Qualification';
import { useQualificationListResource } from '../../hooks/store/useQualification';

type QualificationSelectProps<DisableClearable extends boolean | undefined> = Omit<
  AutocompleteProps<string, Qualification, false, DisableClearable>,
  'options' | 'request'
>;

export default function QualificationSelect<DisableClearable extends boolean | undefined>(
  props: QualificationSelectProps<DisableClearable>
): JSX.Element {
  const { label, value, ...otherProps } = props;
  const [options, request] = useQualificationListResource();

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      label={label || 'Mansione'}
      options={options}
      request={request}
      getOptionLabel={({ name }) => name}
    />
  );
}
