import React, { useMemo, useCallback } from 'react';
import { startOfTomorrow } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressButton, TextField, QtyInput, CheckBox, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../../entities/Person';
import { PersonRequirement, AbstractRequirement, OpticalRequirement } from '../../../../entities/Requirement';
import { usePerson } from '../../../../hooks/store/usePerson';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import OpticalRequirementSelect from '../../../inputs/OpticalRequirementSelect';
import RequirementSelect from '../../../inputs/RequirementSelect';
import FormRow from '../../../layout/FormRow';

const useStyles = makeStyles((theme) => ({
  notificationsRow: {
    alignItems: 'flex-start',
  },
  notificationDays: {
    '& .MuiInput-root': {
      marginTop: theme.spacing(3),
    },
  },
  notifications: {
    '& .MuiInput-input': {
      minHeight: 31,
    },
  },
}));

type PersonRequirementAddForm = {
  requirement: AbstractRequirement | null;
  opticalRequirement: OpticalRequirement | null;
  expireAt: Date;
  needsGlasses: boolean;
  detailsHR: boolean;
  notificationDays: number;
  notes: string;
  code: string;
};

type PersonRequirementAddDialogProps = {
  open: boolean;
  handleClose: (submitted: boolean) => void;
  person: Person;
};

const minDate = startOfTomorrow();
const schema = validate.object().shape({
  requirement: validate.mixed<AbstractRequirement>().required(),
  opticalRequirement: validate
    .mixed<OpticalRequirement>()
    .when('requirement', (requirement, schema) => (requirement?.needsOptical ? schema.required() : schema)),
  expireAt: validate.date().min(minDate).required(),
  notificationDays: validate.number().min(1).max(180).required(),
  code: validate
    .string()
    .when('requirement', (requirement, schema) => (requirement?.needsCode ? schema.required() : schema)),
});

export default function PersonRequirementAddDialog(props: PersonRequirementAddDialogProps): JSX.Element {
  const classes = useStyles();
  const { person, open, handleClose } = props;
  const { createPersonRequirement, createPersonRequirementClear, createPersonRequirementRequest } = usePerson();

  const initialValues = useMemo(
    () => ({
      requirement: null as null | AbstractRequirement,
      opticalRequirement: null as null | OpticalRequirement,
      expireAt: minDate,
      needsGlasses: false,
      detailsHR: false,
      notifications: person.email ? [person.email] : ([] as string[]),
      notificationDays: 60,
      notes: '',
      code: '',
    }),
    [person.email]
  );
  const handleSubmit = useCallback(
    async (values: PersonRequirementAddForm) => {
      const personRequirement = {
        id: values.requirement?.id,
        name: values.requirement?.name,
        opticalRequirement: values.opticalRequirement || undefined,
        notificationsDays: values.notificationDays,
        expireAt: values.expireAt,
        notes: values.notes,
        code: values.code,
        needsGlasses: values.opticalRequirement ? values.needsGlasses : undefined,
        detailsHR: values.opticalRequirement ? values.detailsHR : undefined,
      } as PersonRequirement;
      const result = await createPersonRequirement(person, personRequirement);

      if (result?.id) {
        handleClose(true);
        createPersonRequirementClear();
      }
    },
    [createPersonRequirement, createPersonRequirementClear, handleClose, person]
  );
  const { form, setValue, setTouched, onSubmit, replaceValues } = useForm<PersonRequirementAddForm>({
    initialValues,
    schema,
    handleSubmit,
  });
  const handleChangeRequirement = useCallback(
    (requirement: AbstractRequirement | null) => {
      if (requirement) {
        replaceValues({
          requirement,
          notificationDays: requirement.notificationsDays,
        });
      } else {
        setValue(null, 'requirement');
      }
    },
    [replaceValues, setValue]
  );

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        createPersonRequirementClear();
      }
    },
    [createPersonRequirementClear, handleClose]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Nuovo requisito</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <RequirementSelect
            value={form.values.requirement}
            onChange={handleChangeRequirement}
            onBlur={() => setTouched('requirement')}
            error={form.errors.requirement && form.touched.requirement}
            helperText={form.touched.requirement && form.errors.requirement?.message}
            required
          />
          <DatePicker
            label={form.values.requirement?.needsOptical ? 'Scadenza visita' : 'Data scadenza'}
            value={form.values.expireAt}
            minDate={minDate}
            onChange={(value) => setValue(value, 'expireAt')}
            onBlur={() => setTouched('expireAt')}
            error={form.errors.expireAt && form.touched.expireAt}
            helperText={form.touched.expireAt && form.errors.expireAt?.message}
            required
          />
        </FormRow>
        {form.values.requirement?.needsOptical && (
          <>
            <FormRow>
              <OpticalRequirementSelect
                value={form.values.opticalRequirement}
                onChange={(value) => setValue(value, 'opticalRequirement')}
                onBlur={() => setTouched('opticalRequirement')}
                error={form.errors.opticalRequirement && form.touched.opticalRequirement}
                helperText={form.touched.opticalRequirement && form.errors.opticalRequirement?.message}
                required
              />
            </FormRow>
            <FormRow>
              <CheckBox
                label="Necessita lenti correttive"
                value={form.values.needsGlasses}
                onChange={(value) => setValue(value, 'needsGlasses')}
                onBlur={() => setTouched('needsGlasses')}
              />
              <CheckBox
                label="Dettagli c/o HR"
                value={form.values.detailsHR}
                onChange={(value) => setValue(value, 'detailsHR')}
                onBlur={() => setTouched('detailsHR')}
              />
            </FormRow>
          </>
        )}
        {form.values.requirement?.needsCode && (
          <FormRow>
            <TextField
              label="Codice"
              value={form.values.code}
              onChange={(value) => setValue(value, 'code')}
              onBlur={() => setTouched('code')}
              error={form.errors.code && form.touched.code}
              helperText={form.touched.code && form.errors.code?.message}
              required
            />
          </FormRow>
        )}
        <FormRow className={classes.notificationsRow}>
          <QtyInput
            label="Invio notifiche"
            value={form.values.notificationDays}
            onChange={(value) => setValue(value, 'notificationDays')}
            onBlur={() => setTouched('notificationDays')}
            min={1}
            max={180}
            error={form.errors.notificationDays && form.touched.notificationDays}
            helperText={(form.touched.notificationDays && form.errors.notificationDays?.message) || 'Giorni prima'}
            className={classes.notificationDays}
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        <ErrorAlert error={createPersonRequirementRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={createPersonRequirementRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
