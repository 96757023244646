import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import StampShapeRepository from '../../api/StampShapeRepository';
import { StampShape } from '../../entities/Stamp';
import { RootState } from '../../store/reducers';
import {
  ReadStampShapeFailureAction,
  ReadStampShapeRequestAction,
  ReadStampShapeSuccessAction,
  READ_STAMP_SHAPE_FAILURE,
  READ_STAMP_SHAPE_REQUEST,
  READ_STAMP_SHAPE_SUCCESS,
} from '../../store/reducers/stampShape';
import { useAuth } from './useAuth';

type UseStampShape = {
  stampShapeList: StampShape[];
  readStampShapeListRequest: RequestState;
  readStampShapeList: () => Promise<void>;
};

export function useStampShape(): UseStampShape {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new StampShapeRepository(token), [token]);

  const stampShapeList = useSelector((state: RootState) => state.stampShape.list);

  const readStampShapeListRequest = useSelector((state: RootState) => state.stampShape.requests.readList);

  const readStampShapeList = useCallback(() => {
    dispatch<ReadStampShapeRequestAction>({ type: READ_STAMP_SHAPE_REQUEST });

    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadStampShapeSuccessAction>({ type: READ_STAMP_SHAPE_SUCCESS, list });
      } catch (error) {
        dispatch<ReadStampShapeFailureAction>({ type: READ_STAMP_SHAPE_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    stampShapeList,
    readStampShapeList,
    readStampShapeListRequest,
  };
}

export function useStampShapeListResource(): [StampShape[], RequestState, RequestError | null, () => void] {
  const { stampShapeList, readStampShapeList, readStampShapeListRequest } = useStampShape();

  return useResource<typeof stampShapeList, RequestState>(
    'StampShapeList',
    stampShapeList,
    readStampShapeList,
    readStampShapeListRequest
  );
}
