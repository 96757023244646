import React from 'react';
import { Select, SelectProps } from '@top-solution/mui-inputs';

import { StampSuspensionReason } from '../../entities/Stamp';
import { useStampSuspensionReasonListResource } from '../../hooks/store/useStampSuspensionReason';

type StampSuspensionReasonSelectProps = Omit<
  SelectProps<string, StampSuspensionReason>,
  'options' | 'request' | 'renderOption'
>;

export default function StampSuspensionReasonSelect(props: StampSuspensionReasonSelectProps): JSX.Element {
  const { label, ...otherProps } = props;
  const [options, request] = useStampSuspensionReasonListResource();

  return (
    <Select
      {...otherProps}
      label={label || 'Motivo sospensione'}
      options={options}
      request={request}
      renderOption={({ name }) => name}
    />
  );
}
