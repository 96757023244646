import React, { useState, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from 'mdi-material-ui/Plus';
import DetailsIcon from 'mdi-material-ui/TextBoxSearch';

import { useAppSettingsResource } from '../../../hooks/store/useAppSettings';
import { useAuth } from '../../../hooks/store/useAuth';
import { usePerson } from '../../../hooks/store/usePerson';
import { useTableHiddenColumns } from '../../../hooks/store/useTableHiddenColumns';
import { editManagerStaffSection, staffSection as section } from '../../../utils/appSections';
import { useTableOptions, RowActionsWrapper, formatDateForSort, formatDateForDisplay } from '../../../utils/table';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';
import TabbedComponents from '../../TabbedComponents';
import AddPersonDialog from './AddPersonDialog';
import FilterByLastName from './FilterByLastName';
import FilterByProgram from './FilterByProgram';
import FilterByQualification from './FilterByQualification';
import FilterBySerial from './FilterBySerial';
import FilterByStamp from './FilterByStamp';

const useStyles = makeStyles((theme) => ({
  filtersWrapper: {
    position: 'relative',
  },
  addButton: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(1),
  },
  batchEditManagersButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(8),
  },
}));

export default function Staff(): JSX.Element {
  const classes = useStyles();
  const { queryPersonResults, queryPersonRequest } = usePerson();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('staff_table');
  const { isAdmin } = useAuth();
  const [addPersonDialogOpen, setAddPersonDialogOpen] = useState(false);
  const [appSettings, appSettingsRequest] = useAppSettingsResource();

  const handleAddPersonClick = useCallback(() => setAddPersonDialogOpen(true), []);
  const handleAddPersonDialogClose = useCallback(() => setAddPersonDialogOpen(false), []);
  const tabs = useMemo(
    () =>
      appSettingsRequest.lastUpdate && !appSettingsRequest.inProgress
        ? [
            {
              title: 'Cognome',
              slug: '/staff/by_lastname',
              children: <FilterByLastName appSettings={appSettings} />,
            },
            {
              title: 'Matricola',
              slug: '/staff/by_serial',
              children: <FilterBySerial appSettings={appSettings} />,
            },
            {
              title: 'Mansione',
              slug: '/staff/by_qualification',
              children: <FilterByQualification appSettings={appSettings} />,
            },
            {
              title: 'Programma',
              slug: '/staff/by_program',
              children: <FilterByProgram appSettings={appSettings} />,
            },
            {
              title: 'Timbro',
              slug: '/staff/by_stamp',
              children: <FilterByStamp appSettings={appSettings} />,
            },
          ]
        : null,
    [appSettings, appSettingsRequest]
  );

  const { tableOptions } = useTableOptions(
    {
      tableBodyMaxHeight: 'calc(100vh - 356px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    'Digital Roster - export ricerca personale.csv'
  );

  const columns = useMemo(
    () => [
      { label: 'Matricola', name: 'id', options: { display: !hiddenColumns?.['id'] } },
      { label: 'Cognome', name: 'lastName', options: { display: !hiddenColumns?.['lastName'] } },
      { label: 'Nome', name: 'name', options: { display: !hiddenColumns?.['name'] } },
      { label: 'Email', name: 'email', options: { display: !hiddenColumns?.['email'] } },
      {
        label: 'Fine lavoro',
        name: 'endAt',
        options: {
          customBodyRenderLite: (dataIndex: number) =>
            formatDateForDisplay(queryPersonResults[dataIndex].endAt || null),
          display: !hiddenColumns?.['endAt'],
        },
      },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <RowActionsWrapper>
              <Tooltip title="Dettagli" placement="top" arrow>
                <IconButton component={Link} to={`/person/${queryPersonResults[dataIndex].id}`}>
                  <DetailsIcon />
                </IconButton>
              </Tooltip>
            </RowActionsWrapper>
          ),
        },
      },
    ],
    [hiddenColumns, queryPersonResults]
  );

  const data = useMemo(
    () =>
      queryPersonResults.map((person) => ({
        id: person.id,
        lastName: person.lastname,
        name: person.name,
        email: person.email || '–',
        endAt: formatDateForSort(person.endAt),
      })),
    [queryPersonResults]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false}>
        <div className={classes.filtersWrapper}>
          {tabs && <TabbedComponents components={tabs} />}
          {isAdmin && (
            <>
              <Tooltip title="Nuovo dipendente" placement="left" arrow onClick={handleAddPersonClick}>
                <Fab color="secondary" size="medium" className={classes.addButton}>
                  <AddIcon />
                </Fab>
              </Tooltip>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                component={Link}
                to={editManagerStaffSection.path}
                className={classes.batchEditManagersButton}
              >
                {editManagerStaffSection.shortName}
              </Button>
            </>
          )}
        </div>
        <RequestResult
          submitted={Boolean(
            queryPersonRequest.params.id ||
              queryPersonRequest.params.lastName ||
              queryPersonRequest.params.qualification ||
              queryPersonRequest.params.program !== undefined || //  have to check against undefined because of fake program 0
              (queryPersonRequest.params.stamp && queryPersonRequest.params.date)
          )}
          request={queryPersonRequest}
          empty={queryPersonResults.length === 0}
          emptyTitle="Nessun risultato"
          emptyDescription="Non sono state trovate persone corrispondenti ai filtri selezionati"
        >
          <MUIDataTable title={section.shortName} columns={columns} data={data} options={tableOptions} />
        </RequestResult>
        {addPersonDialogOpen && <AddPersonDialog open={addPersonDialogOpen} handleClose={handleAddPersonDialogClose} />}
      </Container>
    </AppLayout>
  );
}
