import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import Autocomplete from '@material-ui/core/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CloseIcon from 'mdi-material-ui/Close';
import { DatePicker } from '@top-solution/mui-inputs';
import type { ExpirationBatchUpdateItem } from '../../../../api/ExpirationRepository';
import type { Person } from '../../../../entities/Person';
import type { PersonRequirement } from '../../../../entities/Requirement';
import { useExpiration } from '../../../../hooks/store/useExpiration';
import { expirationsSection, expirationsRenewSection as section } from '../../../../utils/appSections';
import Spacer from '../../../../utils/Spacer';
import { ErrorAlert } from '../../../Error';
import AppLayout from '../../../layout/Layout';
import RequestResult from '../../../RequestResult';
import RenewRequirementRow from './RenewRequirementRow';
import {
  SearchRequirementExpirationForm,
  SearchRequirementExpirationFormData,
} from './SearchRequirementExpirationForm';

export const minDate = startOfTomorrow();

type PersonWithRequirements = {
  person: Person;
  requirements: PersonRequirement[];
};

export default function RenewRequirement(): JSX.Element {
  const history = useHistory();
  const {
    queryExpiration,
    queryExpirationRequest,
    queryExpirationResults,
    expirationBatchUpdate,
    expirationBatchUpdateRequest,
  } = useExpiration();
  const [expireAt, setExpireAt] = useState(minDate);
  const [requirements, setRequirements] = useState<Record<Person['id'], PersonWithRequirements>>({});
  const [personToAdd, setPersonToAdd] = useState<PersonWithRequirements | null>(null);
  const [searchRequirementsParams, setSearchRequirementParams] = useState<SearchRequirementExpirationFormData>({
    requirement: null,
    qualification: null,
    requirementList: [],
  } as unknown as SearchRequirementExpirationFormData);

  const people = useMemo<PersonWithRequirements[]>(() => {
    const people = new Array<PersonWithRequirements>();
    queryExpirationResults
      .reduce((map, expiration) => {
        if (!requirements[expiration.person.id]) {
          const personWithRequirements: PersonWithRequirements = map.get(expiration.person.id) ?? {
            person: expiration.person,
            requirements: new Array<PersonRequirement>(),
          };
          personWithRequirements.requirements.push(expiration.requirement);
          map.set(expiration.person.id, personWithRequirements);
        }
        return map;
      }, new Map<Person['id'], PersonWithRequirements>())
      .forEach((person) => people.push(person));
    return people;
  }, [queryExpirationResults, requirements]);

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth="lg">
        <Card>
          <CardContent sx={{ minHeight: `calc(100vh - 80px)` }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h5" component="h2" gutterBottom sx={{ flexGrow: 1 }}>
                {section.fullName}
              </Typography>
              <IconButton component={Link} to={expirationsSection.path}>
                <CloseIcon />
              </IconButton>
            </Box>
            <SearchRequirementExpirationForm
              initialValues={searchRequirementsParams}
              isLoading={queryExpirationRequest.inProgress}
              onSubmit={(data: SearchRequirementExpirationFormData) => {
                setSearchRequirementParams(data);
                queryExpiration({
                  requirementID: data.requirement.id,
                  qualificationID: data.qualification?.id,
                });
                setRequirements({});
              }}
            />
            <RequestResult
              request={queryExpirationRequest}
              submitted={
                searchRequirementsParams.requirement &&
                searchRequirementsParams.requirement.id === queryExpirationRequest.params.requirementID &&
                searchRequirementsParams.qualification?.id ===
                  (queryExpirationRequest.params.qualificationID ?? undefined)
              }
              empty={queryExpirationRequest.results.length === 0}
              emptyDescription="Nessun dipendente trovato per il requisito selezionato"
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  marginY: 1,
                }}
              >
                <Autocomplete
                  options={people}
                  getOptionLabel={(option) =>
                    typeof option === 'string'
                      ? option
                      : `${option.person.id} ${option.person.lastname} ${option.person.name}`
                  }
                  value={personToAdd}
                  onChange={(_, value) => setPersonToAdd(value)}
                  renderInput={(props) => <TextField label="Dipendente" helperText=" " {...props} />}
                  fullWidth
                  sx={{ maxWidth: '53ch' }}
                />
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  disabled={!personToAdd}
                  onClick={() => {
                    if (personToAdd) {
                      setRequirements((previousValue) => ({
                        ...previousValue,
                        [personToAdd.person.id]: personToAdd,
                      }));
                      setPersonToAdd(null);
                    }
                  }}
                >
                  Aggiungi
                </Button>
                <Spacer />
                <DatePicker
                  label="Nuova scadenza"
                  value={expireAt}
                  onChange={(date) => setExpireAt(date ?? minDate)}
                  minDate={minDate}
                />
              </Box>
              {Object.values(requirements).map(({ person, requirements }) =>
                requirements.map((requirement) => (
                  <RenewRequirementRow
                    key={`${person.id}-${requirement.id}-${requirement.code ?? 'ø'}-${
                      requirement.opticalRequirement?.id ?? 'ø'
                    }`}
                    person={person}
                    requirement={requirement}
                    expireAt={expireAt}
                    onChange={(requirement: PersonRequirement, person: Person) => {
                      setRequirements((previousValue) => ({
                        ...previousValue,
                        [person.id]: {
                          person,
                          requirements: requirements.map((item) =>
                            requirement.id === item.id &&
                            item.code === requirement.code &&
                            item.opticalRequirement?.id === requirement.opticalRequirement?.id
                              ? requirement
                              : item
                          ),
                        },
                      }));
                    }}
                    onDelete={(requirement: PersonRequirement, person: Person) =>
                      setRequirements((previousValue) => {
                        const updatedPerson = {
                          person,
                          requirements: requirements.filter(
                            (item) =>
                              !(
                                requirement.id === item.id &&
                                item.code === requirement.code &&
                                item.opticalRequirement?.id === requirement.opticalRequirement?.id
                              )
                          ),
                        };
                        if (updatedPerson.requirements.length === 0) {
                          delete previousValue[person.id];
                          return {
                            ...previousValue,
                          };
                        }
                        return {
                          ...previousValue,
                          [person.id]: updatedPerson,
                        };
                      })
                    }
                  />
                ))
              )}
              {expirationBatchUpdateRequest.error && <ErrorAlert error={expirationBatchUpdateRequest.error} />}
              {Object.values(requirements).length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginY: 2,
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    pending={expirationBatchUpdateRequest.inProgress}
                    onClick={async () => {
                      await expirationBatchUpdate(
                        Object.values(requirements).reduce(
                          (list, { person, requirements }) =>
                            list.concat(
                              requirements.map((requirement) => ({
                                personId: person.id,
                                requirement: {
                                  ...requirement,
                                  expireAt,
                                },
                              }))
                            ),
                          new Array<ExpirationBatchUpdateItem>()
                        )
                      );
                      history.push(expirationsSection.path);
                    }}
                  >
                    Aggiorna
                  </LoadingButton>
                </Box>
              )}
            </RequestResult>
          </CardContent>
        </Card>
      </Container>
    </AppLayout>
  );
}
