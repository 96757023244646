import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import decode from 'jwt-decode';

export const INVALID_TOKEN = 'INVALID_TOKEN';

const initialState = {
  token: INVALID_TOKEN,
  roles: null as null | string[],
  username: null as null | string,
  firstname: null as null | string,
  lastname: null as null | string,
  lastUpdate: null as null | number,
  expiration: null as null | number,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokenAction: (state, action: PayloadAction<{ token: string }>) => {
      const { roles, username, firstname, lastname, exp } = decode<{
        roles: string[];
        username: string;
        firstname: string;
        lastname: string;
        exp: number;
      }>(action.payload.token);
      state.token = action.payload.token;
      state.roles = roles;
      state.username = username;
      state.firstname = firstname;
      state.lastname = lastname;
      state.lastUpdate = Date.now();
      state.expiration = exp * 1000;
    },
  },
});

export const { setTokenAction } = authSlice.actions;

export default authSlice.reducer;
