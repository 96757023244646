import { RequestError, RequestState } from '@top-solution/utils';
import { StampType } from '../../entities/Stamp';

export const READ_STAMP_TYPE_REQUEST = 'READ_STAMP_TYPE_REQUEST';
export const READ_STAMP_TYPE_SUCCESS = 'READ_STAMP_TYPE_SUCCESS';
export const READ_STAMP_TYPE_FAILURE = 'READ_STAMP_TYPE_FAILURE';

const initialState = {
  list: [] as StampType[],
  requests: {
    readList: {
      inProgress: false,
      error: null,
      lastUpdate: null,
    } as RequestState,
  },
};

export type StampTypeState = typeof initialState;

export interface ReadStampTypeRequestAction {
  type: typeof READ_STAMP_TYPE_REQUEST;
}
export interface ReadStampTypeSuccessAction {
  type: typeof READ_STAMP_TYPE_SUCCESS;
  list: Array<StampType>;
}
export interface ReadStampTypeFailureAction {
  type: typeof READ_STAMP_TYPE_FAILURE;
  error: RequestError;
}

type ActionType = ReadStampTypeRequestAction | ReadStampTypeSuccessAction | ReadStampTypeFailureAction;

export default function stampTypeReducer(state = initialState, action: ActionType): StampTypeState {
  switch (action.type) {
    case READ_STAMP_TYPE_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_STAMP_TYPE_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_STAMP_TYPE_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
