import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import SkillRepostitory from '../../api/SkillRepostitory';
import { Skill } from '../../entities/Skill';
import { RootState } from '../../store/reducers';
import {
  ReadSkillFailureAction,
  ReadSkillRequestAction,
  ReadSkillSuccessAction,
  READ_SKILL_LIST_FAILURE,
  READ_SKILL_LIST_REQUEST,
  READ_SKILL_LIST_SUCCESS,
} from '../../store/reducers/skill';
import { useAuth } from './useAuth';

type UseSkill = {
  skillList: Skill[];
  readSkillListRequest: RequestState;
  readSkillList: () => Promise<void>;
};

export function useSkill(): UseSkill {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new SkillRepostitory(token), [token]);

  const skillList = useSelector((state: RootState) => state.skill.list);

  const readSkillListRequest = useSelector((state: RootState) => state.skill.requests.readList);

  const readSkillList = useCallback(() => {
    dispatch<ReadSkillRequestAction>({ type: READ_SKILL_LIST_REQUEST });

    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadSkillSuccessAction>({ type: READ_SKILL_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadSkillFailureAction>({ type: READ_SKILL_LIST_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    skillList,
    readSkillList,
    readSkillListRequest,
  };
}

export function useSkillListResource(): [Skill[], RequestState, RequestError | null, () => void] {
  const { skillList, readSkillList, readSkillListRequest } = useSkill();

  return useResource<typeof skillList, RequestState>('SkillList', skillList, readSkillList, readSkillListRequest);
}
