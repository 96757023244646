import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Select, SelectProps } from '@top-solution/mui-inputs';

import { Plant } from '../../entities/Plant';
import { usePlantListResource } from '../../hooks/store/usePlant';

const useStyles = makeStyles({
  select: {
    minWidth: '13ch',
  },
});

type PlantSelectProps = Omit<SelectProps<string, Plant>, 'options' | 'request' | 'renderOption'>;

export default function PlantSelect(props: PlantSelectProps): JSX.Element {
  const classes = useStyles();
  const { label, className, ...otherProps } = props;
  const [options, request] = usePlantListResource();

  return (
    <Select
      {...otherProps}
      className={clsx(classes.select, className)}
      label={label || 'Sito'}
      options={options}
      request={request}
      renderOption={({ name }) => name}
    />
  );
}
