import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import StampTypeRepository from '../../api/StampTypeRepository';
import { StampType } from '../../entities/Stamp';
import { RootState } from '../../store/reducers';
import {
  ReadStampTypeFailureAction,
  ReadStampTypeRequestAction,
  ReadStampTypeSuccessAction,
  READ_STAMP_TYPE_FAILURE,
  READ_STAMP_TYPE_REQUEST,
  READ_STAMP_TYPE_SUCCESS,
} from '../../store/reducers/stampType';
import { useAuth } from './useAuth';

type UseStampTypes = {
  stampTypesList: StampType[];
  readStampTypeListRequest: RequestState;
  readStampTypeList: () => Promise<void>;
};

export function useStampTypes(): UseStampTypes {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new StampTypeRepository(token), [token]);

  const stampTypesList = useSelector((state: RootState) => state.stampType.list);

  const readStampTypeListRequest = useSelector((state: RootState) => state.stampType.requests.readList);

  const readStampTypeList = useCallback(() => {
    dispatch<ReadStampTypeRequestAction>({ type: READ_STAMP_TYPE_REQUEST });

    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadStampTypeSuccessAction>({ type: READ_STAMP_TYPE_SUCCESS, list });
      } catch (error) {
        dispatch<ReadStampTypeFailureAction>({ type: READ_STAMP_TYPE_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    stampTypesList,
    readStampTypeList,
    readStampTypeListRequest,
  };
}

export function useStampTypesListResource(): [StampType[], RequestState, RequestError | null, () => void] {
  const { stampTypesList, readStampTypeList, readStampTypeListRequest } = useStampTypes();

  return useResource<typeof stampTypesList, RequestState>(
    'StampTypeList',
    stampTypesList,
    readStampTypeList,
    readStampTypeListRequest
  );
}
