import React, { useMemo, forwardRef } from 'react';
import { NavLink, LinkProps } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

type ListItemLinkProps = {
  icon: React.ReactNode;
  label: string;
  miniDrawer: boolean;
  to: string;
  tooltip?: string;
};

export default function ListItemLink(props: ListItemLinkProps): JSX.Element {
  const { icon, label, miniDrawer, to, tooltip } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function ListItemComponent(itemProps, ref) {
        return <NavLink to={to} ref={ref} activeClassName="Mui-selected" {...itemProps} />;
      }),
    [to]
  );

  if (miniDrawer) {
    return (
      <Tooltip title={tooltip || label} placement="left" arrow>
        <ListItem button component={renderLink}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </ListItem>
      </Tooltip>
    );
  }
  return (
    <ListItem button component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
}
