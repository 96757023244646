import { format, formatISO, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';

export const defaultMinDate = parseISO('1929-11-05');

export const DATE_SHORT = 'dd/MM/yyyy';
export const DATE_MED = 'd MMM yyyy';
export const DATE_FULL = 'd MMMM yyyy';

function safeFormat(date: Date | string, f: string): string {
  if (typeof date === 'string') {
    return date;
  }
  return format(date, f, { locale: it });
}

export function formatDateShort(date: Date | string): string {
  return safeFormat(date, DATE_SHORT);
}

export function formatDateMedium(date: Date | string): string {
  return safeFormat(date, DATE_MED);
}

export function formatDateFull(date: Date | string): string {
  return safeFormat(date, DATE_MED);
}

export function formatISODate(date: Date | string | number | undefined): undefined | string {
  if (typeof date === 'string') {
    return date;
  }
  if (date === undefined) {
    return undefined;
  }
  return formatISO(date, { representation: 'date' });
}

export function parseISODate(date: string | undefined): undefined | Date {
  if (date === undefined) {
    return undefined;
  }
  return parseISO(date);
}
