import { Skill } from '../entities/Skill';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class SkillRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/skills', token);
  }
  async getList(): Promise<Skill[]> {
    const { data } = await this.axios.get<Skill[]>('/');
    return data;
  }
}
