import React, { useCallback, useMemo } from 'react';
import { CheckBox } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { AppSettings } from '../../../entities/CurrentUser';
import { Plant } from '../../../entities/Plant';
import { Program } from '../../../entities/Program';
import { useAppSettings } from '../../../hooks/store/useAppSettings';
import { usePerson } from '../../../hooks/store/usePerson';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PlantSelect from '../../inputs/PlantSelect';
import ProgramSelect from '../../inputs/ProgramSelect';

type FilterByProgramForm = {
  program: null | Program;
  plant: null | Plant;
  excludeEnded: boolean;
};

const schema = validate.object().shape({
  program: validate.mixed().required(),
  plant: validate.mixed().nullable(),
});

type FilterByProgramProps = {
  appSettings: AppSettings;
};

export default function FilterByProgram(props: FilterByProgramProps): JSX.Element {
  const { appSettings } = props;
  const { queryPerson, queryPersonRequest } = usePerson();
  const { setAppSettings } = useAppSettings();

  const initialValues = useMemo(
    () => ({
      program: null as null | Program,
      plant: null as null | Plant,
      excludeEnded: appSettings.excludeEnded,
    }),
    [appSettings.excludeEnded]
  );

  const handleSubmit = useCallback(
    (values: FilterByProgramForm) => {
      queryPerson({
        program: values.program?.id,
        plant: values.plant?.id,
        excludeEnded: values.excludeEnded,
      });
    },
    [queryPerson]
  );

  const { form, setTouched, setValue, onSubmit } = useForm<FilterByProgramForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <FilterForm onSubmit={onSubmit} request={queryPersonRequest} disabled={!form.isValid}>
      <ProgramSelect
        value={form.values.program}
        onChange={(value) => setValue(value, 'program')}
        onBlur={() => setTouched('program')}
        style={{ flexGrow: 1, maxWidth: '36ch' }}
        error={form.errors.program && form.touched.program}
        helperText={form.touched.program && form.errors.program?.message}
      />
      <PlantSelect
        value={form.values.plant}
        onChange={(value) => setValue(value, 'plant')}
        onBlur={() => setTouched('plant')}
        style={{ flexGrow: 1, maxWidth: '18ch' }}
      />
      <CheckBox
        value={form.values.excludeEnded}
        onChange={(value) => {
          setValue(value, 'excludeEnded');
          setAppSettings({ ...appSettings, excludeEnded: value });
        }}
        label="Escludi congedati"
      />
    </FilterForm>
  );
}
