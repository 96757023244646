import React from 'react';
import { Autocomplete, AutocompleteProps } from '@top-solution/mui-inputs';

import { Skill } from '../../entities/Skill';
import { useSkillListResource } from '../../hooks/store/useSkill';

type SkillSelectProps<Multiple extends boolean | undefined, DisableClearable extends boolean | undefined> = Omit<
  AutocompleteProps<string, Skill, Multiple, DisableClearable>,
  'options' | 'request'
>;

export default function SkillSelect<Multiple extends boolean | undefined, DisableClearable extends boolean | undefined>(
  props: SkillSelectProps<Multiple, DisableClearable>
): JSX.Element {
  const { label, multiple, value, ...otherProps } = props;
  const [options, request] = useSkillListResource();

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      label={label || 'Abilitazioni'}
      options={options}
      request={request}
      getOptionLabel={({ id, name }) => `${id} – ${name}`}
      multiple={multiple}
    />
  );
}
