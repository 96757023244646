import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBox, TextField } from '@top-solution/mui-inputs';

import { useForm } from '@top-solution/use-form';
import { AppSettings } from '../../../entities/CurrentUser';
import { Plant } from '../../../entities/Plant';
import { useAppSettings } from '../../../hooks/store/useAppSettings';
import { usePerson } from '../../../hooks/store/usePerson';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PlantSelect from '../../inputs/PlantSelect';

type FilterByLastNameForm = {
  lastName: string;
  excludeEnded: boolean;
  plant: null | Plant;
};

const schema = validate.object().shape({
  lastName: validate.string().required(),
  excludeEnded: validate.boolean(),
  plant: validate.mixed().nullable(),
});

const useStyles = makeStyles({
  qualificationSelect: {
    flexGrow: 1,
    maxWidth: '150ch',
  },
  plantSelect: {
    flexGrow: 1,
    maxWidth: '14ch',
  },
  programSelect: {
    flexGrow: 1,
    maxWidth: '18ch',
  },
  skillSelect: {
    flexGrow: 1,
    maxWidth: '18ch',
  },
  excludeCheckbox: {
    minWidth: '20ch',
  },
});

type FilterByLastNameProps = {
  appSettings: AppSettings;
};

export default function FilterByLastName(props: FilterByLastNameProps): JSX.Element {
  const { appSettings } = props;
  const classes = useStyles();
  const { queryPerson, queryPersonRequest } = usePerson();
  const { setAppSettings } = useAppSettings();

  const initialValues = useMemo(
    () => ({
      lastName: '',
      excludeEnded: appSettings.excludeEnded,
      plant: null as null | Plant,
    }),
    [appSettings.excludeEnded]
  );

  const handleSubmit = useCallback(
    (values: FilterByLastNameForm) => {
      queryPerson({ lastName: values.lastName, excludeEnded: values.excludeEnded, plant: values.plant?.id });
    },
    [queryPerson]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterByLastNameForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <FilterForm onSubmit={onSubmit} request={queryPersonRequest} disabled={!form.isValid}>
      <TextField
        label="Cognome"
        style={{ flexGrow: 1, maxWidth: '36ch' }}
        value={form.values.lastName}
        onChange={(value) => setValue(value, 'lastName')}
        onBlur={() => setTouched('lastName')}
        error={form.errors.lastName && form.touched.lastName}
        helperText={
          (form.touched.lastName && form.errors.lastName?.message) || 'Utilizzare il carattere * per nomi incompleti'
        }
      />
      <PlantSelect
        className={classes.plantSelect}
        value={form.values.plant}
        onChange={(value) => setValue(value, 'plant')}
        onBlur={() => setTouched('plant')}
      />
      <CheckBox
        value={form.values.excludeEnded}
        onChange={(value) => {
          setValue(value, 'excludeEnded');
          setAppSettings({ ...appSettings, excludeEnded: value });
        }}
        label="Escludi congedati"
      />
    </FilterForm>
  );
}
