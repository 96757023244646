import React, { useState, useCallback, useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from 'mdi-material-ui/Check';
import EditIcon from 'mdi-material-ui/Pencil';
import DeleteIcon from 'mdi-material-ui/TrashCan';

import PersonDutyDeleteDialog from '../components/pages/Staff/Details/PersonDutyDeleteDialog';
import PersonDutyEditDialog from '../components/pages/Staff/Details/PersonDutyEditDialog';
import { Duty } from '../entities/Duty';
import { Person } from '../entities/Person';
import { NAME_SEPARATOR } from '../entities/Qualification';
import { REQUIREMENT_HF_ID } from '../entities/Requirement';
import { useAuth } from '../hooks/store/useAuth';
import { useTableHiddenColumns } from '../hooks/store/useTableHiddenColumns';
import { useTableOptions, RowActionsWrapper, formatDateForSort, formatDateForDisplay } from '../utils/table';
import RequirementLabel from './RequirementLabel';
import StampShapeComponent from './StampShape';

const useStyles = makeStyles((theme) => ({
  stamp: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  textCenter: {
    textAlign: 'center',
  },
  skillChip: {
    margin: 1,
  },
  errorChip: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

type PersonRosterTableProps = {
  person: Person;
  title?: string;
  tableOptions?: MUIDataTableOptions;
};

export default function PersonRosterTable(props: PersonRosterTableProps): JSX.Element {
  const classes = useStyles();
  const { person, title } = props;
  const { isAdmin } = useAuth();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('roster_table');
  const [editDialogModel, setEditDialogModel] = useState(null as null | Duty);
  const [deleteDialogModel, setDeleteDialogModel] = useState(null as null | Duty);
  const { tableOptions } = useTableOptions(
    {
      ...props.tableOptions,
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    `${person.id} ${person.lastname} ${person.name} - export mansioni.csv`
  );

  const handleEditDialogClose = useCallback(() => setEditDialogModel(null), []);
  const handleEditClick = useCallback(
    (dataIndex: number) => setEditDialogModel(person.roster?.[dataIndex] || null),
    [person]
  );

  const handleDeleteDialogClose = useCallback(() => setDeleteDialogModel(null), []);
  const handleDeleteClick = useCallback(
    (dataIndex: number) => setDeleteDialogModel(person.roster?.[dataIndex] || null),
    [person]
  );

  const columns = useMemo(() => {
    const columns = [
      { label: 'Sito', name: 'plant', options: { display: !hiddenColumns?.['plant'] } },
      {
        label: 'Mansione',
        name: 'qualification',
        options: {
          display: !hiddenColumns?.['qualification'],
          customBodyRenderLite: (dataIndex: number) =>
            (person.roster && (
              <>
                {person.roster[dataIndex].qualification.name.split(NAME_SEPARATOR).map((name, index) => (
                  <div className={classes.noWrap} key={index}>
                    {name}
                  </div>
                ))}
              </>
            )) ||
            '–',
        },
      },
      {
        label: 'Applicazione',
        name: 'programs',
        options: {
          display: !hiddenColumns?.['programs'],
          customBodyRenderLite: (dataIndex: number) =>
            person.roster?.[dataIndex].programs?.map((program) => (
              <div className={classes.noWrap} key={program.id}>
                {program.name}
              </div>
            )) || '–',
        },
      },
      { label: 'Ambito', name: 'scope', options: { display: !hiddenColumns?.['scope'] } },
      {
        label: 'Solo produzione',
        name: 'onlyProduction',
        options: {
          display: !hiddenColumns?.['onlyProduction'],
          customBodyRenderLite: (dataIndex: number) =>
            (person.roster?.[dataIndex].isProduction && (
              <div className={classes.textCenter}>
                <CheckIcon />
              </div>
            )) ||
            null,
        },
      },
      {
        label: 'Safety part',
        name: 'isSafetyPart',
        options: {
          display: !hiddenColumns?.['isSafetyPart'],
          customBodyRenderLite: (dataIndex: number) =>
            (person.roster?.[dataIndex].isSafetyPart && (
              <div className={classes.textCenter}>
                <CheckIcon />
              </div>
            )) ||
            null,
        },
      },
      {
        label: 'Abilitazioni',
        name: 'skills',
        options: {
          sort: false,
          display: !hiddenColumns?.['skills'],
          customBodyRenderLite: (dataIndex: number) =>
            person.roster?.[dataIndex].skills?.map((skill) => (
              <Tooltip title={skill.name} key={skill.id} placement="top" arrow>
                <Chip label={skill.id} variant="outlined" className={classes.skillChip} />
              </Tooltip>
            )) || '–',
        },
      },
      {
        label: 'Timbro',
        name: 'stamp',
        options: {
          display: !hiddenColumns?.['stamp'],
          customBodyRenderLite: (dataIndex: number) => {
            const stamp = person.roster?.[dataIndex].stamp;
            return (
              (stamp && (
                <span className={classes.stamp}>
                  <StampShapeComponent shape={stamp.shape} /> {stamp.code}
                </span>
              )) ||
              '–'
            );
          },
        },
      },
      {
        label: 'In training',
        name: 'training',
        options: {
          display: !hiddenColumns?.['training'],
          customBodyRenderLite: (dataIndex: number) =>
            (person.roster?.[dataIndex].training && (
              <div className={classes.textCenter}>
                <CheckIcon />
              </div>
            )) ||
            null,
        },
      },
      {
        label: 'Req. Visivo',
        name: 'opticalReq',
        options: {
          sort: false,
          display: !hiddenColumns?.['opticalReq'],
          customBodyRenderLite: function OpticalRequirement(dataIndex: number) {
            const opticalReq =
              (person.roster &&
                person.roster[dataIndex].requirements?.find((req) => Boolean(req.opticalRequirement))) ||
              null;
            if (opticalReq && !opticalReq.expireAt) {
              return (
                <Chip
                  variant="filled"
                  className={classes.errorChip}
                  label={`${opticalReq.opticalRequirement?.name} mancante`}
                />
              );
            }
            return (opticalReq && <RequirementLabel requirement={opticalReq} showDate />) || '–';
          },
        },
      },
      {
        label: 'Safety Training / Human Factor',
        name: 'humanFactor',
        options: {
          sort: false,
          display: !hiddenColumns?.['humanFactor'],
          customBodyRenderLite: (dataIndex: number) => {
            const humanFactor =
              person.roster?.[dataIndex].requirements?.find((req) => req.id === REQUIREMENT_HF_ID) || null;
            if (humanFactor && !humanFactor.expireAt) {
              return <Chip variant="filled" className={classes.errorChip} label={`${humanFactor.name} mancante`} />;
            }
            return (humanFactor && <RequirementLabel requirement={humanFactor} showDate />) || '–';
          },
        },
      },
      {
        label: 'Altri requisiti',
        name: 'otherReq',
        options: {
          sort: false,
          display: !hiddenColumns?.['otherReq'],
          customBodyRenderLite: (dataIndex: number) => {
            const otherReq =
              person.roster?.[dataIndex].requirements?.filter(
                (req) => req.id !== REQUIREMENT_HF_ID && !req.opticalRequirement
              ) || [];
            return (
              otherReq?.map((req) => {
                return <RequirementLabel requirement={req} key={req.id + (req.code || '')} />;
              }) || '–'
            );
          },
        },
      },
      { label: 'Note', name: 'notes', options: { display: !hiddenColumns?.['notes'], sort: false } },
      {
        label: 'Data Inserimento Anagrafica',
        name: 'from',
        options: {
          display: !hiddenColumns?.['from'],
          customBodyRenderLite: (dataIndex: number) =>
            formatDateForDisplay(person.roster ? person.roster[dataIndex].from : null),
        },
      },
      {
        label: 'Data fine',
        name: 'to',
        options: {
          display: !hiddenColumns?.['to'],
          customBodyRenderLite: (dataIndex: number) =>
            formatDateForDisplay(person.roster ? person.roster[dataIndex].to : null),
        },
      },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) =>
            isAdmin && (
              <RowActionsWrapper className={classes.noWrap}>
                <Tooltip title="Modifica" placement="top" arrow>
                  <IconButton onClick={() => handleEditClick(dataIndex)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Elimina" placement="top" arrow>
                  <IconButton onClick={() => handleDeleteClick(dataIndex)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </RowActionsWrapper>
            ),
        },
      },
    ];
    if (!isAdmin) {
      columns.splice(13, 1);
    }
    return columns;
  }, [
    classes.errorChip,
    classes.noWrap,
    classes.skillChip,
    classes.stamp,
    classes.textCenter,
    handleDeleteClick,
    handleEditClick,
    hiddenColumns,
    isAdmin,
    person.roster,
  ]);

  const data = useMemo(
    () =>
      person.roster?.map((duty) => {
        const humanFactor = duty.requirements?.find(({ id }) => id === REQUIREMENT_HF_ID);
        const opticalReq = duty.requirements?.find(({ opticalRequirement }) => Boolean(opticalRequirement));
        return {
          id: duty.id,
          plant: duty.plant.id,
          qualification: duty.qualification.name,
          programs: duty.programs?.map((program) => program.name).join(', ') || '–',
          skills: duty.skills?.map((skill) => skill.id).join(', ') || '–',
          notes: duty.notes || '–',
          from: formatDateForSort(duty.from),
          to: formatDateForSort(duty.to),
          onlyProduction: (duty.isProduction && 'y') || 'n',
          isSafetyPart: (duty.isSafetyPart && 'y') || 'n',
          training: (duty.training && 'y') || 'n',
          stamp: duty.stamp ? `${duty.stamp.shape.id} ${duty.stamp.code}` : '',
          scope: typeof duty.isMilitary !== 'boolean' ? 'Civile/Militare' : duty.isMilitary ? 'Militare' : 'Civile',
          humanFactor: humanFactor ? formatDateForDisplay(humanFactor.expireAt) : '',
          opticalReq:
            opticalReq && opticalReq.opticalRequirement
              ? `${opticalReq.opticalRequirement?.name} ${formatDateForDisplay(opticalReq.expireAt)}`
              : '',
          otherReq:
            duty.requirements
              ?.filter((req) => req.id !== REQUIREMENT_HF_ID && !req.opticalRequirement)
              .map((req) => `${req.name} ${req.code || ''} ${formatDateForDisplay(req.expireAt)}`)
              .join(', ') || '',
        };
      }) || [],
    [person.roster]
  );

  if (!person.roster) {
    return <></>;
  }

  return (
    <>
      <MUIDataTable title={title || ''} columns={columns} data={data} options={tableOptions} />
      {editDialogModel && person && (
        <PersonDutyEditDialog
          open={Boolean(editDialogModel)}
          handleClose={handleEditDialogClose}
          person={person}
          duty={editDialogModel}
        />
      )}
      {deleteDialogModel && person && (
        <PersonDutyDeleteDialog
          open={Boolean(deleteDialogModel)}
          handleClose={handleDeleteDialogClose}
          person={person}
          duty={deleteDialogModel}
        />
      )}
    </>
  );
}
