import React from 'react';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { ErrorAlert } from './Error';

const useStyles = makeStyles((theme) => ({
  progress: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  alert: {
    background: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
}));

export interface RequestResultProps {
  request: {
    inProgress: boolean;
    error: Error | null;
  };
  submitted: boolean;
  empty?: boolean;
  emptyTitle?: string;
  emptyDescription?: string;
  children: Array<React.ReactNode> | React.ReactNode;
}

export default function RequestResult(props: RequestResultProps): JSX.Element {
  const classes = useStyles();
  const { request, submitted, empty, emptyTitle, emptyDescription, children } = props;

  if (!submitted) {
    return <></>;
  }

  if (request.inProgress) {
    return (
      <div className={classes.progress}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (request.error) {
    return <ErrorAlert error={request.error} className={classes.alert} showDetails />;
  }

  if (empty) {
    return (
      <Alert variant="outlined" severity="info" className={classes.alert}>
        {emptyTitle && <AlertTitle>{emptyTitle}</AlertTitle>}
        {emptyDescription}
      </Alert>
    );
  }

  return <div>{children}</div>;
}
