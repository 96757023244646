import CommonDataRepository from './CommonDataRepository';

export default class TokenRepository extends CommonDataRepository {
  constructor(token: string) {
    super('/token', token);
  }

  async get(): Promise<string> {
    const { data } = await this.axios.get<string>('/');
    return data;
  }
}
