import React, { useCallback } from 'react';
import { subYears, endOfToday } from 'date-fns';
import { DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../entities/Person';
import { usePerson } from '../../../hooks/store/usePerson';
import { defaultMinDate } from '../../../utils/date';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PersonSelect from '../../inputs/PersonSelect';

type FilterByPersonForm = {
  from: Date;
  to: Date;
  person: Person | null;
};

type FilterByPersonProps = {
  onSubmit: (person: Person, from: Date, to: Date) => void;
};

const today = endOfToday();
const initialValues = {
  from: subYears(today, 1),
  to: today,
  person: null,
};
const schema = validate.object().shape({
  person: validate.mixed().required(),
  from: validate.date().min(defaultMinDate).max(validate.ref('to')).required(),
  to: validate.date().min(validate.ref('from')).max(endOfToday()).required(),
});

export default function FilterByPerson(props: FilterByPersonProps): JSX.Element {
  const { readPersonDetailsRequest } = usePerson();

  const handleSubmit = useCallback(
    (values: FilterByPersonForm) => {
      if (values.person) {
        props.onSubmit(values.person, values.from, values.to);
      }
    },
    [props]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterByPersonForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <FilterForm onSubmit={onSubmit} request={readPersonDetailsRequest} disabled={!form.isValid}>
      <PersonSelect
        label="Dipendente"
        style={{ flexGrow: 1, maxWidth: '50ch' }}
        value={form.values.person}
        onChange={(value) => setValue(value, 'person')}
        onBlur={() => setTouched('person')}
        error={form.errors.person && form.touched.person}
        helperText={form.touched.person && form.errors.person?.message}
      />
      <DatePicker
        label="Inizio validità"
        value={form.values.from}
        onChange={(from) => setValue(from, 'from')}
        onBlur={() => setTouched('from')}
        error={form.errors.from && form.touched.from}
        helperText={form.touched.from && form.errors.from?.message}
      />
      <DatePicker
        label="Fine validità"
        value={form.values.to}
        onChange={(to) => setValue(to, 'to')}
        onBlur={() => setTouched('to')}
        error={form.errors.to && form.touched.to}
        helperText={form.touched.to && form.errors.to?.message}
      />
    </FilterForm>
  );
}
