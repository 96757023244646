import { RequestError, RequestState } from '@top-solution/utils';
import { PersonTraining, QueryPeopleParams } from '../../api/PeopleRepository';
import { Duty } from '../../entities/Duty';
import { Person } from '../../entities/Person';
import { PersonRequirement } from '../../entities/Requirement';
import { VisualRequirementVisit } from '../../entities/VisualRequirementVisit';

export const READ_PERSON_LIST_REQUEST = 'READ_PERSON_LIST_REQUEST';
export const READ_PERSON_LIST_SUCCESS = 'READ_PERSON_LIST_SUCCESS';
export const READ_PERSON_LIST_FAILURE = 'READ_PERSON_LIST_FAILURE';
export const READ_PERSON_LIST_CLEAR = 'READ_PERSON_LIST_CLEAR';
export const QUERY_PERSON_REQUEST = 'QUERY_PERSON_REQUEST';
export const QUERY_PERSON_SUCCESS = 'QUERY_PERSON_SUCCESS';
export const QUERY_PERSON_FAILURE = 'QUERY_PERSON_FAILURE';
export const QUERY_PERSON_CLEAR = 'QUERY_PERSON_CLEAR';
export const READ_PERSON_DETAILS_REQUEST = 'READ_PERSON_DETAILS_REQUEST';
export const READ_PERSON_DETAILS_SUCCESS = 'READ_PERSON_DETAILS_SUCCESS';
export const READ_PERSON_DETAILS_FAILURE = 'READ_PERSON_DETAILS_FAILURE';
export const READ_PERSON_DETAILS_CLEAR = 'READ_PERSON_DETAILS_CLEAR';
export const UPDATE_PERSON_REQUEST = 'UPDATE_PERSON_REQUEST';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAILURE = 'UPDATE_PERSON_FAILURE';
export const UPDATE_PERSON_CLEAR = 'UPDATE_PERSON_CLEAR';
export const CREATE_PERSON_REQUEST = 'CREATE_PERSON_REQUEST';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_FAILURE = 'CREATE_PERSON_FAILURE';
export const CREATE_PERSON_CLEAR = 'CREATE_PERSON_CLEAR';
export const CREATE_PERSON_REQUIREMENT_REQUEST = 'CREATE_PERSON_REQUIREMENT_REQUEST';
export const CREATE_PERSON_REQUIREMENT_SUCCESS = 'CREATE_PERSON_REQUIREMENT_SUCCESS';
export const CREATE_PERSON_REQUIREMENT_FAILURE = 'CREATE_PERSON_REQUIREMENT_FAILURE';
export const CREATE_PERSON_REQUIREMENT_CLEAR = 'CREATE_PERSON_REQUIREMENT_CLEAR';
export const UPDATE_PERSON_REQUIREMENT_REQUEST = 'UPDATE_PERSON_REQUIREMENT_REQUEST';
export const UPDATE_PERSON_REQUIREMENT_SUCCESS = 'UPDATE_PERSON_REQUIREMENT_SUCCESS';
export const UPDATE_PERSON_REQUIREMENT_FAILURE = 'UPDATE_PERSON_REQUIREMENT_FAILURE';
export const UPDATE_PERSON_REQUIREMENT_CLEAR = 'UPDATE_PERSON_REQUIREMENT_CLEAR';
export const DELETE_PERSON_REQUIREMENT_REQUEST = 'DELETE_PERSON_REQUIREMENT_REQUEST';
export const DELETE_PERSON_REQUIREMENT_SUCCESS = 'DELETE_PERSON_REQUIREMENT_SUCCESS';
export const DELETE_PERSON_REQUIREMENT_FAILURE = 'DELETE_PERSON_REQUIREMENT_FAILURE';
export const DELETE_PERSON_REQUIREMENT_CLEAR = 'DELETE_PERSON_REQUIREMENT_CLEAR';
export const CREATE_PERSON_DUTY_REQUEST = 'CREATE_PERSON_DUTY_REQUEST';
export const CREATE_PERSON_DUTY_SUCCESS = 'CREATE_PERSON_DUTY_SUCCESS';
export const CREATE_PERSON_DUTY_FAILURE = 'CREATE_PERSON_DUTY_FAILURE';
export const CREATE_PERSON_DUTY_CLEAR = 'CREATE_PERSON_DUTY_CLEAR';
export const UPDATE_PERSON_DUTY_REQUEST = 'UPDATE_PERSON_DUTY_REQUEST';
export const UPDATE_PERSON_DUTY_SUCCESS = 'UPDATE_PERSON_DUTY_SUCCESS';
export const UPDATE_PERSON_DUTY_FAILURE = 'UPDATE_PERSON_DUTY_FAILURE';
export const UPDATE_PERSON_DUTY_CLEAR = 'UPDATE_PERSON_DUTY_CLEAR';
export const DELETE_PERSON_DUTY_REQUEST = 'DELETE_PERSON_DUTY_REQUEST';
export const DELETE_PERSON_DUTY_SUCCESS = 'DELETE_PERSON_DUTY_SUCCESS';
export const DELETE_PERSON_DUTY_FAILURE = 'DELETE_PERSON_DUTY_FAILURE';
export const DELETE_PERSON_DUTY_CLEAR = 'DELETE_PERSON_DUTY_CLEAR';
export const READ_PERSON_VISIT_PRESCRIPTION_REQUEST = 'READ_PERSON_VISIT_PRESCRIPTION_REQUEST';
export const READ_PERSON_VISIT_PRESCRIPTION_SUCCESS = 'READ_PERSON_VISIT_PRESCRIPTION_SUCCESS';
export const READ_PERSON_VISIT_PRESCRIPTION_FAILURE = 'READ_PERSON_VISIT_PRESCRIPTION_FAILURE';
export const READ_PERSON_LIST_TRAINING_REQUEST = 'READ_PERSON_LIST_TRAINING_REQUEST';
export const READ_PERSON_LIST_TRAINING_SUCCESS = 'READ_PERSON_LIST_TRAINING_SUCCESS';
export const READ_PERSON_LIST_TRAINING_FAILURE = 'READ_PERSON_VISIT_PRESCRIPTION_FAILURE';
export const READ_PERSON_LIST_TRAINING_CLEAR = 'READ_PERSON_VISIT_PRESCRIPTION_CLEAR';
export const UPDATE_PERSON_ID_REQUEST = 'UPDATE_PERSON_ID_REQUEST';
export const UPDATE_PERSON_ID_SUCCESS = 'UPDATE_PERSON_ID_SUCCESS';
export const UPDATE_PERSON_ID_FAILURE = 'UPDATE_PERSON_ID_FAILURE';
export const UPDATE_PERSON_ID_CLEAR = 'UPDATE_PERSON_ID_CLEAR';

const initialState = {
  byId: {} as { [personId: string]: Person },
  list: [] as Array<Person>,
  requests: {
    readList: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    } as RequestState,
    query: {
      params: {
        id: null,
        lastName: null,
        duty: null,
      } as QueryPeopleParams,
      inProgress: false,
      error: null as null | RequestError,
      results: [] as Array<Person>,
    },
    readDetails: {
      id: null as string | null,
      inProgress: false,
      error: null as null | RequestError,
      data: null as null | Person,
    },
    update: {
      id: null as string | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    updateID: {
      newID: null as string | null,
      person: null as Person | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    create: {
      person: null as Person | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    createPersonRequirement: {
      person: null as null | Person,
      requirement: null as null | PersonRequirement,
      inProgress: false,
      error: null as null | RequestError,
    },
    updatePersonRequirement: {
      person: null as null | Person,
      requirement: null as null | PersonRequirement,
      inProgress: false,
      error: null as null | RequestError,
    },
    deletePersonRequirement: {
      person: null as null | Person,
      requirement: null as null | PersonRequirement,
      inProgress: false,
      error: null as null | RequestError,
    },
    createPersonDuty: {
      person: null as null | Person,
      duty: null as null | Duty,
      inProgress: false,
      error: null as null | RequestError,
    },
    updatePersonDuty: {
      person: null as null | Person,
      duty: null as null | Duty,
      inProgress: false,
      error: null as null | RequestError,
    },
    deletePersonDuty: {
      person: null as null | Person,
      duty: null as null | Duty,
      inProgress: false,
      error: null as null | RequestError,
    },
    readPersonVisitPrescriptionList: {
      personId: null as null | string,
      visitPrescriptionList: [] as VisualRequirementVisit[],
      inProgress: false,
      error: null as null | RequestError,
    },
    training: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
      list: [] as Array<PersonTraining>,
    },
  },
};

export type PersonState = typeof initialState;

export interface ReadPersonListRequestAction {
  type: typeof READ_PERSON_LIST_REQUEST;
}
export interface ReadPersonListSuccessAction {
  type: typeof READ_PERSON_LIST_SUCCESS;
  list: Array<Person>;
}
export interface ReadPersonListFailureAction {
  type: typeof READ_PERSON_LIST_FAILURE;
  error: RequestError;
}
export interface ReadPersonListClearAction {
  type: typeof READ_PERSON_LIST_CLEAR;
}

export interface QueryPersonRequestAction {
  type: typeof QUERY_PERSON_REQUEST;
  params: QueryPeopleParams;
}
export interface QueryPersonSuccessAction {
  type: typeof QUERY_PERSON_SUCCESS;
  results: Array<Person>;
}
export interface QueryPersonFailureAction {
  type: typeof QUERY_PERSON_FAILURE;
  error: RequestError;
}
export interface QueryPersonClearAction {
  type: typeof QUERY_PERSON_CLEAR;
}

export interface ReadPersonDetailsRequestAction {
  type: typeof READ_PERSON_DETAILS_REQUEST;
  id: string;
}
export interface ReadPersonDetailsSuccessAction {
  type: typeof READ_PERSON_DETAILS_SUCCESS;
  person: Person;
}
export interface ReadPersonDetailsFailureAction {
  type: typeof READ_PERSON_DETAILS_FAILURE;
  error: RequestError;
}
export interface ReadPersonDetailsClearAction {
  type: typeof READ_PERSON_DETAILS_CLEAR;
}

export interface UpdatePersonRequestAction {
  type: typeof UPDATE_PERSON_REQUEST;
  id: string;
}
export interface UpdatePersonSuccessAction {
  type: typeof UPDATE_PERSON_SUCCESS;
  person: Person;
}
export interface UpdatePersonFailureAction {
  type: typeof UPDATE_PERSON_FAILURE;
  error: RequestError;
}
export interface UpdatePersonClearAction {
  type: typeof UPDATE_PERSON_CLEAR;
}

export interface CreatePersonRequestAction {
  type: typeof CREATE_PERSON_REQUEST;
  person: Person;
}
export interface CreatePersonSuccessAction {
  type: typeof CREATE_PERSON_SUCCESS;
  person: Person;
}
export interface CreatePersonFailureAction {
  type: typeof CREATE_PERSON_FAILURE;
  error: RequestError;
}
export interface CreatePersonClearAction {
  type: typeof CREATE_PERSON_CLEAR;
}

export interface CreatePersonRequirementRequestAction {
  type: typeof CREATE_PERSON_REQUIREMENT_REQUEST;
  person: Person;
  requirement: PersonRequirement;
}
export interface CreatePersonRequirementSuccessAction {
  type: typeof CREATE_PERSON_REQUIREMENT_SUCCESS;
  person: Person;
}
export interface CreatePersonRequirementFailureAction {
  type: typeof CREATE_PERSON_REQUIREMENT_FAILURE;
  error: RequestError;
}
export interface CreatePersonRequirementClearAction {
  type: typeof CREATE_PERSON_REQUIREMENT_CLEAR;
}

export interface UpdatePersonRequirementRequestAction {
  type: typeof UPDATE_PERSON_REQUIREMENT_REQUEST;
  person: Person;
  requirement: PersonRequirement;
}
export interface UpdatePersonRequirementSuccessAction {
  type: typeof UPDATE_PERSON_REQUIREMENT_SUCCESS;
  person: Person;
}
export interface UpdatePersonRequirementFailureAction {
  type: typeof UPDATE_PERSON_REQUIREMENT_FAILURE;
  error: RequestError;
}
export interface UpdatePersonRequirementClearAction {
  type: typeof UPDATE_PERSON_REQUIREMENT_CLEAR;
}

export interface DeletePersonRequirementRequestAction {
  type: typeof DELETE_PERSON_REQUIREMENT_REQUEST;
  person: Person;
  requirement: PersonRequirement;
}
export interface DeletePersonRequirementSuccessAction {
  type: typeof DELETE_PERSON_REQUIREMENT_SUCCESS;
  person: Person;
}
export interface DeletePersonRequirementFailureAction {
  type: typeof DELETE_PERSON_REQUIREMENT_FAILURE;
  error: RequestError;
}
export interface DeletePersonRequirementClearAction {
  type: typeof DELETE_PERSON_REQUIREMENT_CLEAR;
}

export interface CreatePersonDutyRequestAction {
  type: typeof CREATE_PERSON_DUTY_REQUEST;
  person: Person;
  duty: Duty;
}
export interface CreatePersonDutySuccessAction {
  type: typeof CREATE_PERSON_DUTY_SUCCESS;
  person: Person;
}
export interface CreatePersonDutyFailureAction {
  type: typeof CREATE_PERSON_DUTY_FAILURE;
  error: RequestError;
}
export interface CreatePersonDutyClearAction {
  type: typeof CREATE_PERSON_DUTY_CLEAR;
}

export interface UpdatePersonDutyRequestAction {
  type: typeof UPDATE_PERSON_DUTY_REQUEST;
  person: Person;
  duty: Duty;
}
export interface UpdatePersonDutySuccessAction {
  type: typeof UPDATE_PERSON_DUTY_SUCCESS;
  duty: Duty;
}
export interface UpdatePersonDutyFailureAction {
  type: typeof UPDATE_PERSON_DUTY_FAILURE;
  error: RequestError;
}
export interface UpdatePersonDutyClearAction {
  type: typeof UPDATE_PERSON_DUTY_CLEAR;
}

export interface DeletePersonDutyRequestAction {
  type: typeof DELETE_PERSON_DUTY_REQUEST;
  person: Person;
  duty: Duty;
}
export interface DeletePersonDutySuccessAction {
  type: typeof DELETE_PERSON_DUTY_SUCCESS;
  duty: Duty;
}
export interface DeletePersonDutyFailureAction {
  type: typeof DELETE_PERSON_DUTY_FAILURE;
  error: RequestError;
}
export interface DeletePersonDutyClearAction {
  type: typeof DELETE_PERSON_DUTY_CLEAR;
}

export interface ReadPersonVisitPrescriptionListRequestAction {
  type: typeof READ_PERSON_VISIT_PRESCRIPTION_REQUEST;
  personId: string;
}
export interface ReadPersonVisitPrescriptionListSuccessAction {
  type: typeof READ_PERSON_VISIT_PRESCRIPTION_SUCCESS;
  visitPrescriptionList: VisualRequirementVisit[];
}
export interface ReadPersonVisitPrescriptionListFailureAction {
  type: typeof READ_PERSON_VISIT_PRESCRIPTION_FAILURE;
  error: RequestError;
}
export interface UpdatePersonIDRequestAction {
  type: typeof UPDATE_PERSON_ID_REQUEST;
  newID: string;
  person: Person;
}
export interface UpdatePersonIDSuccessAction {
  type: typeof UPDATE_PERSON_ID_SUCCESS;
}
export interface UpdatePersonIDFailureAction {
  type: typeof UPDATE_PERSON_ID_FAILURE;
  error: RequestError;
}
export interface UpdatePersonIDClearAction {
  type: typeof UPDATE_PERSON_ID_CLEAR;
}

export interface ReadPersonTrainingListRequestAction {
  type: typeof READ_PERSON_LIST_TRAINING_REQUEST;
}
export interface ReadPersonTrainingListSuccessAction {
  type: typeof READ_PERSON_LIST_TRAINING_SUCCESS;
  list: PersonTraining[];
}
export interface ReadPersonTrainingListFailureAction {
  type: typeof READ_PERSON_LIST_TRAINING_FAILURE;
  error: RequestError;
}

export interface ReadPersonTrainingListClearAction {
  type: typeof READ_PERSON_LIST_TRAINING_CLEAR;
}

type ActionType =
  | ReadPersonListRequestAction
  | ReadPersonListSuccessAction
  | ReadPersonListFailureAction
  | ReadPersonListClearAction
  | QueryPersonRequestAction
  | QueryPersonSuccessAction
  | QueryPersonFailureAction
  | QueryPersonClearAction
  | ReadPersonDetailsRequestAction
  | ReadPersonDetailsSuccessAction
  | ReadPersonDetailsFailureAction
  | ReadPersonDetailsClearAction
  | UpdatePersonRequestAction
  | UpdatePersonSuccessAction
  | UpdatePersonFailureAction
  | UpdatePersonClearAction
  | CreatePersonRequestAction
  | CreatePersonSuccessAction
  | CreatePersonFailureAction
  | CreatePersonClearAction
  | CreatePersonRequirementRequestAction
  | CreatePersonRequirementSuccessAction
  | CreatePersonRequirementFailureAction
  | CreatePersonRequirementClearAction
  | UpdatePersonRequirementRequestAction
  | UpdatePersonRequirementSuccessAction
  | UpdatePersonRequirementFailureAction
  | UpdatePersonRequirementClearAction
  | DeletePersonRequirementRequestAction
  | DeletePersonRequirementSuccessAction
  | DeletePersonRequirementFailureAction
  | DeletePersonRequirementClearAction
  | CreatePersonDutyRequestAction
  | CreatePersonDutySuccessAction
  | CreatePersonDutyFailureAction
  | CreatePersonDutyClearAction
  | UpdatePersonDutyRequestAction
  | UpdatePersonDutySuccessAction
  | UpdatePersonDutyFailureAction
  | UpdatePersonDutyClearAction
  | DeletePersonDutyRequestAction
  | DeletePersonDutySuccessAction
  | DeletePersonDutyFailureAction
  | DeletePersonDutyClearAction
  | ReadPersonVisitPrescriptionListRequestAction
  | ReadPersonVisitPrescriptionListSuccessAction
  | ReadPersonVisitPrescriptionListFailureAction
  | ReadPersonTrainingListRequestAction
  | ReadPersonTrainingListSuccessAction
  | ReadPersonTrainingListFailureAction
  | ReadPersonTrainingListClearAction
  | UpdatePersonIDRequestAction
  | UpdatePersonIDSuccessAction
  | UpdatePersonIDFailureAction
  | UpdatePersonIDClearAction;

export default function personReducer(state = initialState, action: ActionType): PersonState {
  switch (action.type) {
    case READ_PERSON_LIST_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_PERSON_LIST_SUCCESS: {
      return {
        ...state,
        list: action.list,
        byId: action.list.reduce(
          (people, person) => ({
            ...people,
            [person.id]: person,
          }),
          {}
        ),
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_PERSON_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case READ_PERSON_LIST_CLEAR: {
      return {
        ...state,
        list: [],
        byId: {},
        requests: {
          ...state.requests,
          readList: {
            ...initialState.requests.readList,
          },
        },
      };
    }
    case QUERY_PERSON_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            params: action.params,
            inProgress: true,
          },
        },
      };
    }
    case QUERY_PERSON_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: null,
            results: action.results,
          },
        },
      };
    }
    case QUERY_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case QUERY_PERSON_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...initialState.requests.query,
          },
        },
      };
    }
    case READ_PERSON_DETAILS_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case READ_PERSON_DETAILS_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            inProgress: false,
            data: action.person,
          },
        },
      };
    }
    case READ_PERSON_DETAILS_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case READ_PERSON_DETAILS_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...initialState.requests.readDetails,
          },
        },
      };
    }
    case UPDATE_PERSON_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case UPDATE_PERSON_SUCCESS: {
      if (state.requests.readDetails.data?.id === action.person.id) {
        state.requests.readDetails.data = action.person;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
          },
        },
      };
    }
    case UPDATE_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPDATE_PERSON_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...initialState.requests.update,
          },
        },
      };
    }
    case CREATE_PERSON_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            person: action.person,
            inProgress: true,
          },
        },
      };
    }
    case CREATE_PERSON_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
          },
        },
      };
    }
    case CREATE_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case CREATE_PERSON_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...initialState.requests.create,
          },
        },
      };
    }
    case CREATE_PERSON_REQUIREMENT_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonRequirement: {
            ...state.requests.createPersonRequirement,
            person: action.person,
            requirement: action.requirement,
            inProgress: true,
          },
        },
      };
    }
    case CREATE_PERSON_REQUIREMENT_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonRequirement: {
            ...state.requests.createPersonRequirement,
            inProgress: false,
          },
        },
      };
    }
    case CREATE_PERSON_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonRequirement: {
            ...state.requests.createPersonRequirement,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case CREATE_PERSON_REQUIREMENT_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonRequirement: {
            ...initialState.requests.createPersonRequirement,
          },
        },
      };
    }
    case UPDATE_PERSON_REQUIREMENT_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonRequirement: {
            ...state.requests.updatePersonRequirement,
            person: action.person,
            requirement: action.requirement,
            inProgress: true,
          },
        },
      };
    }
    case UPDATE_PERSON_REQUIREMENT_SUCCESS: {
      if (state.requests.readDetails.data?.id === action.person.id) {
        state.requests.readDetails.data = action.person;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonRequirement: {
            ...state.requests.updatePersonRequirement,
            inProgress: false,
          },
        },
      };
    }
    case UPDATE_PERSON_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonRequirement: {
            ...state.requests.updatePersonRequirement,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPDATE_PERSON_REQUIREMENT_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonRequirement: {
            ...initialState.requests.updatePersonRequirement,
          },
        },
      };
    }
    case DELETE_PERSON_REQUIREMENT_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonRequirement: {
            ...state.requests.deletePersonRequirement,
            person: action.person,
            requirement: action.requirement,
            inProgress: true,
          },
        },
      };
    }
    case DELETE_PERSON_REQUIREMENT_SUCCESS: {
      if (state.requests.readDetails.data?.id === action.person.id) {
        state.requests.readDetails.data = action.person;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonRequirement: {
            ...state.requests.deletePersonRequirement,
            inProgress: false,
          },
        },
      };
    }
    case DELETE_PERSON_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonRequirement: {
            ...state.requests.deletePersonRequirement,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case DELETE_PERSON_REQUIREMENT_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonRequirement: {
            ...initialState.requests.deletePersonRequirement,
          },
        },
      };
    }
    case CREATE_PERSON_DUTY_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonDuty: {
            ...state.requests.createPersonDuty,
            person: action.person,
            duty: action.duty,
            inProgress: true,
          },
        },
      };
    }
    case CREATE_PERSON_DUTY_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonDuty: {
            ...state.requests.createPersonDuty,
            inProgress: false,
          },
        },
      };
    }
    case CREATE_PERSON_DUTY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonDuty: {
            ...state.requests.createPersonDuty,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case CREATE_PERSON_DUTY_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          createPersonDuty: {
            ...initialState.requests.createPersonDuty,
          },
        },
      };
    }

    case UPDATE_PERSON_DUTY_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonDuty: {
            ...state.requests.updatePersonDuty,
            person: action.person,
            duty: action.duty,
            inProgress: true,
          },
        },
      };
    }
    case UPDATE_PERSON_DUTY_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonDuty: {
            ...state.requests.updatePersonDuty,
            inProgress: false,
            duty: action.duty,
          },
        },
      };
    }
    case UPDATE_PERSON_DUTY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonDuty: {
            ...state.requests.updatePersonDuty,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPDATE_PERSON_DUTY_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updatePersonDuty: {
            ...initialState.requests.updatePersonDuty,
          },
        },
      };
    }

    case DELETE_PERSON_DUTY_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonDuty: {
            ...state.requests.deletePersonDuty,
            person: action.person,
            duty: action.duty,
            inProgress: true,
          },
        },
      };
    }
    case DELETE_PERSON_DUTY_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonDuty: {
            ...state.requests.deletePersonDuty,
            inProgress: false,
            duty: action.duty,
          },
        },
      };
    }
    case DELETE_PERSON_DUTY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonDuty: {
            ...state.requests.deletePersonDuty,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case DELETE_PERSON_DUTY_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          deletePersonDuty: {
            ...initialState.requests.deletePersonDuty,
          },
        },
      };
    }

    case READ_PERSON_VISIT_PRESCRIPTION_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readPersonVisitPrescriptionList: {
            ...state.requests.readPersonVisitPrescriptionList,
            personId: action.personId,
            inProgress: true,
          },
        },
      };
    }
    case READ_PERSON_VISIT_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readPersonVisitPrescriptionList: {
            ...state.requests.readPersonVisitPrescriptionList,
            inProgress: false,
            visitPrescriptionList: action.visitPrescriptionList,
          },
        },
      };
    }
    case READ_PERSON_VISIT_PRESCRIPTION_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readPersonVisitPrescriptionList: {
            ...state.requests.readPersonVisitPrescriptionList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }

    case READ_PERSON_LIST_TRAINING_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          training: {
            ...state.requests.training,
            inProgress: true,
          },
        },
      };
    }
    case READ_PERSON_LIST_TRAINING_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          training: {
            ...state.requests.training,
            inProgress: false,
            list: action.list,
            lastUpdate: Date.now(),
          },
        },
      };
    }
    case READ_PERSON_LIST_TRAINING_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          training: {
            ...state.requests.training,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case READ_PERSON_LIST_TRAINING_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          training: {
            ...initialState.requests.training,
          },
        },
      };
    }

    case UPDATE_PERSON_ID_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updateID: {
            ...state.requests.updateID,
            newID: action.newID,
            person: action.person,
            inProgress: true,
          },
        },
      };
    }
    case UPDATE_PERSON_ID_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updateID: {
            ...state.requests.updateID,
            inProgress: false,
          },
        },
      };
    }
    case UPDATE_PERSON_ID_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updateID: {
            ...state.requests.updateID,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPDATE_PERSON_ID_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          updateID: {
            ...initialState.requests.updateID,
          },
        },
      };
    }

    default:
      return state;
  }
}
