import { ObjectWithId } from '@top-solution/utils';
import { Person } from './Person';

export const REQUIREMENT_HF_ID = 1;

export interface OpticalRequirement extends ObjectWithId {
  id: number;
  name: string;
}

export interface IRequirement extends ObjectWithId {
  id: number;
  name: string;
  notificationsDays: number;
}

export interface AbstractRequirement extends IRequirement {
  needsOptical: boolean;
  needsCode: boolean;
  defaultNotifications?: string[];
}

export interface QualificationRequirement extends IRequirement {
  opticalRequirement: OpticalRequirement;
  exceptProduction: boolean;
  needSafetyPart?: boolean;
}

export interface PersonRequirement extends IRequirement {
  expireAt: Date | string;
  opticalRequirement?: OpticalRequirement;
  notes?: string;
  needsGlasses?: boolean;
  detailsHR?: boolean;
  code?: string;
  notifications?: string[];
}

export interface PersonRequirementExpiration {
  person: Person;
  requirement: PersonRequirement;
  dutyCount: number;
}

export interface MissingRequirement extends IRequirement {
  opticalRequirement?: OpticalRequirement;
}
