import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { RequestError } from '@top-solution/utils';
import ExpirationRepository, {
  ExpirationBatchUpdateParams,
  QueryExpirationParams,
} from '../../api/ExpirationRepository';
import { PersonRequirementExpiration } from '../../entities/Requirement';
import { RootState } from '../../store/reducers';
import {
  QueryExpirationFailureAction,
  QueryExpirationRequestAction,
  QueryExpirationSuccessAction,
  QUERY_EXPIRATION_FAILURE,
  QUERY_EXPIRATION_REQUEST,
  QUERY_EXPIRATION_SUCCESS,
  ExpirationBatchUpdateFailureAction,
  ExpirationBatchUpdateRequestAction,
  ExpirationBatchUpdateSuccessAction,
  EXPIRATION_BATCH_UPDATE_FAILURE,
  EXPIRATION_BATCH_UPDATE_REQUEST,
  EXPIRATION_BATCH_UPDATE_SUCCESS,
} from '../../store/reducers/expiration';
import { useAuth } from './useAuth';

export type UseExpiration = {
  queryExpirationResults: PersonRequirementExpiration[];
  queryExpiration: (params: QueryExpirationParams) => Promise<void>;
  queryExpirationRequest: {
    params: QueryExpirationParams;
    inProgress: boolean;
    error: RequestError | null;
    results: PersonRequirementExpiration[];
  };
  expirationBatchUpdate: (params: ExpirationBatchUpdateParams) => Promise<void>;
  expirationBatchUpdateRequest: {
    params: ExpirationBatchUpdateParams;
    inProgress: boolean;
    error: RequestError | null;
  };
};

export function useExpiration(): UseExpiration {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new ExpirationRepository(token), [token]);
  const queryExpirationRequest = useSelector((state: RootState) => state.expiration.requests.query);
  const queryExpirationResults = useSelector((state: RootState) => state.expiration.requests.query.results);
  const expirationBatchUpdateRequest = useSelector((state: RootState) => state.expiration.requests.batchUpdate);

  const queryExpiration = useCallback(
    (params: QueryExpirationParams) => {
      dispatch<QueryExpirationRequestAction>({ type: QUERY_EXPIRATION_REQUEST, params });

      const read = async () => {
        try {
          const results = await api.query(params);
          dispatch<QueryExpirationSuccessAction>({ type: QUERY_EXPIRATION_SUCCESS, results });
        } catch (error) {
          dispatch<QueryExpirationFailureAction>({
            type: QUERY_EXPIRATION_FAILURE,
            error: new RequestError(error as AxiosError),
          });
          throw error;
        }
      };

      return read();
    },
    [api, dispatch]
  );

  const expirationBatchUpdate = useCallback(
    (params: ExpirationBatchUpdateParams) => {
      dispatch<ExpirationBatchUpdateRequestAction>({ type: EXPIRATION_BATCH_UPDATE_REQUEST, params });
      const updateBatch = async () => {
        try {
          await api.batchUpdate(params);
          dispatch<ExpirationBatchUpdateSuccessAction>({ type: EXPIRATION_BATCH_UPDATE_SUCCESS });
        } catch (error) {
          dispatch<ExpirationBatchUpdateFailureAction>({
            type: EXPIRATION_BATCH_UPDATE_FAILURE,
            error: new RequestError(error as AxiosError),
          });
          throw error;
        }
      };
      return updateBatch();
    },
    [api, dispatch]
  );

  return {
    queryExpirationResults,
    queryExpiration,
    queryExpirationRequest,
    expirationBatchUpdateRequest,
    expirationBatchUpdate,
  };
}
