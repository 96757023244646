import { combineReducers } from 'redux';
import appSettings from './appSettings';
import auth from './auth';
import expiration from './expiration';
import opticalRequirement from './opticalRequirement';
import person from './person';
import plant from './plant';
import program from './program';
import qualification from './qualification';
import notificationsEmailList from './qualityNotificationsEmail';
import requirement from './requirement';
import skill from './skill';
import stamp from './stamp';
import stampShape from './stampShape';
import stampSuspensionReason from './stampSuspensionReason';
import stampType from './stampType';

export const rootReducer = combineReducers({
  auth,
  appSettings,
  person,
  stampShape,
  stampSuspensionReason,
  stampType,
  stamp,
  qualification,
  program,
  plant,
  skill,
  requirement,
  opticalRequirement,
  expiration,
  notificationsEmailList,
});

export type RootState = ReturnType<typeof rootReducer>;
