import React from 'react';
import StaffIcon from 'mdi-material-ui/AccountGroup';
import TrainingIcon from 'mdi-material-ui/BookEducation';
import ExpirationsIcon from 'mdi-material-ui/CalendarClock';
import EmailIcon from 'mdi-material-ui/EmailMultiple';
import RosterIcon from 'mdi-material-ui/OrderBoolAscendingVariant';
import StampsIcon from 'mdi-material-ui/Stamper';

type AppSection = {
  icon: React.ReactNode;
  shortName: string;
  fullName: string;
  path: string;
  admin: boolean;
};

export const stampsSection = {
  icon: <StampsIcon />,
  shortName: 'Timbri',
  fullName: 'Ricerca timbri',
  path: '/stamps',
  admin: false,
} as AppSection;

export const staffSection = {
  icon: <StaffIcon />,
  shortName: 'Personale',
  fullName: 'Ricerca personale',
  path: '/staff',
  admin: false,
} as AppSection;

export const editManagerStaffSection = {
  icon: <StaffIcon />,
  shortName: 'Modifica manager',
  fullName: 'Modifica manager',
  path: '/staff/editManagers',
} as AppSection;

export const trainingSection = {
  icon: <TrainingIcon />,
  shortName: 'Training',
  fullName: 'Personale in training',
  path: '/training',
  admin: false,
} as AppSection;

export const rosterSection = {
  icon: <RosterIcon />,
  shortName: 'Mansioni',
  fullName: 'Ricerca mansioni',
  path: '/roster',
  admin: false,
} as AppSection;

export const expirationsSection = {
  icon: <ExpirationsIcon />,
  shortName: 'Scadenze',
  fullName: 'Ricerca scadenze',
  path: '/expirations',
  admin: false,
} as AppSection;

export const expirationsRenewSection = {
  icon: <ExpirationsIcon />,
  shortName: 'Rinnovo scadenze',
  fullName: 'Rinnovo scadenze multiple',
  path: '/expirations/renew',
  admin: true,
} as AppSection;

export const manageNotifications = {
  icon: <EmailIcon />,
  shortName: 'Notifiche',
  fullName: 'Email notifiche',
  path: '/admin/notifications',
  admin: true,
} as AppSection;

const sections = [stampsSection, staffSection, trainingSection, rosterSection, expirationsSection, manageNotifications];

export default sections;
