import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import QualificationRepository from '../../api/QualificationRepository';
import { Qualification } from '../../entities/Qualification';
import { RootState } from '../../store/reducers';
import {
  ReadQualificationListFailureAction,
  ReadQualificationListRequestAction,
  ReadQualificationListSuccessAction,
  READ_QUALIFICATION_LIST_FAILURE,
  READ_QUALIFICATION_LIST_REQUEST,
  READ_QUALIFICATION_LIST_SUCCESS,
} from '../../store/reducers/qualification';
import { useAuth } from './useAuth';

type UseQualification = {
  qualificationList: Qualification[];
  readQualificationListRequest: RequestState;
  readQualificationList: () => Promise<void>;
};

export function useQualification(): UseQualification {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new QualificationRepository(token), [token]);

  const qualificationList = useSelector((state: RootState) => state.qualification.list);
  const readQualificationListRequest = useSelector((state: RootState) => state.qualification.requests.readList);

  const readQualificationList = useCallback(() => {
    dispatch<ReadQualificationListRequestAction>({ type: READ_QUALIFICATION_LIST_REQUEST });
    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadQualificationListSuccessAction>({ type: READ_QUALIFICATION_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadQualificationListFailureAction>({
          type: READ_QUALIFICATION_LIST_FAILURE,
          error: new RequestError(error),
        });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    qualificationList,
    readQualificationList,
    readQualificationListRequest,
  };
}

export function useQualificationListResource(): [Qualification[], RequestState, RequestError | null, () => void] {
  const { qualificationList, readQualificationList, readQualificationListRequest } = useQualification();

  return useResource<typeof qualificationList, RequestState>(
    'QualificationList',
    qualificationList,
    readQualificationList,
    readQualificationListRequest
  );
}
