import { NotificationsEmailList } from '../entities/NotificationsEmailList';
import DigitalRosterRepository from './DigitalRosterRepository';

const EMAIL_SEPARATOR = ';';

export default class NotificationsEmailListRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/notifications', token);
  }

  async read(): Promise<NotificationsEmailList> {
    const { data } = await this.axios.get<{
      global: { email: string };
      byRequirement: { id: number; name: string; defaultNotifications: string }[];
    }>('/');
    return {
      global: { email: data.global.email.split(EMAIL_SEPARATOR) },
      byRequirement: data.byRequirement.map((item) => ({
        ...item,
        defaultNotifications: item.defaultNotifications?.split(EMAIL_SEPARATOR) ?? [],
      })),
    };
  }

  async set(data: NotificationsEmailList): Promise<void> {
    return this.axios.post('/', {
      global: { email: data.global.email.join(EMAIL_SEPARATOR) },
      byRequirement: data.byRequirement.map((item) => ({
        ...item,
        defaultNotifications: item.defaultNotifications.length
          ? item.defaultNotifications.join(EMAIL_SEPARATOR)
          : undefined,
      })),
    });
  }
}
