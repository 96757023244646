import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import ProgramRepository from '../../api/ProgramRepository';
import { Program } from '../../entities/Program';
import { RootState } from '../../store/reducers';
import {
  ReadProgramListFailureAction,
  ReadProgramListRequestAction,
  ReadProgramListSuccessAction,
  READ_PROGRAM_LIST_FAILURE,
  READ_PROGRAM_LIST_REQUEST,
  READ_PROGRAM_LIST_SUCCESS,
} from '../../store/reducers/program';
import { useAuth } from './useAuth';

type UseProgram = {
  programList: Program[];
  readProgramListRequest: RequestState;
  readProgramList: () => Promise<void>;
};

export function useProgram(): UseProgram {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new ProgramRepository(token), [token]);

  const programList = useSelector((state: RootState) => state.program.list);
  const readProgramListRequest = useSelector((state: RootState) => state.program.requests.readList);

  const readProgramList = useCallback(() => {
    dispatch<ReadProgramListRequestAction>({ type: READ_PROGRAM_LIST_REQUEST });
    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadProgramListSuccessAction>({ type: READ_PROGRAM_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadProgramListFailureAction>({ type: READ_PROGRAM_LIST_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    programList,
    readProgramList,
    readProgramListRequest,
  };
}

export function useProgramListResource(): [Program[], RequestState, RequestError | null, () => void] {
  const { programList, readProgramList, readProgramListRequest } = useProgram();

  return useResource<typeof programList, RequestState>(
    'ProgramList',
    programList,
    readProgramList,
    readProgramListRequest
  );
}
