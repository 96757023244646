import { FunctionComponent } from 'react';

import { ReactComponent as BrugherioShape } from './brugherio.svg';
import { ReactComponent as CircleShape } from './circle.svg';
import { ReactComponent as DoubleCircleShape } from './double-circle.svg';
import { ReactComponent as OctagonShape } from './octagon.svg';
import { ReactComponent as StarShape } from './other.svg';
import { ReactComponent as SquareShape } from './square.svg';
import { ReactComponent as TriangleShape } from './triangle.svg';

const shapes = {
  OTH: StarShape,
  BRU: BrugherioShape,
  CRC: CircleShape,
  DCR: DoubleCircleShape,
  SQR: SquareShape,
  OCT: OctagonShape,
  TRI: TriangleShape,
} as {
  [key: string]: FunctionComponent;
};

export default shapes;
