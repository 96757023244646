import React, { useCallback, useMemo } from 'react';
import { CheckBox, TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { AppSettings } from '../../../entities/CurrentUser';
import { useAppSettings } from '../../../hooks/store/useAppSettings';
import { usePerson } from '../../../hooks/store/usePerson';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';

type FilterBySerialForm = {
  id: string;
  excludeEnded: boolean;
};

const schema = validate.object().shape({
  id: validate.string().required(),
  excludeEnded: validate.boolean(),
});

type FilterBySerialProps = {
  appSettings: AppSettings;
};

export default function FilterBySerial(props: FilterBySerialProps): JSX.Element {
  const { appSettings } = props;
  const { queryPerson, queryPersonRequest } = usePerson();
  const { setAppSettings } = useAppSettings();

  const initialValues = useMemo(
    () => ({
      id: '',
      excludeEnded: appSettings.excludeEnded,
    }),
    [appSettings.excludeEnded]
  );

  const handleSubmit = useCallback(
    (values: FilterBySerialForm) => {
      queryPerson({ id: values.id, excludeEnded: values.excludeEnded });
    },
    [queryPerson]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterBySerialForm>({ initialValues, schema, handleSubmit });

  return (
    <FilterForm onSubmit={onSubmit} request={queryPersonRequest} disabled={!form.isValid}>
      <TextField
        style={{ flexGrow: 1, maxWidth: '36ch' }}
        label="Matricola"
        value={form.values.id}
        onChange={(value) => setValue(value, 'id')}
        onBlur={() => setTouched('id')}
        error={form.errors.id && form.touched.id}
        helperText={
          (form.errors.id && form.touched.id && form.errors.id?.message) ||
          'Utilizzare il carattere * per nomi incompleti'
        }
      />
      <CheckBox
        value={form.values.excludeEnded}
        onChange={(value) => {
          setValue(value, 'excludeEnded');
          setAppSettings({ ...appSettings, excludeEnded: value });
        }}
        label="Escludi congedati"
      />
    </FilterForm>
  );
}
