import React from 'react';
import { Select, SelectProps } from '@top-solution/mui-inputs';
import { OpticalRequirement } from '../../entities/Requirement';
import { useOpticalRequirementListResource } from '../../hooks/store/useOpticalRequirement';

type OpticalRequirementSelectProps = Omit<
  SelectProps<string, OpticalRequirement>,
  'options' | 'request' | 'renderOption'
>;

export default function OpticalRequirementSelect(props: OpticalRequirementSelectProps): JSX.Element {
  const { label, ...otherProps } = props;
  const [options, request] = useOpticalRequirementListResource();

  return (
    <Select
      {...otherProps}
      label={label || 'Tipo Requisito'}
      options={options}
      request={request}
      renderOption={({ name }) => name}
    />
  );
}
