import { Duty } from '../entities/Duty';
import { Person, PersonStampHistoryRecord } from '../entities/Person';
import { Plant } from '../entities/Plant';
import { Program } from '../entities/Program';
import { Qualification } from '../entities/Qualification';
import { OpticalRequirement, PersonRequirement } from '../entities/Requirement';
import { Skill } from '../entities/Skill';
import { StampShape, StampSuspensionReason, StampType } from '../entities/Stamp';
import { VisualRequirementVisit } from '../entities/VisualRequirementVisit';
import { formatISODate, parseISODate } from '../utils/date';
import DigitalRosterRepository from './DigitalRosterRepository';

type PersonStampHistoryRecordResponse = {
  id: number;
  code: string;
  archivedQty: number;
  qty: number;
  notes: string;
  from: string;
  to: string;
  type: StampType;
  shape: StampShape;
  suspension?: {
    reason: StampSuspensionReason;
    from?: string;
    to?: string;
    notes?: string;
  };
};

type PersonDutyResponse = {
  id: number;
  plant: Plant;
  qualification: Qualification;
  programs: Program[];
  skills: Skill[];
  isProduction: boolean;
  isMilitary: boolean;
  isSafetyPart: boolean;
  training: boolean;
  from: string;
  to?: string;
  requirements?: PersonRequirementResponse[];
  notes?: string;
  stampID?: number;
};

export type PersonRequirementResponse = {
  id: number;
  name: string;
  opticalRequirement: OpticalRequirement;
  notifications: string;
  notificationsDays: number;
  expireAt: string;
  notes?: string;
};

export type PersonResponse = {
  id: string;
  name: string;
  lastname: string;
  email: string;
  managerList?: string[];
  endAt?: string;
  stampHistory?: PersonStampHistoryRecordResponse[];
  roster?: PersonDutyResponse[];
  requirements?: PersonRequirementResponse[];
};

export type QueryPeopleParams = {
  id?: string | null;
  lastName?: string | null;
  qualification?: number | null;
  program?: number | null;
  skill?: number | null;
  stamp?: number | null;
  date?: string | null;
  plant?: string | null;
  excludeEnded?: boolean;
  excludeInTraining?: boolean;
};

type UpdatePersonPayload = {
  name: string;
  lastname: string;
  email: string;
  managerList: string[];
  endAt?: string;
};

export type CreatePersonPayload = {
  id: string;
  name: string;
  lastname: string;
  email: string;
  managerList: string[];
};

export type CreateDutyPayload = {
  plantID: string;
  qualificationID: number;
  programs: { id: number }[];
  skills: { id: number }[];
  stampID?: number;
  training: boolean;
  isMilitary?: boolean;
  isProduction: boolean;
  isSafetyPart: boolean;
  from: string;
  to?: string;
  notes?: string;
};

export type UpdateDutyPayload = {
  requirements?: {
    requirementID: number;
    personID: string;
    code?: string;
    opticalrequirementID?: number;
  }[];
  programs: { id: number }[];
  skills: { id: number }[];
  stampID?: number;
  training: boolean;
  isMilitary?: boolean;
  isProduction: boolean;
  isSafetyPart: boolean;
  to?: string;
  notes?: string;
};

export type PersonTrainingResponse = {
  person: PersonResponse;
  duty: PersonDutyResponse;
};

export type PersonTraining = {
  person: Person;
  duty: Duty;
};

function parsePersonStampHistoryRecord(record: PersonStampHistoryRecordResponse): PersonStampHistoryRecord {
  return {
    ...record,
    from: parseISODate(record.from),
    to: parseISODate(record.to),
    suspension: record.suspension
      ? {
          ...record.suspension,
          from: parseISODate(record.suspension.from),
          to: parseISODate(record.suspension.to),
        }
      : undefined,
  } as PersonStampHistoryRecord;
}

function parsePersonRequirementResponse(requirement: PersonRequirementResponse): PersonRequirement {
  return {
    ...requirement,
    id: requirement.id,
    expireAt: requirement.expireAt === 'MISSING' ? requirement.expireAt : (parseISODate(requirement.expireAt) as Date),
    notifications: requirement.notifications ? requirement.notifications.split(';') : [],
  };
}

function parsePersonDutyResponse(
  duty: PersonDutyResponse,
  stampsById: { [id: string]: PersonStampHistoryRecord }
): Duty {
  return {
    ...duty,
    requirements: duty.requirements?.map(parsePersonRequirementResponse),
    stamp: duty.stampID ? stampsById[duty.stampID] : undefined,
    skills: duty.skills || [],
    from: parseISODate(duty.from),
    to: parseISODate(duty.to),
  };
}

export function parsePersonResponse(person: PersonResponse): Person {
  const parsedStamps = person.stampHistory?.map(parsePersonStampHistoryRecord) || [];
  const stampsById = parsedStamps.reduce(
    (stamps, stamp) => ({
      ...stamps,
      [stamp.id]: stamp,
    }),
    {} as { [id: number]: PersonStampHistoryRecord }
  );
  return {
    ...person,
    endAt: parseISODate(person.endAt),
    stampHistory: person.stampHistory ? parsedStamps : undefined,
    roster: person.roster?.map((duty) => parsePersonDutyResponse(duty, stampsById)),
    requirements: person.requirements?.map(parsePersonRequirementResponse),
  };
}

export default class PeopleRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/people', token);
  }
  async getList(): Promise<Person[]> {
    const { data } = await this.axios.get<PersonResponse[]>('/');
    return data.map(parsePersonResponse);
  }
  async getTraining(): Promise<PersonTraining[]> {
    const { data } = await this.axios.get<PersonTrainingResponse[]>('/training');
    return data.map(
      ({ person, duty }) =>
        ({
          person: parsePersonResponse(person),
          duty: parsePersonDutyResponse(duty, {}),
        } as PersonTraining)
    );
  }
  async query(query: QueryPeopleParams): Promise<Person[]> {
    let url = '/';
    let params;
    if (query.id) {
      url = `/byID/${query.id}`;
      params = { excludeEnded: query.excludeEnded };
    } else if (query.lastName) {
      url = `/byLastname/${query.lastName}`;
      params = { excludeEnded: query.excludeEnded, plant: query.plant };
    } else if (query.qualification) {
      url = `/byQualification/${query.qualification}`;
      params = {
        plant: query.plant,
        excludeEnded: query.excludeEnded,
        excludeInTraining: query.excludeInTraining,
        program: query.program,
        skill: query.skill,
      };
    } else if (query.program !== undefined) {
      //  have to check against undefined because of fake program 0
      url = `/byProgram/${query.program}`;
      params = { plant: query.plant, excludeEnded: query.excludeEnded };
    } else if (query.stamp && query.date) {
      url = `/byStamp/${query.stamp}`;
      params = { date: query.date, excludeEnded: query.excludeEnded };
    }
    const { data } = await this.axios.get<PersonResponse[]>(url, { params });
    return data.map(parsePersonResponse);
  }
  async getDetails(personId: string): Promise<Person> {
    const { data } = await this.axios.get<PersonResponse>(`/${personId}`);
    return parsePersonResponse(data);
  }
  async update(person: Person): Promise<Person> {
    const { data } = await this.axios.patch<PersonResponse>(`/${person.id}`, {
      id: person.id,
      name: person.name,
      lastname: person.lastname,
      email: person.email,
      managerList: person.managerList,
      endAt: formatISODate(person.endAt),
    } as UpdatePersonPayload);
    return parsePersonResponse(data);
  }
  async create(person: Person): Promise<Person> {
    const { data } = await this.axios.post<PersonResponse>('/', person as CreatePersonPayload);
    return parsePersonResponse(data);
  }
  async updateRequirement(person: Person, requirement: PersonRequirement): Promise<Person> {
    const { data } = await this.axios.patch(`/${person.id}/requirements/${requirement.id}`, {
      expireAt: formatISODate(requirement.expireAt),
      notificationsDays: requirement.notificationsDays,
      needsGlasses: requirement.needsGlasses,
      detailsHR: requirement.detailsHR,
      opticalRequirement: requirement.opticalRequirement,
      notes: requirement.notes,
      code: requirement.code,
    });
    return parsePersonResponse(data);
  }
  async deleteRequirement(person: Person, requirement: PersonRequirement): Promise<Person> {
    const { data } = await this.axios.delete(`/${person.id}/requirements/${requirement.id}`, {
      data: {
        code: requirement.code,
        opticalRequirement: requirement.opticalRequirement,
      },
    });
    return parsePersonResponse(data);
  }
  async createRequirement(person: Person, requirement: PersonRequirement): Promise<Person> {
    const { data } = await this.axios.post(`/${person.id}/requirements`, {
      expireAt: formatISODate(requirement.expireAt),
      notificationsDays: requirement.notificationsDays,
      needsGlasses: requirement.needsGlasses,
      detailsHR: requirement.detailsHR,
      opticalRequirement: requirement.opticalRequirement,
      notes: requirement.notes,
      code: requirement.code,
      requirementID: requirement.id,
    });
    return parsePersonResponse(data);
  }
  async createDuty(person: Person, duty: Duty): Promise<Person> {
    const { data } = await this.axios.post(`/${person.id}/roster`, {
      requirements: duty.requirements
        ? duty.requirements.map((requirement) => ({
            requirementID: requirement.id,
            personID: person.id,
            code: requirement.code,
            opticalrequirementID: requirement.opticalRequirement?.id,
          }))
        : undefined,
      plantID: duty.plant.id,
      qualificationID: duty.qualification.id,
      programs: duty.programs.map(({ id }) => ({ id })),
      skills: duty.skills ? duty.skills.map(({ id }) => ({ id })) : undefined,
      stampID: duty.stamp?.id,
      training: duty.training,
      isMilitary: duty.isMilitary,
      isProduction: duty.isProduction,
      isSafetyPart: duty.isSafetyPart,
      from: formatISODate(duty.from),
      to: formatISODate(duty.to),
      notes: duty.notes,
    } as CreateDutyPayload);
    return parsePersonResponse(data);
  }

  async updateDuty(person: Person, duty: Duty): Promise<Person> {
    const { data } = await this.axios.put(`/${person.id}/roster/${duty.id}`, {
      requirements: duty.requirements
        ? duty.requirements.map((requirement) => ({
            requirementID: requirement.id,
            personID: person.id,
            code: requirement.code,
            opticalrequirementID: requirement.opticalRequirement?.id,
          }))
        : undefined,
      plantID: duty.plant.id,
      programs: duty.programs.map(({ id }) => ({ id })),
      skills: duty.skills.map(({ id }) => ({ id })),
      stampID: duty.stamp?.id,
      training: duty.training,
      isMilitary: duty.isMilitary,
      isProduction: duty.isProduction,
      isSafetyPart: duty.isSafetyPart,
      to: formatISODate(duty.to),
      notes: duty.notes,
    } as UpdateDutyPayload);
    return parsePersonResponse(data);
  }

  async deleteDuty(person: Person, duty: Duty): Promise<Person> {
    const { data } = await this.axios.delete(`/${person.id}/roster/${duty.id}`);
    return parsePersonResponse(data);
  }

  async getVisitPrescription(personId: string): Promise<VisualRequirementVisit[]> {
    const { data } = await this.axios.get<VisualRequirementVisit[]>(`/${personId}/visualPrescription`);
    return data;
  }

  async changeID(person: Person, newID: string): Promise<Person> {
    const { data } = await this.axios.put(`/${person.id}/id`, { newID });
    return parsePersonResponse(data);
  }
}
