import React from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from '../../../hooks/store/useAuth';
import appSections from '../../../utils/appSections';
import Spacer from '../../../utils/Spacer';
import CurrentUserAvatar from './CurrentUserAvatar';
import DownloadReportListItem from './DownloadReportListItem';
import ListItemLink from './ListItemLink';

export const DRAWER_WIDTH_LARGE = 240;
export const DRAWER_WIDTH_MINI = 56;

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  drawer: {
    width: DRAWER_WIDTH_LARGE,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerContainer: {
    overflow: 'hidden',
    overflowY: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerOpen: {
    width: DRAWER_WIDTH_LARGE,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: DRAWER_WIDTH_MINI,
  },
}));

type MenuDrawerProps = {
  miniDrawer: boolean;
};

export default function MenuDrawer(props: MenuDrawerProps): JSX.Element {
  const classes = useStyles();
  const { isAdmin } = useAuth();
  const { miniDrawer } = props;

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !miniDrawer,
        [classes.drawerClose]: miniDrawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !miniDrawer,
          [classes.drawerClose]: miniDrawer,
        }),
      }}
    >
      <div className={classes.appBarSpacer} />
      <div className={classes.drawerContainer}>
        <List component="nav">
          {appSections
            .filter((section) => !section.admin || isAdmin)
            .map((section, index) => (
              <ListItemLink
                key={index}
                miniDrawer={miniDrawer}
                to={section.path}
                label={section.shortName}
                tooltip={section.fullName}
                icon={section.icon}
              />
            ))}
        </List>
        <Divider />
        {isAdmin && <DownloadReportListItem />}
        <Spacer />
        <CurrentUserAvatar miniDrawer={miniDrawer} />
      </div>
    </Drawer>
  );
}
