import React from 'react';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';

import TSInputProps from '../../entities/TSInputProps';

const useStyles = makeStyles({
  select: {
    minWidth: '14ch',
    maxWidth: '14ch',
  },
});

interface StampTypeSelectProps
  extends TSInputProps<boolean, false, false>,
    Omit<StandardTextFieldProps, 'value' | 'name' | 'onChange' | 'onBlur'> {}

const options = [
  { label: 'Civile/Militare', value: -1 },
  { label: 'Civile', value: 0 },
  { label: 'Militare', value: 1 },
];

export default function StampTypeSelect(props: StampTypeSelectProps): JSX.Element {
  const classes = useStyles();
  const { label, value, name, onChange, onBlur, className, ...otherProps } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      const value = event.target.value as number;
      onChange(value > 0 ? true : value === 0 ? false : null, name);
    }
  };

  return (
    <TextField
      select
      {...otherProps}
      label={label || 'Ambito'}
      className={clsx(classes.select, className)}
      value={value === null ? -1 : value ? 1 : 0}
      onChange={handleChange}
      onBlur={() => (onBlur ? onBlur(name) : undefined)}
      fullWidth
    >
      {options.map((item) => (
        <MenuItem value={item.value} key={item.label}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
