import { ObjectWithId, RequestError } from '@top-solution/utils';
import { QueryStampsParams } from '../../api/StampRepository';
import { Stamp } from '../../entities/Stamp';

export const QUERY_STAMP_REQUEST = 'QUERY_STAMP_REQUEST';
export const QUERY_STAMP_SUCCESS = 'QUERY_STAMP_SUCCESS';
export const QUERY_STAMP_FAILURE = 'QUERY_STAMP_FAILURE';
export const QUERY_STAMP_CLEAR = 'QUERY_STAMP_CLEAR';
export const CREATE_STAMP_REQUEST = 'CREATE_STAMP_REQUEST';
export const CREATE_STAMP_SUCCESS = 'CREATE_STAMP_SUCCESS';
export const CREATE_STAMP_FAILURE = 'CREATE_STAMP_FAILURE';
export const CREATE_STAMP_CLEAR = 'CREATE_STAMP_CLEAR';
export const READ_STAMP_DETAILS_REQUEST = 'READ_STAMP_DETAILS_REQUEST';
export const READ_STAMP_DETAILS_SUCCESS = 'READ_STAMP_DETAILS_SUCCESS';
export const READ_STAMP_DETAILS_FAILURE = 'READ_STAMP_DETAILS_FAILURE';
export const SUSPEND_STAMP_REQUEST = 'SUSPEND_STAMP_REQUEST';
export const SUSPEND_STAMP_SUCCESS = 'SUSPEND_STAMP_SUCCESS';
export const SUSPEND_STAMP_FAILURE = 'SUSPEND_STAMP_FAILURE';
export const SUSPEND_STAMP_CLEAR = 'SUSPEND_STAMP_CLEAR';
export const ASSIGN_STAMP_REQUEST = 'ASSIGN_STAMP_REQUEST';
export const ASSIGN_STAMP_SUCCESS = 'ASSIGN_STAMP_SUCCESS';
export const ASSIGN_STAMP_FAILURE = 'ASSIGN_STAMP_FAILURE';
export const ASSIGN_STAMP_CLEAR = 'ASSIGN_STAMP_CLEAR';
export const UPDATE_STAMP_REQUEST = 'UPDATE_STAMP_REQUEST';
export const UPDATE_STAMP_SUCCESS = 'UPDATE_STAMP_SUCCESS';
export const UPDATE_STAMP_FAILURE = 'UPDATE_STAMP_FAILURE';
export const UPDATE_STAMP_CLEAR = 'UPDATE_STAMP_CLEAR';

const initialState = {
  list: [] as Stamp[],
  details: {} as { [id: number]: Stamp },
  requests: {
    query: {
      params: {
        shape: null,
        number: null,
        person: null,
        startDate: null,
        endDate: null,
      } as QueryStampsParams,
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    },
    create: {
      stamp: null as Stamp | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    readDetails: {
      id: null as number | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    suspend: {
      id: null as number | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    assign: {
      id: null as number | null,
      inProgress: false,
      error: null as null | RequestError,
    },
    update: {
      id: null as number | null,
      inProgress: false,
      error: null as null | RequestError,
    },
  },
};

export type StampState = typeof initialState;

export interface QueryStampRequestAction {
  type: typeof QUERY_STAMP_REQUEST;
  params: QueryStampsParams;
}
export interface QueryStampSuccessAction {
  type: typeof QUERY_STAMP_SUCCESS;
  list: Array<Stamp>;
}
export interface QueryStampFailureAction {
  type: typeof QUERY_STAMP_FAILURE;
  error: RequestError;
}
export interface QueryStampClearAction {
  type: typeof QUERY_STAMP_CLEAR;
}

export interface CreateStampRequestAction {
  type: typeof CREATE_STAMP_REQUEST;
  stamp: Stamp;
}
export interface CreateStampSuccessAction {
  type: typeof CREATE_STAMP_SUCCESS;
  data: ObjectWithId;
}
export interface CreateStampFailureAction {
  type: typeof CREATE_STAMP_FAILURE;
  error: RequestError;
}
export interface CreateStampClearAction {
  type: typeof CREATE_STAMP_CLEAR;
}

export interface ReadStampDetailsRequestAction {
  type: typeof READ_STAMP_DETAILS_REQUEST;
  id: number;
}
export interface ReadStampDetailsSuccessAction {
  type: typeof READ_STAMP_DETAILS_SUCCESS;
  stamp: Stamp;
}
export interface ReadStampDetailsFailureAction {
  type: typeof READ_STAMP_DETAILS_FAILURE;
  error: RequestError;
}

export interface SuspendStampRequestAction {
  type: typeof SUSPEND_STAMP_REQUEST;
  id: number;
}
export interface SuspendStampSuccessAction {
  type: typeof SUSPEND_STAMP_SUCCESS;
  stamp: Stamp;
}
export interface SuspendStampFailureAction {
  type: typeof SUSPEND_STAMP_FAILURE;
  error: RequestError;
}
export interface SuspendStampClearAction {
  type: typeof SUSPEND_STAMP_CLEAR;
}

export interface AssignStampRequestAction {
  type: typeof ASSIGN_STAMP_REQUEST;
  id: number;
}
export interface AssignStampSuccessAction {
  type: typeof ASSIGN_STAMP_SUCCESS;
  stamp: Stamp;
}
export interface AssignStampFailureAction {
  type: typeof ASSIGN_STAMP_FAILURE;
  error: RequestError;
}
export interface AssignStampClearAction {
  type: typeof ASSIGN_STAMP_CLEAR;
}

export interface UpdateStampRequestAction {
  type: typeof UPDATE_STAMP_REQUEST;
  id: number;
}
export interface UpdateStampSuccessAction {
  type: typeof UPDATE_STAMP_SUCCESS;
  stamp: Stamp;
}
export interface UpdateStampFailureAction {
  type: typeof UPDATE_STAMP_FAILURE;
  error: RequestError;
}
export interface UpdateStampClearAction {
  type: typeof UPDATE_STAMP_CLEAR;
}

type ActionType =
  | QueryStampRequestAction
  | QueryStampSuccessAction
  | QueryStampFailureAction
  | QueryStampClearAction
  | CreateStampRequestAction
  | CreateStampSuccessAction
  | CreateStampFailureAction
  | CreateStampClearAction
  | ReadStampDetailsRequestAction
  | ReadStampDetailsSuccessAction
  | ReadStampDetailsFailureAction
  | SuspendStampRequestAction
  | SuspendStampSuccessAction
  | SuspendStampFailureAction
  | SuspendStampClearAction
  | AssignStampRequestAction
  | AssignStampSuccessAction
  | AssignStampFailureAction
  | AssignStampClearAction
  | UpdateStampRequestAction
  | UpdateStampSuccessAction
  | UpdateStampFailureAction
  | UpdateStampClearAction;

export default function stampReducer(state = initialState, action: ActionType): StampState {
  switch (action.type) {
    case QUERY_STAMP_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            params: action.params,
            inProgress: true,
          },
        },
      };
    }
    case QUERY_STAMP_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case QUERY_STAMP_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case QUERY_STAMP_CLEAR: {
      return {
        ...state,
        list: [],
        requests: {
          ...state.requests,
          query: {
            ...initialState.requests.query,
          },
        },
      };
    }
    case CREATE_STAMP_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            stamp: action.stamp,
            inProgress: true,
          },
        },
      };
    }
    case CREATE_STAMP_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            stamp: {
              ...state.requests.create.stamp,
              id: action.data.id as number,
            } as Stamp,
            inProgress: false,
          },
        },
      };
    }
    case CREATE_STAMP_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case CREATE_STAMP_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...initialState.requests.create,
          },
        },
      };
    }
    case READ_STAMP_DETAILS_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case READ_STAMP_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          [action.stamp.id]: action.stamp,
        },
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            inProgress: false,
          },
        },
      };
    }
    case READ_STAMP_DETAILS_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readDetails: {
            ...state.requests.readDetails,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case SUSPEND_STAMP_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          suspend: {
            ...state.requests.suspend,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case SUSPEND_STAMP_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          [action.stamp.id]: action.stamp,
        },
        requests: {
          ...state.requests,
          suspend: {
            ...state.requests.suspend,
            inProgress: false,
          },
        },
      };
    }
    case SUSPEND_STAMP_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          suspend: {
            ...state.requests.suspend,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case SUSPEND_STAMP_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          suspend: {
            ...initialState.requests.suspend,
          },
        },
      };
    }
    case ASSIGN_STAMP_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          assign: {
            ...state.requests.assign,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case ASSIGN_STAMP_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          [action.stamp.id]: action.stamp,
        },
        requests: {
          ...state.requests,
          assign: {
            ...state.requests.assign,
            inProgress: false,
          },
        },
      };
    }
    case ASSIGN_STAMP_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          assign: {
            ...state.requests.assign,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case ASSIGN_STAMP_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          assign: {
            ...initialState.requests.assign,
          },
        },
      };
    }
    case UPDATE_STAMP_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            id: action.id,
            inProgress: true,
          },
        },
      };
    }
    case UPDATE_STAMP_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          [action.stamp.id]: action.stamp,
        },
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
          },
        },
      };
    }
    case UPDATE_STAMP_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPDATE_STAMP_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...initialState.requests.update,
          },
        },
      };
    }
    default:
      return state;
  }
}
