import { Program, PROGRAM_ALL_ID } from '../entities/Program';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class ProgramRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/programs', token);
  }
  async getList(): Promise<Program[]> {
    const { data } = await this.axios.get<Program[]>('/');
    const programAllIndex = data.findIndex(({ id }) => id === PROGRAM_ALL_ID);
    const programAll = data.splice(programAllIndex, 1);
    return [...programAll, ...data];
  }
}
