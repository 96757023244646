import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, Resource, useResource } from '@top-solution/utils';
import AppSettingsRepository from '../../api/AppSettingsRepository';
import { BasicRequest } from '../../entities/BasicRequest';
import { AppSettings } from '../../entities/CurrentUser';
import { RootState } from '../../store/reducers';
import {
  readErrorAction,
  readRequestAction,
  readSuccessAction,
  setErrorAction,
  setRequestAction,
  setSuccessAction,
} from '../../store/reducers/appSettings';
import { useAuth } from './useAuth';

type UseAppSettings = {
  appSettings: AppSettings;
  readAppSettingsRequest: RequestState;
  readAppSettings: () => Promise<AppSettings | undefined>;
  setAppSettingsRequest: BasicRequest;
  setAppSettings: (appSettings: AppSettings) => Promise<void>;
};

export function useAppSettings(): UseAppSettings {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new AppSettingsRepository(token), [token]);

  const appSettings = useSelector((state: RootState) => state.appSettings.data);

  const readAppSettingsRequest = useSelector((state: RootState) => state.appSettings.request.read);
  const setAppSettingsRequest = useSelector((state: RootState) => state.appSettings.request.set);

  const readAppSettings = useCallback(async () => {
    try {
      dispatch(readRequestAction());
      const config = await api.read();
      dispatch(readSuccessAction({ config }));
      return config;
    } catch (error) {
      dispatch(readErrorAction({ error: new RequestError(error) }));
    }
  }, [api, dispatch]);

  const setAppSettings = useCallback(
    async (config: AppSettings) => {
      try {
        dispatch(setRequestAction({ config }));
        await api.set(config);
        dispatch(setSuccessAction());
      } catch (error) {
        dispatch(setErrorAction({ error: new RequestError(error) }));
      }
    },
    [api, dispatch]
  );

  return {
    appSettings,
    readAppSettingsRequest,
    readAppSettings,
    setAppSettingsRequest,
    setAppSettings,
  };
}

export function useAppSettingsResource(): Resource<AppSettings, RequestState> {
  const { appSettings, readAppSettings, readAppSettingsRequest } = useAppSettings();

  return useResource('app_config', appSettings, readAppSettings, readAppSettingsRequest);
}
