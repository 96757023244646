import { Repository } from '@top-solution/utils';

export default class DigitalRosterRepository extends Repository {
  constructor(resourceURL: string, token: string) {
    super({
      apiURL: '/api',
      resourceURL,
      axiosConfig: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  }
}
