import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { endOfToday } from 'date-fns';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressButton, TextField, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { StampSuspensionReason, StampSuspension, Stamp } from '../../../entities/Stamp';
import { useStamp } from '../../../hooks/store/useStamp';
import { defaultMinDate } from '../../../utils/date';
import Spacer from '../../../utils/Spacer';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import StampSuspensionReasonSelect from '../../inputs/StampSuspensionReasonSelect';
import FormRow from '../../layout/FormRow';

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

type SuspendStampForm = {
  reason: StampSuspensionReason | null;
  from: Date;
  notes: string;
  confirm: string;
};

type SuspendStampDialogProps = {
  open: boolean;
  handleClose: (suspended: boolean) => void;
  stamp: Stamp;
};

const initialValues = {
  reason: null as null | StampSuspensionReason,
  from: endOfToday(),
  notes: '',
  confirm: '',
};

export default function SuspendStampDialog(props: SuspendStampDialogProps): JSX.Element {
  const classes = useStyles();
  const { open, handleClose, stamp } = props;
  const { suspendStamp, suspendStampRequest, suspendStampClear } = useStamp();

  const minDate = useMemo(
    () => stamp.suspension?.to || stamp.owner?.to || stamp.owner?.from || defaultMinDate,
    [stamp.owner, stamp.suspension]
  );

  const getSuspensionReason = (reason: StampSuspensionReason | null) =>
    reason?.duration === -1 ? 'revoca' : 'sospendi';

  const schema = useMemo(
    () =>
      validate.object().shape({
        reason: validate.mixed().required(),
        from: validate.date().min(minDate).max(endOfToday()).required(),
        notes: validate.string(),
        confirm: validate
          .string()
          .when('reason', (reason: StampSuspensionReason | null, schema: validate.StringSchema) =>
            schema.equals([getSuspensionReason(reason)])
          )
          .required(),
      }),
    [minDate]
  );
  const handleSubmit = useCallback(
    async (values: SuspendStampForm) => {
      const response = await suspendStamp(stamp.id, {
        reason: values.reason,
        from: values.from,
        notes: values.notes,
      } as StampSuspension);
      if (response?.id) {
        handleClose(true);
        suspendStampClear();
      }
    },
    [handleClose, stamp.id, suspendStamp, suspendStampClear]
  );

  const { form, setValue, setTouched, onSubmit } = useForm<SuspendStampForm>({ initialValues, schema, handleSubmit });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        suspendStampClear();
      }
    },
    [handleClose, suspendStampClear]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Sospensione timbro</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <StampSuspensionReasonSelect
            value={form.values.reason}
            onChange={(value) => setValue(value, 'reason')}
            onBlur={() => setTouched('reason')}
            error={form.errors.reason && form.touched.reason}
            helperText={form.touched.reason && form.errors.reason?.message}
            required
          />
          <DatePicker
            label="Data sospensione"
            minDate={minDate}
            value={form.values.from}
            onChange={(value) => setValue(value, 'from')}
            onBlur={() => setTouched('from')}
            error={form.errors.from && form.touched.from}
            helperText={form.touched.from && form.errors.from?.message}
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        <Alert
          severity={form.values.reason && form.values.reason?.duration > 0 ? 'warning' : 'error'}
          className={clsx({ [classes.hidden]: !form.values.reason })}
        >
          <AlertTitle>Attenzione</AlertTitle>
          {form.values.reason && form.values.reason.duration > 0
            ? `Il timbro verrà sospeso e non potrà essere riassegnato per 2 anni.`
            : 'Il timbro verrà revocato e non potrà più essere riassegnato.'}
          <br />
          Per continuare digita “{getSuspensionReason(form.values.reason)}”:
          <TextField
            value={form.values.confirm}
            onChange={(value) => setValue(value, 'confirm')}
            onBlur={() => setTouched('confirm')}
            error={form.errors.confirm && form.touched.confirm}
            disableClearable
            fullWidth
            required
          />
        </Alert>
        <ErrorAlert error={suspendStampRequest.error} className={classes.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={suspendStampRequest.inProgress}
        >
          Procedi
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
