import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DetailsIcon from 'mdi-material-ui/TextBoxSearch';

import { usePersonTrainingListResource } from '../../../hooks/store/usePerson';
import { useTableHiddenColumns } from '../../../hooks/store/useTableHiddenColumns';
import { trainingSection as section } from '../../../utils/appSections';
import { useTableOptions, RowActionsWrapper } from '../../../utils/table';
import AppLayout from '../../layout/Layout';
import RequestResult from '../../RequestResult';

export default function Training(): JSX.Element {
  const [personTrainingList, readPersonTrainingListRequest] = usePersonTrainingListResource();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('training_table');

  const { tableOptions } = useTableOptions(
    {
      tableBodyMaxHeight: 'calc(100vh - 196px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    'Digital Roster - export personale in training.csv'
  );

  const columns = useMemo(
    () => [
      { label: 'Matricola', name: 'id', options: { display: !hiddenColumns?.['id'] } },
      { label: 'Cognome', name: 'lastName', options: { display: !hiddenColumns?.['lastName'] } },
      { label: 'Nome', name: 'name', options: { display: !hiddenColumns?.['name'] } },
      { label: 'Email', name: 'email', options: { display: !hiddenColumns?.['email'] } },
      { label: 'Mansione', name: 'duty', options: { display: !hiddenColumns?.['duty'] } },
      {
        label: ' ',
        name: 'actions',
        options: {
          sort: false,
          filter: false,
          download: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <RowActionsWrapper>
              <Tooltip title="Dettagli" placement="top" arrow>
                <IconButton component={Link} to={`/person/${personTrainingList[dataIndex].person.id}`}>
                  <DetailsIcon />
                </IconButton>
              </Tooltip>
            </RowActionsWrapper>
          ),
        },
      },
    ],
    [hiddenColumns, personTrainingList]
  );

  const data = useMemo(
    () =>
      personTrainingList.map(({ person, duty }) => ({
        id: person.id,
        lastName: person.lastname,
        name: person.name,
        email: person.email || '–',
        duty: duty.qualification.name,
      })),
    [personTrainingList]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false}>
        <RequestResult
          submitted={Boolean(readPersonTrainingListRequest.lastUpdate)}
          request={readPersonTrainingListRequest}
          empty={personTrainingList.length === 0}
          emptyTitle="Nessun risultato"
          emptyDescription="Non sono state trovate persone corrispondenti ai filtri selezionati"
        >
          <MUIDataTable title={section.shortName} columns={columns} data={data} options={tableOptions} />
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
