import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { RequestError, RequestState, Resource, useResource } from '@top-solution/utils';
import NotificationsEmailListRepository from '../../api/NotificationsEmailListRepository';
import { BasicRequest } from '../../entities/BasicRequest';
import { NotificationsEmailList } from '../../entities/NotificationsEmailList';
import { RootState } from '../../store/reducers';
import {
  readErrorAction,
  readRequestAction,
  readSuccessAction,
  setErrorAction,
  setRequestAction,
  setSuccessAction,
} from '../../store/reducers/qualityNotificationsEmail';
import { useAuth } from './useAuth';

type UseNotificationsEmailList = {
  notificationsEmailList: NotificationsEmailList;
  readQualityNotificationsEmailListRequest: RequestState;
  readQualityNotificationsEmailList: () => Promise<NotificationsEmailList | undefined>;
  setQualityNotificationsEmailListRequest: BasicRequest;
  setQualityNotificationsEmailList: (notifications: NotificationsEmailList) => Promise<void>;
};

export function useNotificationsEmailList(): UseNotificationsEmailList {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new NotificationsEmailListRepository(token), [token]);

  const notificationsEmailList = useSelector((state: RootState) => state.notificationsEmailList.data);

  const readQualityNotificationsEmailListRequest = useSelector(
    (state: RootState) => state.notificationsEmailList.request.read
  );
  const setQualityNotificationsEmailListRequest = useSelector(
    (state: RootState) => state.notificationsEmailList.request.set
  );

  const readQualityNotificationsEmailList = useCallback(async () => {
    try {
      dispatch(readRequestAction());
      const data = await api.read();
      dispatch(readSuccessAction({ data }));
      return data;
    } catch (error) {
      dispatch(readErrorAction({ error: new RequestError(error as AxiosError) }));
    }
  }, [api, dispatch]);

  const setQualityNotificationsEmailList = useCallback(
    async (data: NotificationsEmailList) => {
      try {
        dispatch(setRequestAction());
        await api.set(data);
        dispatch(setSuccessAction({ data }));
      } catch (error) {
        dispatch(setErrorAction({ error: new RequestError(error as AxiosError) }));
      }
    },
    [api, dispatch]
  );

  return {
    notificationsEmailList,
    readQualityNotificationsEmailListRequest,
    readQualityNotificationsEmailList,
    setQualityNotificationsEmailListRequest,
    setQualityNotificationsEmailList,
  };
}

export function useNotificationsEmailListResource(): Resource<NotificationsEmailList, RequestState> {
  const { notificationsEmailList, readQualityNotificationsEmailList, readQualityNotificationsEmailListRequest } =
    useNotificationsEmailList();

  return useResource(
    'quality_notifications_email_list',
    notificationsEmailList,
    readQualityNotificationsEmailList,
    readQualityNotificationsEmailListRequest
  );
}
