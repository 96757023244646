import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { StampShape } from '../../entities/Stamp';
import Shapes from './shapes';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '30px',
  },
  icon: {
    fill: theme.palette.text.secondary,
  },
}));

type StampShapeProps = {
  shape: StampShape;
  className?: string;
};

export default function StampShapeComponent(props: StampShapeProps): JSX.Element {
  const classes = useStyles();
  const { shape, className } = props;

  return (
    <span className={clsx(classes.wrapper, className)}>
      <SvgIcon component={Shapes[shape.id]} className={classes.icon} fontSize="small" />
    </span>
  );
}
