import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from 'mdi-material-ui/TrashCan';
import { CheckBox } from '@top-solution/mui-inputs';
import { Person } from '../../../../entities/Person';
import { PersonRequirement } from '../../../../entities/Requirement';
import Spacer from '../../../../utils/Spacer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.25, 1),
    // margin: theme.spacing(0, -1),
  },
  oldExpires: {
    color: theme.palette.error.dark,
    textDecoration: 'line-through',
  },
  newExpires: {
    color: theme.palette.success.dark,
  },
}));

type RenewRequirementRowProps = {
  expireAt: Date;
  person: Person;
  requirement: PersonRequirement;
  onChange: (requirement: PersonRequirement, person: Person) => void;
  onDelete: (requirement: PersonRequirement, person: Person) => void;
};

export default function RenewRequirementRow(props: RenewRequirementRowProps): JSX.Element {
  const classes = useStyles();
  const { expireAt, person, requirement, onChange, onDelete } = props;

  return (
    <li className={classes.wrapper}>
      <span>
        {person.id} {person.lastname} {person.name}
      </span>

      {requirement.opticalRequirement && <strong>{requirement.opticalRequirement.name}</strong>}

      {requirement.code && <strong>(codice: {requirement.code})</strong>}

      <Spacer />

      {requirement.opticalRequirement && (
        <>
          <CheckBox
            label="Necessita lenti correttive"
            value={Boolean(requirement.needsGlasses)}
            onChange={(value) =>
              onChange(
                {
                  ...requirement,
                  needsGlasses: value,
                },
                person
              )
            }
          />{' '}
          <CheckBox
            label="Dettagli c/o HR"
            value={Boolean(requirement.detailsHR)}
            onChange={(value) =>
              onChange(
                {
                  ...requirement,
                  detailsHR: value,
                },
                person
              )
            }
          />
        </>
      )}

      <span className={classes.oldExpires}>
        {new Date(requirement.expireAt).toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </span>

      <span className={classes.newExpires}>
        {expireAt.toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </span>

      <Tooltip title="Rimuovi" placement="top" arrow disableInteractive>
        <IconButton onClick={() => onDelete(requirement, person)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </li>
  );
}
