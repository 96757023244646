import { ObjectWithId } from '@top-solution/utils';
import { QualificationRequirement } from './Requirement';

export const NAME_SEPARATOR = ' | ';

export interface Qualification extends ObjectWithId {
  id: number;
  name: string;
  requirements: QualificationRequirement[];
}
