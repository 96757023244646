import { styled } from '@material-ui/core/styles';

const FormRow = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),
  marginBottom: theme.spacing(3),

  '& > *': {
    flex: '1 0 0%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default FormRow;
