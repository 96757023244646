import React, { useCallback } from 'react';
import { TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { StampShape } from '../../../entities/Stamp';
import { useStamp } from '../../../hooks/store/useStamp';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import StampShapeSelect from '../../inputs/StampShapeSelect';

type FilterByShapeForm = {
  code: string;
  shape: StampShape | null;
};

const initialValues = {
  code: '',
  shape: null,
};
const schema = validate.object().shape({
  shape: validate.mixed().required(),
  code: validate.string().required(),
});

export default function FilterByShape(): JSX.Element {
  const { queryStampList, queryStampListRequest } = useStamp();
  const handleSubmit = useCallback(
    (values: FilterByShapeForm) => queryStampList({ shape: values.shape?.id, code: values.code }),
    [queryStampList]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterByShapeForm>({ initialValues, schema, handleSubmit });

  return (
    <FilterForm onSubmit={onSubmit} request={queryStampListRequest} disabled={!form.isValid}>
      <StampShapeSelect
        value={form.values.shape}
        onChange={(value) => setValue(value, 'shape')}
        onBlur={() => setTouched('shape')}
        error={form.errors.shape && form.touched.shape}
        helperText={form.touched.shape && form.errors.shape?.message}
      />
      <TextField
        style={{ flexGrow: 1, maxWidth: '36ch' }}
        label="Numero"
        value={form.values.code}
        onChange={(value) => setValue(value, 'code')}
        onBlur={() => setTouched('code')}
        error={form.errors.code && form.touched.code}
        helperText={
          (form.touched.code && form.errors.code?.message) || 'Utilizzare il carattere * per numeri incompleti'
        }
      />
    </FilterForm>
  );
}
