import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TokenRepository from '../../api/TokenRepository';
import { UserRole } from '../../entities/User';
import { RootState } from '../../store/reducers';
import { setTokenAction } from '../../store/reducers/auth';

type UseAuth = {
  token: string;
  lastUpdate: number | null;
  username: string | null;
  firstname: string | null;
  lastname: string | null;
  roles: string[] | null;
  expiration: number | null;
  isAdmin: boolean;
  isExpired: () => boolean;
  setToken: (token: string) => void;
  refreshToken: () => void;
};

export function useAuth(): UseAuth {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  const lastUpdate = useSelector((state: RootState) => state.auth.lastUpdate);
  const username = useSelector((state: RootState) => state.auth.username);
  const firstname = useSelector((state: RootState) => state.auth.firstname);
  const lastname = useSelector((state: RootState) => state.auth.lastname);
  const roles = useSelector((state: RootState) => state.auth.roles);
  const expiration = useSelector((state: RootState) => state.auth.expiration);

  const isAdmin = useMemo(() => Boolean(roles?.find((role) => role === UserRole.ADMIN)), [roles]);

  const isExpired = useCallback(() => expiration !== null && Date.now() >= expiration, [expiration]);

  const api = useMemo(() => new TokenRepository(token), [token]);

  const setToken = useCallback(
    (token: string) => {
      dispatch(setTokenAction({ token }));
    },
    [dispatch]
  );

  const refreshToken = useCallback(async () => {
    const token = await api.get();
    setToken(token);
  }, [api, setToken]);

  return {
    token,
    lastUpdate,
    username,
    firstname,
    lastname,
    roles,
    expiration,
    isAdmin,
    isExpired,
    setToken,
    refreshToken,
  };
}
