import React from 'react';
import Alert, { Color, AlertProps } from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RequestError } from '@top-solution/utils';

import ErrorMessage, { ErrorMessageProps } from './ErrorMessage';

const useStyles = makeStyles({
  pre: {
    whiteSpace: 'pre-wrap',
  },
});

export interface ErrorAlertProps extends ErrorMessageProps, AlertProps {
  showDetails?: boolean;
}

export default function ErrorAlert(props: ErrorAlertProps): JSX.Element {
  const classes = useStyles();
  const { error, showDetails, ...otherProps } = props;

  if (!error) {
    return <></>;
  }
  let details = error.stack;
  let severity = 'error';
  const reqError = error as RequestError;
  if (reqError.axiosError?.response?.data) {
    details = JSON.stringify(reqError.axiosError.response?.data, null, 2);
    if (parseInt(reqError.code || '-1') < 500) {
      severity = 'warning';
    }
  }
  return (
    <Alert variant="outlined" severity={severity as Color} {...otherProps}>
      <AlertTitle>Oops, something went wrong</AlertTitle>
      <p>
        <ErrorMessage error={error} />
      </p>
      {showDetails ? (
        <div>
          <Typography variant="subtitle1">Details</Typography>
          <pre className={classes.pre}>{details}</pre>
        </div>
      ) : null}
    </Alert>
  );
}
