import { RequestError, RequestState } from '@top-solution/utils';
import { Qualification } from '../../entities/Qualification';

export const READ_QUALIFICATION_LIST_REQUEST = 'READ_QUALIFICATION_LIST_REQUEST';
export const READ_QUALIFICATION_LIST_SUCCESS = 'READ_QUALIFICATION_LIST_SUCCESS';
export const READ_QUALIFICATION_LIST_FAILURE = 'READ_QUALIFICATION_LIST_FAILURE';

const initialState = {
  list: [] as Array<Qualification>,
  requests: {
    readList: {
      inProgress: false,
      error: null,
      lastUpdate: null,
    } as RequestState,
  },
};

export type QualificationState = typeof initialState;

export interface ReadQualificationListRequestAction {
  type: typeof READ_QUALIFICATION_LIST_REQUEST;
}
export interface ReadQualificationListSuccessAction {
  type: typeof READ_QUALIFICATION_LIST_SUCCESS;
  list: Array<Qualification>;
}
export interface ReadQualificationListFailureAction {
  type: typeof READ_QUALIFICATION_LIST_FAILURE;
  error: RequestError;
}

type ActionType =
  | ReadQualificationListRequestAction
  | ReadQualificationListSuccessAction
  | ReadQualificationListFailureAction;

export default function qualificationReducer(state = initialState, action: ActionType): QualificationState {
  switch (action.type) {
    case READ_QUALIFICATION_LIST_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    }
    case READ_QUALIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_QUALIFICATION_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
