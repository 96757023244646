import { AppSettings } from '../entities/CurrentUser';
import CommonDataRepository from './CommonDataRepository';

export default class AppSettingsRepository extends CommonDataRepository {
  constructor(token: string) {
    super('/v1/config', token);
  }

  async read(): Promise<AppSettings> {
    const { data } = await this.axios.get<AppSettings>('/');
    return data;
  }

  async set(config: AppSettings): Promise<void> {
    return this.axios.put('/', config);
  }
}
