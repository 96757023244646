import React, { useEffect } from 'react';
import { Autocomplete, AutocompleteProps } from '@top-solution/mui-inputs';

import { Stamp, StampShape } from '../../entities/Stamp';
import { useStamp } from '../../hooks/store/useStamp';

interface StampSelectProps extends Omit<AutocompleteProps<string, Stamp, false, false>, 'options' | 'request'> {
  shape: StampShape | null;
}

export default function StampSelect(props: StampSelectProps): JSX.Element {
  const { shape, label, ...otherProps } = props;
  const { stampList, queryStampList, queryStampListRequest } = useStamp();

  useEffect(() => {
    if (shape) {
      queryStampList({ shape: shape.id, code: '*' });
    }
  }, [queryStampList, shape]);

  return (
    <Autocomplete
      {...otherProps}
      label={label || 'Numero'}
      options={stampList}
      request={queryStampListRequest}
      getOptionLabel={({ code }) => code}
    />
  );
}
