import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import RequirementRepository from '../../api/RequirementRepository';
import { AbstractRequirement } from '../../entities/Requirement';
import { RootState } from '../../store/reducers';
import {
  ReadRequirementListFailureAction,
  ReadRequirementListRequestAction,
  ReadRequirementListSuccessAction,
  READ_REQUIREMENT_LIST_FAILURE,
  READ_REQUIREMENT_LIST_REQUEST,
  READ_REQUIREMENT_LIST_SUCCESS,
} from '../../store/reducers/requirement';
import { useAuth } from './useAuth';

type UseRequirement = {
  requirementList: AbstractRequirement[];
  readRequirementListRequest: RequestState;
  readRequirementList: () => Promise<void>;
};

export function useRequirement(): UseRequirement {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new RequirementRepository(token), [token]);

  const requirementList = useSelector((state: RootState) => state.requirement.list);
  const readRequirementListRequest = useSelector((state: RootState) => state.requirement.requests.readList);

  const readRequirementList = useCallback(() => {
    dispatch<ReadRequirementListRequestAction>({ type: READ_REQUIREMENT_LIST_REQUEST });
    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadRequirementListSuccessAction>({ type: READ_REQUIREMENT_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadRequirementListFailureAction>({
          type: READ_REQUIREMENT_LIST_FAILURE,
          error: new RequestError(error),
        });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    requirementList,
    readRequirementList,
    readRequirementListRequest,
  };
}

export function useRequirementListResource(): [AbstractRequirement[], RequestState, RequestError | null, () => void] {
  const { requirementList, readRequirementList, readRequirementListRequest } = useRequirement();

  return useResource<typeof requirementList, RequestState>(
    'RequirementList',
    requirementList,
    readRequirementList,
    readRequirementListRequest
  );
}
