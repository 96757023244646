import React from 'react';
import Autocomplete from '@material-ui/core/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { RequirementNotificationsEmailList } from '../../../entities/NotificationsEmailList';

type ManageNotificationsFormRowProps = {
  value: RequirementNotificationsEmailList;
  onChange: (value: RequirementNotificationsEmailList) => void;
  error?: string;
};

export default function ManageNotificationsFormRow(props: ManageNotificationsFormRowProps): JSX.Element {
  const { value, onChange, error } = props;

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      disableClearable
      options={[]}
      renderTags={(value: string[][], getTagProps) =>
        value.map((option: unknown, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip variant="outlined" label={option as string} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label={value.name} placeholder="Email" error={Boolean(error)} helperText={error} />
      )}
      value={value.defaultNotifications}
      onChange={(_, defaultNotifications) =>
        onChange({ ...value, defaultNotifications: defaultNotifications as string[] })
      }
      sx={{ marginTop: 4 }}
    />
  );
}
