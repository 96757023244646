import { StampType } from '../entities/Stamp';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class StampTypeRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/stampTypes', token);
  }
  async getList(): Promise<StampType[]> {
    const { data } = await this.axios.get<StampType[]>('/');
    return data;
  }
}
