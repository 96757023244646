import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../../hooks/store/useAuth';
import Unauthorized from './Unauthorized';

type AuthenticatedRouteProps = RouteProps & {
  admin?: boolean;
};

function AuthenticatedRoute(props: AuthenticatedRouteProps): React.ReactElement {
  const { component, admin, ...routeProps } = props;
  const { lastUpdate, isAdmin, isExpired } = useAuth();

  if (!lastUpdate || isExpired()) {
    const authApi = process.env.REACT_APP_AUTH_API;
    const appId = process.env.REACT_APP_ID;
    const redirectUri = encodeURIComponent(`${document.location.origin}/auth?path=${document.location.pathname}`);
    document.location.href = `${authApi}/login?appID=${appId}&redirectUri=${redirectUri}`;
    return <CircularProgress color="secondary" />;
  }
  return <Route component={admin && !isAdmin ? Unauthorized : component} {...routeProps} />;
}

export default React.memo(AuthenticatedRoute);
