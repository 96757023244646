import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PrescriptionIcon from 'mdi-material-ui/TextBoxCheck';

import { Person } from '../../../../entities/Person';
import { useTableOptions } from '../../../../utils/table';

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3 / 4),
    zIndex: 1,
  },
  downloadVisitPrescriptionButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(10),
    zIndex: 1,
  },
  reqName: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: theme.spacing(1),
    },

    '& strong': {
      marginRight: theme.spacing(1 / 2),
    },
  },
  emailColumn: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  email: {
    margin: 2,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

type PersonMissingRequirementListProps = {
  person: Person | null;
};

const columns = [
  {
    label: 'Requisito',
    name: 'requirement',
  },
  {
    label: 'Requisito visivo',
    name: 'optical',
  },
];

export default function PersonMissingRequirementList(props: PersonMissingRequirementListProps): JSX.Element {
  const classes = useStyles();
  const { person } = props;

  const { tableOptions } = useTableOptions(
    {
      elevation: 0,
      tableBodyMaxHeight: 'calc(100vh - 342px)',
    },
    person
      ? `${person.id} ${person.lastname} ${person.name} - export requisiti mancanti.csv`
      : 'Digital Roster - export requisiti mancanti.csv'
  );

  const data = useMemo(
    () =>
      person?.missingRequirements?.map((requirement) => ({
        requirement: requirement.name,
        optical: requirement.opticalRequirement?.name || '–',
      })) || [],
    [person]
  );

  return (
    <>
      {person?.missingRequirements && person.missingRequirements.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PrescriptionIcon />}
          className={classes.downloadVisitPrescriptionButton}
          component={Link}
          to={`/person/${person?.id}/visit-prescription`}
        >
          Dichiarazione rispondenza visiva
        </Button>
      )}
      {!person?.missingRequirements || person.missingRequirements.length === 0 ? (
        <Alert severity="info" variant="outlined">
          Non sono stati trovati requisiti per questo utente
        </Alert>
      ) : (
        <MUIDataTable title="" columns={columns} data={data} options={tableOptions} />
      )}
    </>
  );
}
