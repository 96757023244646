import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useAuth } from '../../hooks/store/useAuth';
import appSections from '../../utils/appSections';
import AppLayout from '../layout/Layout';

const useStyles = makeStyles((theme) => ({
  home: {
    margin: '0 auto',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    maxWidth: theme.spacing(66),
    width: '100%',
  },
  sectionLink: {
    padding: theme.spacing(1),
    width: theme.spacing(30),
    height: theme.spacing(30),
    '& .MuiButton-label': {
      flexDirection: 'column',
    },
  },
  label: {
    maxWidth: '12ch',
    textAlign: 'center',
  },
  iconWrapper: {
    display: 'block',
    '& .MuiSvgIcon-root': {
      height: '5em',
      width: '5em',
    },
  },
}));

export default function Home(): JSX.Element {
  const classes = useStyles();
  const { isAdmin } = useAuth();

  return (
    <AppLayout>
      <Helmet>
        <title>Digital Roster</title>
      </Helmet>
      <Grid container justifyContent="center" flexGrow={1} spacing={2} className={classes.home}>
        {appSections
          .filter((section) => !section.admin || isAdmin)
          .map(({ path, icon, fullName }, index) => (
            <Grid item key={index}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                component={Link}
                to={path}
                className={classes.sectionLink}
              >
                <span className={classes.iconWrapper}>{icon}</span>
                <Typography component="h2" variant="h5" className={classes.label}>
                  {fullName}
                </Typography>
              </Button>
            </Grid>
          ))}
      </Grid>
    </AppLayout>
  );
}
