import { useCallback } from 'react';
import { useAppSettings, useAppSettingsResource } from './useAppSettings';

type UseTableHiddenColumns = {
  hiddenColumns: Record<string, true | undefined>;
  setHiddenColumn: (column: string, hidden: boolean) => void;
};

export function useTableHiddenColumns(table: string): UseTableHiddenColumns {
  const { setAppSettings } = useAppSettings();
  const [appSettings] = useAppSettingsResource();

  const setHiddenColumn = useCallback(
    (column: string, hidden: boolean) => {
      setAppSettings({
        ...appSettings,
        hiddenColumnsByTable: {
          ...appSettings.hiddenColumnsByTable,
          [table]: {
            ...appSettings.hiddenColumnsByTable[table],
            [column]: hidden || undefined,
          },
        },
      });
    },
    [appSettings, setAppSettings, table]
  );

  return {
    hiddenColumns: appSettings.hiddenColumnsByTable[table],
    setHiddenColumn,
  };
}
