import React, { useMemo } from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../hooks/store/useAuth';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: theme.spacing(2),
  },
  listItemMini: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

type CurrentUserAvatarProps = {
  miniDrawer: boolean;
};

export default function CurrentUserAvatar(props: CurrentUserAvatarProps): JSX.Element {
  const { miniDrawer } = props;
  const classes = useStyles();
  const auth = useAuth();

  const initials = useMemo(() => {
    let initials = '';

    if (auth.firstname) {
      initials += auth.firstname.charAt(0);
    }
    if (auth.lastname) {
      initials += auth.lastname.charAt(0);
    }
    if (initials.length === 0 && auth.username) {
      initials = auth.username.charAt(0);
    }

    return initials;
  }, [auth.firstname, auth.lastname, auth.username]);

  if (!auth.token) {
    return <></>;
  }

  return (
    <ListItem
      dense
      className={clsx(classes.listItem, {
        [classes.listItemMini]: miniDrawer,
      })}
    >
      <ListItemIcon>
        <Avatar>{initials.toUpperCase()}</Avatar>
      </ListItemIcon>
      <ListItemText primary={auth.lastname || auth.username} secondary={auth.firstname} />
    </ListItem>
  );
}
