import React, { useCallback } from 'react';
import { subYears, endOfToday } from 'date-fns';
import { DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../entities/Person';
import { useStamp } from '../../../hooks/store/useStamp';
import { formatISODate, defaultMinDate } from '../../../utils/date';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PersonSelect from '../../inputs/PersonSelect';

type FilterByOwnerForm = {
  from: Date;
  to: Date;
  owner: Person | null;
};

const today = endOfToday();
const initialValues = {
  from: subYears(today, 1),
  to: today,
  owner: null,
};
const schema = validate.object().shape({
  owner: validate.mixed().required(),
  from: validate.date().min(defaultMinDate).max(validate.ref('to')).required(),
  to: validate.date().max(endOfToday()).min(validate.ref('from')).required(),
});

export default function FilterByOwner(): JSX.Element {
  const { queryStampList, queryStampListRequest } = useStamp();
  const handleSubmit = useCallback(
    (values: FilterByOwnerForm) => {
      queryStampList({
        owner: values.owner?.id,
        from: formatISODate(values.from),
        to: formatISODate(values.to),
      });
    },
    [queryStampList]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterByOwnerForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <FilterForm onSubmit={onSubmit} request={queryStampListRequest} disabled={!form.isValid}>
      <PersonSelect
        label="Possessore"
        style={{ flexGrow: 1, maxWidth: '50ch' }}
        value={form.values.owner}
        onChange={(value) => setValue(value, 'owner')}
        onBlur={() => setTouched('owner')}
        error={form.errors.owner && form.touched.owner}
        helperText={form.touched.owner && form.errors.owner?.message}
      />

      <DatePicker
        label="Possesso dal"
        value={form.values.from}
        onChange={(from) => setValue(from, 'from')}
        onBlur={() => setTouched('from')}
        error={form.errors.from && form.touched.from}
        helperText={form.touched.from && form.errors.from?.message}
      />
      <DatePicker
        label="Possesso al"
        value={form.values.to}
        onChange={(to) => setValue(to, 'to')}
        onBlur={() => setTouched('to')}
        error={form.errors.to && form.touched.to}
        helperText={form.touched.to && form.errors.to?.message}
      />
    </FilterForm>
  );
}
