import React, { useState, useCallback } from 'react';
import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from 'mdi-material-ui/Plus';

import { Person } from '../../../../entities/Person';
import { useAuth } from '../../../../hooks/store/useAuth';
import PersonRosterTable from '../../../PersonRosterTable';
import PersonDutyAddDialog from './PersonDutyAddDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3 / 4),
    zIndex: 1,
  },
}));

type PersonStampsListProps = {
  person: Person | null;
};

export default function PersonRoster(props: PersonStampsListProps): JSX.Element {
  const classes = useStyles();
  const { person } = props;
  const { isAdmin } = useAuth();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleAddDialogClick = useCallback(() => setAddDialogOpen(true), []);
  const handleAddDialogClose = useCallback(() => setAddDialogOpen(false), []);

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAddDialogClick}
          className={classes.addButton}
        >
          Nuova mansione
        </Button>
      )}
      {!person?.roster || person.roster.length === 0 ? (
        <Alert severity="info" variant="outlined">
          Non sono stati trovate mansioni per questo utente
        </Alert>
      ) : (
        <PersonRosterTable person={person} tableOptions={{ elevation: 0, tableBodyMaxHeight: 'calc(100vh - 342px)' }} />
      )}
      {addDialogOpen && person && (
        <PersonDutyAddDialog open={addDialogOpen} handleClose={handleAddDialogClose} person={person} />
      )}
    </>
  );
}
