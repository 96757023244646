import React, { useCallback, useMemo } from 'react';
import Autocomplete from '@material-ui/core/Autocomplete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useForm } from '@top-solution/use-form';
import { NotificationsEmailList, RequirementNotificationsEmailList } from '../../../entities/NotificationsEmailList';
import { useNotificationsEmailList } from '../../../hooks/store/useNotificationsEmailList';
import { manageNotifications as section } from '../../../utils/appSections';
import Spacer from '../../../utils/Spacer';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import ManageNotificationsFormRow from './ManageNotificationsFormRow';

type ManageNotificationsFormData = {
  email: string[];
  byRequirement: RequirementNotificationsEmailList[];
};

const schema = validate.object().shape({
  email: validate.array(validate.string().email()),
  byRequirement: validate.array(
    validate.object().shape({
      id: validate.number(),
      name: validate.string(),
      defaultNotifications: validate.array(validate.string().email()),
    })
  ),
});

type ManageNotificationsFormProps = {
  initialValues: NotificationsEmailList;
};

export default function ManageNotificationsForm(props: ManageNotificationsFormProps): JSX.Element {
  const { setQualityNotificationsEmailList, setQualityNotificationsEmailListRequest } = useNotificationsEmailList();

  const initialValues = useMemo(
    () => ({ email: props.initialValues.global.email, byRequirement: props.initialValues.byRequirement }),
    [props.initialValues]
  );

  const handleSubmit = useCallback(
    (data: ManageNotificationsFormData) => {
      setQualityNotificationsEmailList({
        global: { email: data.email },
        byRequirement: data.byRequirement,
      });
    },
    [setQualityNotificationsEmailList]
  );

  const useFormReturn = useForm<ManageNotificationsFormData>({
    initialValues,
    schema,
    handleSubmit,
  });

  const { form, setTouched, setValue, onSubmit } = useFormReturn;

  return (
    <Card component="form" onSubmit={onSubmit}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {section.fullName}
        </Typography>
        <p>I seguenti destinatari riceveranno notifiche relative alle scadenze di ogni requisito:</p>
        <Autocomplete
          multiple
          freeSolo
          autoSelect
          disableClearable
          options={[]}
          renderTags={(value: string[][], getTagProps) =>
            value.map((option: unknown, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip variant="outlined" label={option as string} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Email manager"
              placeholder="Email"
              error={form.errors.email && form.touched.email}
              helperText={form.touched.email && form.errors.email?.message}
            />
          )}
          value={form.values.email}
          onChange={(_, value) => setValue(value, 'email')}
          onBlur={() => setTouched('email')}
        />

        <Typography variant="h6" component="h3" sx={{ marginTop: 6 }}>
          Notifiche requisiti specifici
        </Typography>
        <p>I seguenti destinatari riceveranno notifiche relative alle scadenze del singolo requisito:</p>

        {form.values.byRequirement.map((requirement, index) => {
          let error: string | undefined;
          const errorPath = form.errors.byRequirement?.params?.path as string;
          if (errorPath && errorPath.startsWith(`byRequirement[${index}].defaultNotifications`)) {
            error = form.errors.byRequirement?.nested?.[errorPath]?.message;
          }
          return (
            <ManageNotificationsFormRow
              key={requirement.id}
              value={requirement}
              onChange={(value) =>
                setValue(
                  form.values.byRequirement.map((req) => (req.id === value.id ? value : req)),
                  'byRequirement'
                )
              }
              error={error}
            />
          );
        })}

        {setQualityNotificationsEmailListRequest.error && (
          <ErrorAlert error={setQualityNotificationsEmailListRequest.error} sx={{ marginTop: 1 }} />
        )}
      </CardContent>
      <CardActions>
        <Spacer />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          pending={setQualityNotificationsEmailListRequest.inProgress}
        >
          Salva
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
