import React from 'react';
import clsx from 'clsx';
import { differenceInDays, startOfToday } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import EyeIcon from 'mdi-material-ui/Eye';
import GlassesIcon from 'mdi-material-ui/Glasses';

import { PersonRequirement } from '../entities/Requirement';
import { formatDateShort } from '../utils/date';

const useStyles = makeStyles((theme) => ({
  expiration: {
    whiteSpace: 'nowrap',
  },
  expired: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,

    '& .MuiChip-avatar': {
      color: 'inherit',
    },
  },
  expiring: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,

    '& .MuiChip-avatar': {
      color: 'inherit',
    },
  },
}));

export interface RequirementLabelProps {
  requirement: PersonRequirement;
  showDate?: boolean;
  hideIcon?: boolean;
  className?: string;
}

export default function RequirementLabel(props: RequirementLabelProps): JSX.Element {
  const classes = useStyles();
  const { requirement, showDate, hideIcon, className } = props;
  const daysToExpire =
    typeof requirement.expireAt === 'string' ? 0 : differenceInDays(requirement.expireAt as Date, startOfToday());
  let color = null;
  if (daysToExpire <= 0) {
    color = classes.expired;
  } else if (daysToExpire <= requirement.notificationsDays) {
    color = classes.expiring;
  }

  const variant = daysToExpire <= requirement.notificationsDays ? 'filled' : 'outlined';

  let label;
  if (showDate) {
    label = formatDateShort(requirement.expireAt);
  } else {
    label = requirement.opticalRequirement?.name || requirement.name;
    if (requirement.code) {
      label = `${label} ${requirement.code}`;
    }
  }

  const avatar =
    !hideIcon && requirement.opticalRequirement ? requirement.needsGlasses ? <GlassesIcon /> : <EyeIcon /> : undefined;

  const chip = <Chip variant={variant} avatar={avatar} label={label} className={clsx(color, className)} />;

  if (showDate) {
    return chip;
  }

  return (
    <Tooltip title={formatDateShort(requirement.expireAt)} placement="top" arrow>
      {chip}
    </Tooltip>
  );
}
