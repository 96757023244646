import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CheckIcon from 'mdi-material-ui/Check';

import './VisitPrescription.css';
import { usePerson } from '../../../hooks/store/usePerson';
import { LogoFull } from '../../../utils/Logo';
import RequestResult from '../../RequestResult';

type RouteParams = {
  id: string;
};

export default function PersonDetails(): JSX.Element {
  const { id } = useParams<RouteParams>();

  const {
    personDetails,
    readPersonDetails,
    readPersonDetailsRequest,
    personVisitPrescriptionList,
    readPersonVisitPrescriptionList,
    readPersonVisitPrescriptionListRequest,
  } = usePerson();

  useEffect(() => {
    if (
      (!personDetails || personDetails.id !== id) &&
      !readPersonDetailsRequest.error &&
      !readPersonDetailsRequest.inProgress
    ) {
      readPersonDetails(id);
    }
  }, [id, readPersonDetails, readPersonDetailsRequest.error, readPersonDetailsRequest.inProgress, personDetails]);

  useEffect(() => {
    if (
      readPersonVisitPrescriptionListRequest.personId !== id &&
      !readPersonVisitPrescriptionListRequest.error &&
      !readPersonVisitPrescriptionListRequest.inProgress
    ) {
      readPersonVisitPrescriptionList(id);
    }
  }, [id, readPersonVisitPrescriptionList, readPersonVisitPrescriptionListRequest]);

  return (
    <div>
      <Helmet>
        <title>Dichiarazione di rispondenza visiva | Digital Roster</title>
      </Helmet>
      <RequestResult
        request={{
          inProgress: readPersonDetailsRequest.inProgress || readPersonVisitPrescriptionListRequest.inProgress,
          error: readPersonDetailsRequest.error || readPersonVisitPrescriptionListRequest.error,
        }}
        submitted={Boolean(readPersonDetailsRequest.id && readPersonVisitPrescriptionListRequest.personId)}
      >
        {personDetails && (
          <>
            <div className="no-print visit-prescription-actions">
              <Button color="secondary" variant="contained" component={Link} to={`/person/${id}/requirements`}>
                Indietro
              </Button>
              <Button color="primary" variant="contained" onClick={window.print}>
                Stampa modulo
              </Button>
            </div>
            <div className="visit-prescription">
              <header>
                <LogoFull className="logo" />
                <div className="title translated">
                  <div className="ita">Dichiarazione di rispondenza visiva</div>
                  <div className="eng">Declaration of visual acuity</div>
                </div>
              </header>
              <section className="person-data">
                <div className="info">
                  <div className="translated">
                    <div className="ita">Nome</div>
                    <div className="eng">Name</div>
                  </div>
                  <div className="value">
                    {personDetails.lastname} {personDetails.name}
                  </div>
                </div>
                <div className="info">
                  <div className="translated">
                    <div className="ita">Matricola</div>
                    <div className="eng">Identification NO.</div>
                  </div>
                  <div className="value">{personDetails.id}</div>
                </div>
              </section>
              <section>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2} className="description">
                        <div className="translated">
                          <div className="ita">FUNZIONE: Personale addetto a</div>
                          <div className="eng">FUNCTION: Personnel assigned to</div>
                        </div>
                      </th>
                      <th colSpan={3}>
                        <div className="translated">
                          <div className="ita">REQUISITI MINIMI </div>
                          <div className="eng">MINIMUM REQUIREMENTS </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th className="near">
                        <div className="translated">
                          <div className="ita">Ravvicinata</div>
                          <div className="eng">Near</div>
                        </div>
                      </th>
                      <th className="far">
                        <div className="translated">
                          <div className="ita">A distanza </div>
                          <div className="eng">Far</div>
                        </div>
                      </th>
                      <th className="colors">
                        <div className="translated">
                          <div className="ita">Percezione colori</div>
                          <div className="eng">Colours perception</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {personVisitPrescriptionList.map((visit) => (
                      <tr key={visit.id}>
                        <th>
                          <div className="translated">
                            <div className="ita">{visit.visualNameIt}</div>
                            <div className="eng">{visit.visualNameEng}</div>
                          </div>
                          <div className="tick-wrapper">
                            <div className="tick-box">{visit.haveTo && <CheckIcon />}</div>
                          </div>
                        </th>
                        {visit.notesIt ? (
                          <td colSpan={3}>
                            <div className="translated">
                              <div className="ita">{visit.notesIt}</div>
                              <div className="eng">{visit.notesEng}</div>
                            </div>
                          </td>
                        ) : (
                          <>
                            <td>
                              <div className="translated">
                                <div className="ita">{visit.nearIt}</div>
                                <div className="eng">{visit.nearEng}</div>
                              </div>
                            </td>
                            <td>
                              <div className="translated">
                                <div className="ita">{visit.farIt}</div>
                                <div className="eng">{visit.farEng}</div>
                              </div>
                            </td>
                            <td>
                              <div className="translated">
                                <div className="ita">{visit.coloursPerceptionIt}</div>
                                <div className="eng">{visit.coloursPerceptionEng}</div>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
              <section className="needs-glasses">
                <div className="translated">
                  <div className="ita">Prescrizioni: uso delle lenti correttive personali </div>
                  <div className="eng">Prescriptions: use of corrective personal lenses </div>
                </div>
                <div className="tick-wrapper">
                  <div className="tick-box" />
                  <div className="translated">
                    <div className="ita">Sì</div>
                    <div className="eng">Yes</div>
                  </div>
                </div>
                <div className="tick-wrapper">
                  <div className="tick-box" />
                  <div className="translated">
                    <div className="ita">No</div>
                    <div className="eng">No</div>
                  </div>
                </div>
              </section>
              <section className="statement">
                <div className="translated">
                  <div className="ita">
                    Io sottoscritto/a, in funzione di medico, dichiaro di aver effettuato la verifica di acuità visiva
                    all’esaminato, con il seguente esito:
                  </div>
                  <div className="eng">
                    I certify that I, as medical personnel, have administered the visual acuity exam to the applicant,
                    with the following results:
                  </div>
                </div>
                <div className="tick-wrapper">
                  <div className="tick-box" />
                  <div className="translated">
                    <div className="ita">
                      l&apos;esaminato ha soddisfatto i requisiti visivi in accordo con l’istruzione MT-IS-08.
                    </div>
                    <div className="eng">
                      the applicant meets the visual requirements indicated in instruction MT-IS-08.
                    </div>
                  </div>
                </div>
                <div className="tick-wrapper">
                  <div className="tick-box" />
                  <div className="translated">
                    <div className="ita">l&apos;esaminato non ha soddisfatto alcuni requisiti:</div>
                    <hr />
                    <div className="eng">the applicant does not meet some requirements</div>
                  </div>
                </div>
              </section>
              <section className="sign-area">
                <div className="translated">
                  <div className="ita">Data</div>
                  <div className="eng">Date</div>
                </div>
                <div className="translated">
                  <div className="ita">(Timbro, titolo e firma del medico)</div>
                  <div className="eng">(Stamp, qualification and signature of medical personnel)</div>
                </div>
              </section>
              <div className="model-no">Mod. MT-IS-08/01 Rev. 8</div>
            </div>
          </>
        )}
      </RequestResult>
    </div>
  );
}
