import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBox } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { AppSettings } from '../../../entities/CurrentUser';
import { Plant } from '../../../entities/Plant';
import { Program } from '../../../entities/Program';
import { Qualification } from '../../../entities/Qualification';
import { Skill } from '../../../entities/Skill';
import { useAppSettings } from '../../../hooks/store/useAppSettings';
import { usePerson } from '../../../hooks/store/usePerson';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PlantSelect from '../../inputs/PlantSelect';
import ProgramSelect from '../../inputs/ProgramSelect';
import QualificationSelect from '../../inputs/QualificationSelect';
import SkillSelect from '../../inputs/SkillSelect';

type FilterByQualificationForm = {
  qualification: null | Qualification;
  plant: null | Plant;
  program: null | Program;
  skill: null | Skill;
  excludeEnded: boolean;
  excludeInTraining: boolean;
};

const useStyles = makeStyles({
  qualificationSelect: {
    flexGrow: 1,
    maxWidth: '150ch',
  },
  plantSelect: {
    flexGrow: 1,
    maxWidth: '14ch',
  },
  programSelect: {
    flexGrow: 1,
    maxWidth: '18ch',
  },
  skillSelect: {
    flexGrow: 1,
    maxWidth: '18ch',
  },
  excludeCheckbox: {
    minWidth: '20ch',
  },
});

const schema = validate.object().shape({
  qualification: validate.mixed().required(),
  plant: validate.mixed().nullable(),
  program: validate.mixed().nullable(),
  skill: validate.mixed().nullable(),
});

type FilterByQualificationProps = {
  appSettings: AppSettings;
};

export default function FilterByQualification(props: FilterByQualificationProps): JSX.Element {
  const { appSettings } = props;
  const classes = useStyles();
  const { queryPerson, queryPersonRequest } = usePerson();
  const { setAppSettings } = useAppSettings();

  const initialValues = useMemo(
    () => ({
      qualification: null as null | Qualification,
      plant: null as null | Plant,
      program: null as null | Program,
      skill: null as null | Skill,
      excludeEnded: appSettings.excludeEnded,
      excludeInTraining: appSettings.excludeInTraining,
    }),
    [appSettings.excludeEnded, appSettings.excludeInTraining]
  );

  const handleSubmit = useCallback(
    (values: FilterByQualificationForm) => {
      queryPerson({
        qualification: values.qualification?.id,
        plant: values.plant?.id,
        program: values.program?.id,
        skill: values.skill?.id,
        excludeEnded: values.excludeEnded,
        excludeInTraining: values.excludeInTraining,
      });
    },
    [queryPerson]
  );

  const { form, setTouched, setValue, onSubmit } = useForm<FilterByQualificationForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <FilterForm onSubmit={onSubmit} request={queryPersonRequest} disabled={!form.isValid}>
      <QualificationSelect
        className={classes.qualificationSelect}
        value={form.values.qualification}
        onChange={(value) => setValue(value, 'qualification')}
        onBlur={() => setTouched('qualification')}
        error={form.errors.qualification && form.touched.qualification}
        helperText={form.touched.qualification && form.errors.qualification?.message}
        required
      />
      <SkillSelect
        className={classes.skillSelect}
        value={form.values.skill}
        onChange={(value) => setValue(value, 'skill')}
        onBlur={() => setTouched('skill')}
      />
      <ProgramSelect
        className={classes.programSelect}
        value={form.values.program}
        onChange={(value) => setValue(value, 'program')}
        onBlur={() => setTouched('program')}
      />
      <PlantSelect
        className={classes.plantSelect}
        value={form.values.plant}
        onChange={(value) => setValue(value, 'plant')}
        onBlur={() => setTouched('plant')}
      />
      <CheckBox
        value={form.values.excludeEnded}
        onChange={(value) => {
          setValue(value, 'excludeEnded');
          setAppSettings({ ...appSettings, excludeEnded: value });
        }}
        label="Escludi congedati"
        className={classes.excludeCheckbox}
      />
      <CheckBox
        value={form.values.excludeInTraining}
        onChange={(value) => {
          setValue(value, 'excludeInTraining');
          setAppSettings({ ...appSettings, excludeInTraining: value });
        }}
        label="Escludi in training"
        className={classes.excludeCheckbox}
      />
    </FilterForm>
  );
}
