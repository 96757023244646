import { StampSuspensionReason } from '../entities/Stamp';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class StampSuspensionRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/stampSuspensionReasons', token);
  }
  async getList(): Promise<StampSuspensionReason[]> {
    const { data } = await this.axios.get<StampSuspensionReason[]>('/');
    return data;
  }
}
