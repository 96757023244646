import { useMemo } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { styled } from '@material-ui/core/styles';

import { useAppSettings, useAppSettingsResource } from '../hooks/store/useAppSettings';
import { formatDateShort, formatISODate } from './date';

export function useTableOptions(
  overrideTableOptions?: MUIDataTableOptions,
  csvFileName?: string
): { tableOptions: MUIDataTableOptions } {
  const { setAppSettings } = useAppSettings();
  const [appSettings] = useAppSettingsResource();
  const tableOptions = useMemo(
    () =>
      ({
        elevation: 1,
        rowHover: false,
        print: false,
        filter: false,
        selectableRows: 'none',
        downloadOptions: {
          separator: ';',
          filename: csvFileName || 'Digital Roster - export.csv',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
          return '\uFEFF' + buildHead(columns) + buildBody(data);
        },
        setTableProps: () => ({
          size: 'small',
        }),
        rowsPerPageOptions: [5, 10, 15, 30, 50, 100],
        rowsPerPage: appSettings.rowsPerPage,
        onChangeRowsPerPage: (rowsPerPage: number) => setAppSettings({ ...appSettings, rowsPerPage }),
        textLabels: {
          body: {
            noMatch: 'Nessun risultato corrispondente ai filtri selezionati',
            toolTip: 'Ordina',
            columnHeaderTooltip: (column) => `Ordina per "${column.label}"`,
          },
          pagination: {
            next: 'Pagina successiva',
            previous: 'Pagina precendete',
            rowsPerPage: 'Righe per pagina:',
            displayRows: 'di',
          },
          toolbar: {
            search: 'Cerca',
            downloadCsv: 'Download CSV',
            print: 'Stampa',
            viewColumns: 'Mostra/nascondi colonne',
            filterTable: 'Filtri',
          },
          filter: {
            all: 'Tutto',
            title: 'Filtri',
            reset: 'Reset',
          },
          viewColumns: {
            title: 'Mostra colonne',
            titleAria: 'Mostra/nascondi colonne tabella',
          },
          selectedRows: {
            text: 'righe selezionate',
            delete: 'Elimina',
            deleteAria: 'Elimina righe selezionate',
          },
        },
        ...overrideTableOptions,
      } as MUIDataTableOptions),
    [csvFileName, appSettings, overrideTableOptions, setAppSettings]
  );

  return { tableOptions };
}

export const RowActionsWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(-1) + 2,
  marginTop: theme.spacing(-1) + 2,
}));

export function formatDateForDisplay(date: Date | string | undefined | null): string {
  return (date && formatDateShort(date)) || '–';
}

export function formatDateForSort(date: Date | string | undefined | null): string {
  return (date && formatISODate(date)) || '';
}
