import { format, isValid } from 'date-fns';
import * as yup from 'yup';

import { DATE_SHORT } from './date';

yup.setLocale({
  mixed: {
    required: 'Campo obbligatorio',
    notType: function notType({ type }: { type: string }) {
      switch (type) {
        case 'number':
          return 'Numero non valido';
        case 'date':
          return 'Data non valida';
        case 'object':
          return 'Campo obbligatorio';
        default:
          return `Type error (${type})`;
      }
    },
  },
  string: {
    email: 'Email non valida',
  },
  number: {
    min: ({ min }) => `Minimo: ${min}`,
    max: ({ max }) => `Massimo: ${max}`,
  },
  date: {
    min: ({ min }) => (isValid(min) ? `Minimo: ${format(new Date(min), DATE_SHORT)}` : undefined),
    max: ({ max }) => (isValid(max) ? `Massimo: ${format(new Date(max), DATE_SHORT)}` : undefined),
  },
  array: {
    min: ({ min }) => (isValid(min) ? `Inserire almeno ${min} element${min === 1 ? 'o' : 'i'}` : undefined),
    max: ({ max }) => (isValid(max) ? `Inserire massimo ${max} element${max === 1 ? 'o' : 'i'}` : undefined),
  },
});

export default yup;
