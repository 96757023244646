import React, { createContext, useCallback, useState } from 'react';
import { RequestError } from '@top-solution/utils';
import { Person } from '../../../../entities/Person';
import { usePerson } from '../../../../hooks/store/usePerson';
import { EditManagerFormData } from './EditManagersForm';

function noop(): void {
  throw new Error('Function not implemented.');
}
type UpdateStatusByPersonMap = Record<
  Person['id'],
  { inProgress: boolean; success: boolean; error: RequestError | null }
>;

type EditManagersForm = {
  handleSubmit: (values: EditManagerFormData) => void;
  updateStatus: {
    inProgress: boolean;
    byPerson: UpdateStatusByPersonMap;
  };
};

export const EditManagersFormContext = createContext<EditManagersForm>({
  handleSubmit: noop,
  updateStatus: {
    inProgress: false,
    byPerson: {},
  },
});

export function useEditManagersForm(): EditManagersForm {
  const queryPersonRequest = usePerson();
  const [updateStatusByPerson, setUpdateStatusByPerson] = useState<UpdateStatusByPersonMap>({});
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const handleSubmit = useCallback(
    async (values: EditManagerFormData) => {
      setUpdateInProgress(true);
      let statusByPersonMap = values.people.reduce<UpdateStatusByPersonMap>((map, person) => {
        map[person.id] = { inProgress: true, error: null, success: false };
        return map;
      }, {});
      setUpdateStatusByPerson(statusByPersonMap);
      for (const person of values.people) {
        try {
          await queryPersonRequest.updatePerson({
            ...person,
            managerList: values.managerList,
          } as Person);
          statusByPersonMap = {
            ...statusByPersonMap,
            [person.id]: { inProgress: false, error: null, success: true },
          };
        } catch (error) {
          statusByPersonMap = {
            ...statusByPersonMap,
            [person.id]: { inProgress: false, error: error as RequestError, success: false },
          };
        }
        setUpdateStatusByPerson(statusByPersonMap);
      }
      setUpdateInProgress(false);
    },
    [queryPersonRequest]
  );

  return {
    handleSubmit,
    updateStatus: {
      inProgress: updateInProgress,
      byPerson: updateStatusByPerson,
    },
  };
}

interface EditManagersFormProviderProps {
  children: React.ReactNode;
}

export function EditManagersFormProvider(props: EditManagersFormProviderProps): JSX.Element {
  const { children } = props;

  const value = useEditManagersForm();

  return <EditManagersFormContext.Provider value={value}>{children}</EditManagersFormContext.Provider>;
}
