import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/core/Alert';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useForm } from '@top-solution/use-form';
import { Person } from '../../../../entities/Person';
import { usePerson } from '../../../../hooks/store/usePerson';
import { editManagerStaffSection as section, staffSection } from '../../../../utils/appSections';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import PersonSelect from '../../../inputs/PersonSelect';
import FormRow from '../../../layout/FormRow';
import RequestResult from '../../../RequestResult';
import { EditManagersFormContext } from './EditManagersFormContext';
import EditManagersRow from './EditManagersRow';

const useStyles = makeStyles((theme) => ({
  peopleListWrapper: {
    margin: 0,
    padding: 0,
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'auto',
  },
  peopleRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.25, 1),
    margin: theme.spacing(0, -1),
  },
}));

export type EditManagerFormData = {
  managerList: string[];
  people: Person[];
};

const schema = validate.object().shape({
  managerList: validate.array(validate.string().email()).min(1),
  people: validate
    .array(
      validate.object().shape({
        id: validate.string(),
      })
    )
    .min(1),
});

const initialValues = {
  managerList: [],
  people: [],
};

export default function EditManagersForm(): JSX.Element {
  const classes = useStyles();

  const [personToAdd, setPersonToAdd] = useState<Person | null>(null);

  const { readPersonListRequest } = usePerson();

  const { handleSubmit } = useContext(EditManagersFormContext);

  const { form, setTouched, setValue, onSubmit } = useForm<EditManagerFormData>({
    initialValues,
    schema,
    handleSubmit,
  });

  return (
    <Card component="form" onSubmit={onSubmit}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {section.fullName}
        </Typography>
        <RequestResult request={readPersonListRequest} submitted={true}>
          <FormRow>
            <MuiAutocomplete
              multiple
              freeSolo
              autoSelect
              disableClearable
              options={[]}
              renderTags={(value: string[][], getTagProps) =>
                value.map((option: unknown, index: number) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip variant="outlined" label={option as string} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label="Email manager"
                  placeholder="Email"
                  error={form.errors.managerList && (form.touched.managerList || form.values.managerList.length > 0)}
                  helperText={
                    (form.touched.managerList || form.values.managerList.length > 0) && form.errors.managerList?.message
                  }
                />
              )}
              value={form.values.managerList}
              onChange={(_, value) => setValue(value, 'managerList')}
              onBlur={() => setTouched('managerList')}
            />
          </FormRow>
          <FormRow style={{ alignItems: 'center' }}>
            <PersonSelect
              label="Dipendente"
              value={personToAdd}
              onChange={setPersonToAdd}
              style={{ flexGrow: 1, maxWidth: '40ch' }}
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ minWidth: '12ch', flex: '0 1 0%' }}
              onClick={() => {
                setTouched('people');
                if (personToAdd) {
                  if (!form.values.people.find((p) => p.id === personToAdd.id)) {
                    setValue([...form.values.people, personToAdd], 'people');
                  }
                  setPersonToAdd(null);
                }
              }}
            >
              Aggiungi
            </Button>
          </FormRow>
          {form.values.people.length > 0 && (
            <ul className={classes.peopleListWrapper}>
              {form.values.people.map((person) => (
                <EditManagersRow
                  key={person.id}
                  person={person}
                  onPersonRemove={() =>
                    setValue(
                      form.values.people.filter(({ id }) => id !== person.id),
                      'people'
                    )
                  }
                />
              ))}
            </ul>
          )}
        </RequestResult>
        {form.errors.people && form.touched.people && <Alert severity="error">{form.errors.people.message}</Alert>}
      </CardContent>
      <CardActions>
        <Spacer />
        <Button component={Link} to={staffSection.path} sx={{ marginRight: 1 }}>
          Chiudi
        </Button>
        <LoadingButton type="submit" variant="contained" color="primary" pending={false}>
          Salva
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
