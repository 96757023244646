import React, { useCallback, useMemo } from 'react';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressButton, TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../../entities/Person';
import { usePerson } from '../../../../hooks/store/usePerson';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import FormRow from '../../../layout/FormRow';

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: theme.spacing(2),
  },
}));

type PersonChangeIDForm = {
  id: string;
  confirm: string;
};

type PersonChangeIDDialogProps = {
  open: boolean;
  handleClose: (id?: string) => void;
  person: Person;
};

const confirmText = 'cambio matricola';

const schema = validate.object().shape({
  id: validate.string().min(2).required(),
  confirm: validate.string().equals([confirmText]).required(),
});

export default function PersonChangeIDDialog(props: PersonChangeIDDialogProps): JSX.Element {
  const classes = useStyles();
  const { open, handleClose, person } = props;
  const { updatePersonID, updatePersonIDRequest, updatePersonIDClear } = usePerson();

  const initialValues = useMemo(
    () => ({
      id: person.id,
      confirm: '',
    }),
    [person.id]
  );

  const handleSubmit = useCallback(
    async (values: PersonChangeIDForm) => {
      const updated = await updatePersonID(person, values.id);
      if (updated) {
        handleClose(updated?.id);
        updatePersonIDClear();
      }
    },
    [handleClose, person, updatePersonID, updatePersonIDClear]
  );

  const { form, setValue, setTouched, onSubmit } = useForm<PersonChangeIDForm>({ initialValues, schema, handleSubmit });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose();
        updatePersonIDClear();
      }
    },
    [handleClose, updatePersonIDClear]
  );

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Modifica matricola</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <TextField
            label="Matricola"
            value={form.values.id}
            onChange={(value) => setValue(value, 'id')}
            onBlur={() => setTouched('id')}
            error={form.errors.id && form.touched.id}
            disableClearable
            fullWidth
          />
        </FormRow>
        <Alert severity={'error'}>
          <AlertTitle>Attenzione</AlertTitle>
          Per continuare digita “{confirmText}”:
          <TextField
            value={form.values.confirm}
            onChange={(value) => setValue(value, 'confirm')}
            onBlur={() => setTouched('confirm')}
            error={form.errors.confirm && form.touched.confirm}
            disableClearable
            fullWidth
          />
        </Alert>
        <ErrorAlert error={updatePersonIDRequest.error} className={classes.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={updatePersonIDRequest.inProgress}
        >
          Procedi
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
