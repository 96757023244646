import React from 'react';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

type TabbedComponent = {
  children: React.ReactNode;
  title: string;
  slug: string;
};

type TabbedComponentsProps = {
  components: TabbedComponent[];
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
}));

export default function TabbedComponents(props: TabbedComponentsProps): JSX.Element {
  const classes = useStyles();

  const { components, className } = props;

  if (!components.find(({ slug }) => slug === window.location.pathname)) {
    return <Redirect to={components[0].slug} />;
  }

  return (
    <Paper className={clsx(classes.wrapper, className)}>
      <Tabs value={window.location.pathname} indicatorColor="primary" textColor="primary" className={classes.tabs}>
        {components.map(({ title, slug }, index) => (
          <Tab label={title} key={index} component={Link} to={slug} value={slug} />
        ))}
      </Tabs>
      <Switch>
        {components.map(({ children, slug }, index) => (
          <Route
            path={slug}
            key={index}
            render={() => <div className={clsx(classes.tabPanel, 'tab-panel', `tab-panel_${index}`)}>{children}</div>}
          />
        ))}
      </Switch>
    </Paper>
  );
}
