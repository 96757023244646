import React from 'react';
import { Select, SelectProps } from '@top-solution/mui-inputs';

import { AbstractRequirement } from '../../entities/Requirement';
import { useRequirementListResource } from '../../hooks/store/useRequirement';

type RequirementSelectProps = Omit<SelectProps<string, AbstractRequirement>, 'options' | 'request' | 'renderOption'>;

export default function RequirementSelect(props: RequirementSelectProps): JSX.Element {
  const { label, ...otherProps } = props;
  const [options, request] = useRequirementListResource();

  return (
    <Select
      {...otherProps}
      label={label || 'Requisito'}
      options={options}
      request={request}
      renderOption={({ name }) => name}
    />
  );
}
