import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isPast, differenceInDays, endOfToday } from 'date-fns';
import Alert from '@material-ui/core/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { usePrevious } from '@top-solution/utils';

import { Person } from '../../../../entities/Person';
import { Qualification } from '../../../../entities/Qualification';
import { PersonRequirement, QualificationRequirement } from '../../../../entities/Requirement';
import { formatDateShort } from '../../../../utils/date';
import Spacer from '../../../../utils/Spacer';

const useStyles = makeStyles((theme) => ({
  reqAlert: {
    marginBottom: theme.spacing(1),

    '& .MuiAlert-message': {
      flexGrow: 1,
      display: 'flex',
    },
  },

  selectRequirement: {
    marginBottom: theme.spacing(3),

    '& .MuiSelect-select': {
      display: 'flex',
    },
  },
}));

type PersonDutyRequirementsProps = {
  person: Person;
  qualification: Qualification;
  isProduction: boolean;
  isSafetyPart: boolean;
  selectedRequirements: PersonRequirement[];
  onChange: (selectedRequirements: PersonRequirement[], isFullfilled: boolean) => void;
};

function getRequirementLabel(req: PersonRequirement | QualificationRequirement) {
  if (req.opticalRequirement) {
    return `${req.name} (${req.opticalRequirement.name})`;
  }
  return req.name;
}

type RequirementStatus = 'missing' | 'expired' | 'expiring' | 'ok' | 'multiple';

type RequirementsFullfillment = {
  missing: Record<string, QualificationRequirement>;
  expired: Record<string, PersonRequirement>;
  expiring: Record<string, PersonRequirement>;
  ok: Record<string, PersonRequirement>;
  multiple: Record<string, PersonRequirement[]>;
};

const separator = '~~';

function getRequirementStatus(req: PersonRequirement): RequirementStatus {
  const daysToExpire =
    req?.expireAt && typeof req.expireAt !== 'string' ? differenceInDays(req.expireAt as Date, endOfToday()) : 0;
  if (daysToExpire < 1) {
    return 'expired';
  }
  if (daysToExpire < req.notificationsDays) {
    return 'expiring';
  }
  return 'ok';
}

export default function PersonDutyRequirements(props: PersonDutyRequirementsProps): JSX.Element {
  const classes = useStyles();
  const { person, qualification, isProduction, isSafetyPart, selectedRequirements, onChange } = props;
  const [selectedRequirementsById, setSelectedRequirementsById] = useState(
    null as null | Record<string, PersonRequirement>
  );

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const [id, code] = event.target.value.split(separator);
      const selectedReq = person.requirements?.find((req) => req.id === Number(id) && req.code === code);
      if (selectedReq) {
        setSelectedRequirementsById({
          ...selectedRequirementsById,
          [id]: selectedReq,
        });
      }
    },
    [person.requirements, selectedRequirementsById]
  );

  const requirementsToBeMet = useMemo(
    () =>
      qualification.requirements
        .filter(
          (qualificationRequirement) =>
            (!qualificationRequirement.exceptProduction || !isProduction) &&
            (!qualificationRequirement.needSafetyPart || isSafetyPart)
        )
        .reduce(
          (requirements, requirement) => ({ ...requirements, [String(requirement.id)]: requirement }),
          {} as Record<string, QualificationRequirement>
        ),
    [isProduction, isSafetyPart, qualification.requirements]
  );

  const requirementsFullfillment = useMemo(
    () =>
      Object.values(requirementsToBeMet).reduce(
        (fullfillment, qualificationRequirement) => {
          const availableRequirements =
            person.requirements?.filter(
              (personRequirement) =>
                qualificationRequirement.id === personRequirement.id &&
                (!qualificationRequirement.opticalRequirement ||
                  qualificationRequirement.opticalRequirement.id === personRequirement.opticalRequirement?.id)
            ) || [];
          let status: RequirementStatus;
          if (availableRequirements.length === 1) {
            status = getRequirementStatus(availableRequirements[0]);
          } else if (availableRequirements.length > 1) {
            status = 'multiple';
          } else {
            status = 'missing';
          }
          return {
            ...fullfillment,
            [status]: {
              ...fullfillment[status],
              [String(qualificationRequirement.id)]:
                status === 'missing'
                  ? qualificationRequirement
                  : status === 'multiple'
                  ? availableRequirements
                  : availableRequirements[0],
            },
          };
        },
        {
          missing: {},
          expired: {},
          expiring: {},
          ok: {},
          multiple: {},
        } as RequirementsFullfillment
      ),
    [person.requirements, requirementsToBeMet]
  );
  const prevSelectedRequirementsById = usePrevious(selectedRequirementsById);
  const prevRequirementsToBeMet = usePrevious(requirementsToBeMet);

  useEffect(() => {
    if (!selectedRequirementsById || requirementsToBeMet !== prevRequirementsToBeMet) {
      const byId = Object.keys(requirementsToBeMet).reduce((requirements, id) => {
        const req = requirementsFullfillment.multiple[id]
          ? selectedRequirements.find((req) => String(req.id) === id)
          : requirementsFullfillment.ok[id] || requirementsFullfillment.expiring[id];
        if (req) {
          return {
            ...requirements,
            [id]: req,
          };
        }
        return requirements;
      }, {} as Record<string, PersonRequirement>);
      setSelectedRequirementsById(byId);
    } else if (selectedRequirementsById && selectedRequirementsById !== prevSelectedRequirementsById) {
      onChange(
        Object.values(selectedRequirementsById),
        !Object.keys(requirementsToBeMet).find((id) => !selectedRequirementsById[id])
      );
    }
  }, [
    onChange,
    prevRequirementsToBeMet,
    prevSelectedRequirementsById,
    requirementsFullfillment,
    requirementsFullfillment.expiring,
    requirementsFullfillment.multiple,
    requirementsFullfillment.ok,
    requirementsToBeMet,
    selectedRequirements,
    selectedRequirementsById,
  ]);

  return (
    <>
      {Object.keys(requirementsFullfillment.multiple).map((qualificationRequirementId) => (
        <TextField
          key={qualificationRequirementId}
          label={getRequirementLabel(requirementsToBeMet[qualificationRequirementId])}
          className={classes.selectRequirement}
          value={
            !selectedRequirementsById?.[qualificationRequirementId]
              ? ''
              : `${selectedRequirementsById[qualificationRequirementId].id}${separator}${selectedRequirementsById[qualificationRequirementId].code}`
          }
          onChange={handleSelect}
          select
          fullWidth
          required
        >
          {requirementsFullfillment.multiple[qualificationRequirementId].map((personRequirement) => (
            <MenuItem
              key={personRequirement.code}
              value={`${personRequirement.id}${separator}${personRequirement.code}`}
              disabled={isPast(personRequirement.expireAt as Date)}
            >
              <span>
                {personRequirement.name}
                {personRequirement.code ? ` ${personRequirement.code}` : null}
              </span>
              <Spacer />
              <span>
                {isPast(personRequirement.expireAt as Date) ? ' Scaduto:' : ' Scadenza:'}{' '}
                {formatDateShort(personRequirement.expireAt)}
              </span>
            </MenuItem>
          ))}
        </TextField>
      ))}
      {Object.keys(requirementsFullfillment.missing).map((qualificationRequirementId) => (
        <Alert severity="error" key={qualificationRequirementId} className={classes.reqAlert}>
          <span>{getRequirementLabel(requirementsFullfillment.missing[qualificationRequirementId])}</span>
          <Spacer />
          <span>Requisito mancante</span>
        </Alert>
      ))}
      {Object.keys(requirementsFullfillment.expired).map((qualificationRequirementId) => (
        <Alert severity="error" key={qualificationRequirementId} className={classes.reqAlert}>
          <span>{getRequirementLabel(requirementsFullfillment.expired[qualificationRequirementId])}</span>
          <Spacer />
          <span>Scaduto: {formatDateShort(requirementsFullfillment.expired[qualificationRequirementId].expireAt)}</span>
        </Alert>
      ))}
      {Object.keys(requirementsFullfillment.expiring).map((qualificationRequirementId) => (
        <Alert severity="warning" key={qualificationRequirementId} className={classes.reqAlert}>
          <span>{getRequirementLabel(requirementsFullfillment.expiring[qualificationRequirementId])}</span>
          <Spacer />
          <span>
            Scadenza: {formatDateShort(requirementsFullfillment.expiring[qualificationRequirementId].expireAt)}
          </span>
        </Alert>
      ))}
      {Object.keys(requirementsFullfillment.ok).map((qualificationRequirementId) => (
        <Alert severity="success" key={qualificationRequirementId} className={classes.reqAlert}>
          <span>{getRequirementLabel(requirementsFullfillment.ok[qualificationRequirementId])}</span>
          <Spacer />
          <span>Scadenza: {formatDateShort(requirementsFullfillment.ok[qualificationRequirementId].expireAt)}</span>
        </Alert>
      ))}
    </>
  );
}
