import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

import ErrorAlert from './ErrorAlert';

const style = (theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(2)}px auto`,
      width: '80%',
      maxWidth: theme.breakpoints.width('md'),
    },
  });

interface IProps {
  classes: Record<'root', string>;
  children: React.ReactNode;
}

interface IErrorInfo {
  componentStack: string;
}

interface IState {
  hasError: boolean;
  error: Error | null;
  info: IErrorInfo | null;
}

class GlobalErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error: Error, info: IErrorInfo) {
    this.setState({ error, info, hasError: true });
    if (process.env.REACT_APP_DATADOG_TOKEN) {
      datadogLogs.logger.error(error.message, {
        name: error.name,
        message: error.message,
        stack: info.componentStack,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorAlert className={this.props.classes.root} error={this.state.error} showDetails />;
    }
    return this.props.children;
  }
}

export default withStyles(style)(GlobalErrorBoundary);
