import { RequestError } from '@top-solution/utils';

import { ExpirationBatchUpdateParams, QueryExpirationParams } from '../../api/ExpirationRepository';
import { PersonRequirementExpiration } from '../../entities/Requirement';

export const QUERY_EXPIRATION_REQUEST = 'QUERY_EXPIRATION_REQUEST';
export const QUERY_EXPIRATION_SUCCESS = 'QUERY_EXPIRATION_SUCCESS';
export const QUERY_EXPIRATION_FAILURE = 'QUERY_EXPIRATION_FAILURE';

export const EXPIRATION_BATCH_UPDATE_SUCCESS = 'EXPIRATION_BATCH_UPDATE_SUCCESS';
export const EXPIRATION_BATCH_UPDATE_FAILURE = 'EXPIRATION_BATCH_UPDATE_FAILURE';
export const EXPIRATION_BATCH_UPDATE_REQUEST = 'EXPIRATION_BATCH_UPDATE_REQUEST';

const initialState = {
  list: [] as Array<PersonRequirementExpiration>,
  requests: {
    query: {
      params: {
        from: null,
        to: null,
      } as QueryExpirationParams,
      inProgress: false as boolean,
      error: null as null | RequestError,
      results: [] as Array<PersonRequirementExpiration>,
    },
    batchUpdate: {
      params: [] as ExpirationBatchUpdateParams,
      inProgress: false as boolean,
      error: null as null | RequestError,
    },
  },
};
export type ExpirationState = typeof initialState;

export interface QueryExpirationRequestAction {
  type: typeof QUERY_EXPIRATION_REQUEST;
  params: QueryExpirationParams;
}
export interface QueryExpirationSuccessAction {
  type: typeof QUERY_EXPIRATION_SUCCESS;
  results: Array<PersonRequirementExpiration>;
}
export interface QueryExpirationFailureAction {
  type: typeof QUERY_EXPIRATION_FAILURE;
  error: RequestError;
}

export interface ExpirationBatchUpdateRequestAction {
  type: typeof EXPIRATION_BATCH_UPDATE_REQUEST;
  params: ExpirationBatchUpdateParams;
}
export interface ExpirationBatchUpdateSuccessAction {
  type: typeof EXPIRATION_BATCH_UPDATE_SUCCESS;
}
export interface ExpirationBatchUpdateFailureAction {
  type: typeof EXPIRATION_BATCH_UPDATE_FAILURE;
  error: RequestError;
}

type ActionType =
  | QueryExpirationRequestAction
  | QueryExpirationSuccessAction
  | QueryExpirationFailureAction
  | ExpirationBatchUpdateRequestAction
  | ExpirationBatchUpdateSuccessAction
  | ExpirationBatchUpdateFailureAction;

export default function expirationReducer(state = initialState, action: ActionType): ExpirationState {
  switch (action.type) {
    case QUERY_EXPIRATION_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            params: action.params,
            inProgress: true,
          },
        },
      };
    }
    case QUERY_EXPIRATION_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: null,
            results: action.results,
          },
        },
      };
    }
    case QUERY_EXPIRATION_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          query: {
            ...state.requests.query,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case EXPIRATION_BATCH_UPDATE_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          batchUpdate: {
            ...state.requests.batchUpdate,
            params: action.params,
            inProgress: true,
          },
        },
      };
    }
    case EXPIRATION_BATCH_UPDATE_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          batchUpdate: {
            ...state.requests.batchUpdate,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case EXPIRATION_BATCH_UPDATE_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          batchUpdate: {
            ...state.requests.batchUpdate,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
