import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { endOfToday } from 'date-fns';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { ProgressButton, TextField, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../../entities/Person';
import { useAuth } from '../../../../hooks/store/useAuth';
import { usePerson } from '../../../../hooks/store/usePerson';
import { defaultMinDate } from '../../../../utils/date';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import FormRow from '../../../layout/FormRow';
import EndWorkPersonDialog from './EndWorkPersonDialog';
import PersonChangeIDDialog from './PersonChangeIDDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    margin: `${theme.spacing(2)} 0 0`,
  },
  personMetaRow: {
    alignItems: 'flex-start',
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, .6)',
    },
  },
  buttonsRow: {
    display: 'flex',

    '& > .MuiButton-root + .MuiButton-root': {
      marginLeft: theme.spacing(1),
    },
  },
}));

type personForm = {
  email: string;
  managerList: string[];
  name: string;
  lastName: string;
  endAt: Date | null;
};

const schema = validate.object().shape({
  email: validate.string().email(),
  managerList: validate.array(validate.string().email()).min(1),
  name: validate.string().required(),
  lastName: validate.string().required(),
  endAt: validate.date().min(defaultMinDate).max(endOfToday()).nullable(),
});

type PersonDataProps = {
  person: Person;
};

export default function PersonData(props: PersonDataProps): JSX.Element {
  const classes = useStyles();
  const person = props.person;
  const history = useHistory();
  const { isAdmin } = useAuth();
  const { updatePerson, updatePersonRequest } = usePerson();

  const handleSubmit = useCallback(
    (values: personForm) => {
      updatePerson({
        ...person,
        name: values.name,
        lastname: values.lastName,
        email: values.email || undefined,
        managerList: values.managerList || undefined,
        endAt: values.endAt || undefined,
      } as Person);
    },
    [person, updatePerson]
  );

  const initialValues = useMemo(
    () => ({
      email: person.email || '',
      managerList: person.managerList || [],
      name: person.name || '',
      lastName: person.lastname || '',
      endAt: (person.endAt || null) as null | Date,
    }),
    [person]
  );

  const { form, setTouched, setValue, onSubmit } = useForm<personForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const [endWorkDialogOpen, setEndWorkDialogOpen] = useState(false);
  const [changeIDDialogOpen, setChangeIDDialogOpen] = useState(false);
  const handleEndWorkClick = useCallback(() => setEndWorkDialogOpen(true), []);
  const handleEndWorkDialogClose = useCallback(() => setEndWorkDialogOpen(false), []);
  const handleChangeIDClick = useCallback(() => setChangeIDDialogOpen(true), []);
  const handleChangeIDDialogClose = useCallback(
    (id?: string) => {
      setChangeIDDialogOpen(false);
      if (id !== undefined && id !== person.id) {
        history.replace(`/person/${id}/data`);
      }
    },
    [history, person.id]
  );

  return (
    <div className={classes.root}>
      <FormRow className={classes.personMetaRow}>
        <TextField
          label="Cognome"
          value={form.values.lastName}
          onChange={(value) => setValue(value, 'lastName')}
          onBlur={() => setTouched('lastName')}
          error={form.errors.lastName && form.touched.lastName}
          helperText={form.touched.lastName && form.errors.lastName?.message}
          disableClearable
          required
        />
        <TextField
          label="Nome"
          value={form.values.name}
          onChange={(value) => setValue(value, 'name')}
          onBlur={() => setTouched('name')}
          error={form.errors.name && form.touched.name}
          helperText={form.touched.name && form.errors.name?.message}
          disableClearable
          required
        />
      </FormRow>
      <FormRow className={classes.personMetaRow}>
        <TextField
          label="Email dipendente"
          value={form.values.email}
          onChange={(value) => setValue(value, 'email')}
          onBlur={() => setTouched('email')}
          error={form.errors.email && form.touched.email}
          helperText={form.touched.email && form.errors.email?.message}
          disableClearable
        />
      </FormRow>
      <FormRow className={classes.personMetaRow}>
        <MuiAutocomplete
          multiple
          freeSolo
          autoSelect
          disableClearable
          options={[]}
          renderTags={(value: string[][], getTagProps) =>
            value.map((option: unknown, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip variant="outlined" label={option as string} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label="Email manager"
              placeholder="Email"
              error={form.errors.managerList && (form.touched.managerList || form.values.managerList.length > 0)}
              helperText={
                (form.touched.managerList || form.values.managerList.length > 0) && form.errors.managerList?.message
              }
            />
          )}
          value={form.values.managerList}
          onChange={(_, value) => setValue(value, 'managerList')}
          onBlur={() => setTouched('managerList')}
        />
      </FormRow>
      <FormRow className={classes.personMetaRow}>
        {person.endAt && (
          <DatePicker
            label="Data fine lavoro"
            value={form.values.endAt}
            onChange={(value) => setValue(value, 'endAt')}
            onBlur={() => setTouched('endAt')}
            error={form.errors.endAt && form.touched.endAt}
            helperText={form.touched.endAt && form.errors.endAt?.message}
          />
        )}
      </FormRow>
      {isAdmin && !person.endAt && (
        <div className={classes.buttonsRow}>
          <Button variant="contained" color="secondary" onClick={handleChangeIDClick}>
            Modifica matricola
          </Button>
          <Button variant="contained" color="secondary" onClick={handleEndWorkClick}>
            Fine rapporto di lavoro
          </Button>
          <Spacer />
          <ProgressButton
            variant="contained"
            color="primary"
            onClick={onSubmit}
            inProgress={updatePersonRequest.inProgress}
            disabled={!form.isValid}
          >
            Aggiorna
          </ProgressButton>
        </div>
      )}
      <ErrorAlert error={updatePersonRequest.error} showDetails />
      {endWorkDialogOpen && (
        <EndWorkPersonDialog person={person} open={endWorkDialogOpen} handleClose={handleEndWorkDialogClose} />
      )}
      {changeIDDialogOpen && (
        <PersonChangeIDDialog person={person} open={changeIDDialogOpen} handleClose={handleChangeIDDialogClose} />
      )}
    </div>
  );
}
