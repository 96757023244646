import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from 'mdi-material-ui/AlertCircle';
import SuccessIcon from 'mdi-material-ui/CheckCircle';
import DeleteIcon from 'mdi-material-ui/TrashCan';
import { Person } from '../../../../entities/Person';
import { EditManagersFormContext } from './EditManagersFormContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.25, 1),
    // margin: theme.spacing(0, -1),
  },
  personName: {
    flex: '1 0 0%',
  },
  oldManagers: {
    color: theme.palette.error.dark,
    textDecoration: 'line-through',
  },
  loaderWrapper: {
    padding: theme.spacing(1.5),
    lineHeight: 1,
  },
  successWrapper: {
    padding: theme.spacing(1.5),
    lineHeight: 1,
    color: theme.palette.success.dark,
  },
  errorWrapper: {
    padding: theme.spacing(1.5),
    lineHeight: 1,
    color: theme.palette.error.dark,
  },
}));

type EditManagersRowProps = {
  person: Person;
  onPersonRemove: (person: Person) => void;
};

export default function EditManagersRow(props: EditManagersRowProps): JSX.Element {
  const classes = useStyles();
  const { person, onPersonRemove } = props;
  const {
    updateStatus: { byPerson },
  } = useContext(EditManagersFormContext);

  const updateStatus = byPerson[person.id];

  return (
    <li className={classes.wrapper}>
      <span className={classes.personName}>
        {person.id} {person.lastname} {person.name}
      </span>
      {person.managerList &&
        person.managerList.map((manager) => (
          <span key={manager} className={classes.oldManagers}>
            {manager}
          </span>
        ))}
      {updateStatus?.inProgress ? (
        <span className={classes.loaderWrapper}>
          <CircularProgress size={24} />
        </span>
      ) : updateStatus?.success ? (
        <span className={classes.successWrapper}>
          <SuccessIcon />
        </span>
      ) : updateStatus?.error ? (
        <span className={classes.errorWrapper}>
          <ErrorIcon />
        </span>
      ) : (
        <Tooltip title="Rimuovi" placement="top" arrow disableInteractive>
          <IconButton onClick={() => onPersonRemove(person)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </li>
  );
}
