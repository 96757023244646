import React from 'react';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import { editManagerStaffSection as section } from '../../../../utils/appSections';
import AppLayout from '../../../layout/Layout';
import EditManagersForm from './EditManagersForm';
import { EditManagersFormProvider } from './EditManagersFormContext';

export default function EditManagers(): JSX.Element {
  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth="lg">
        <EditManagersFormProvider>
          <EditManagersForm />
        </EditManagersFormProvider>
      </Container>
    </AppLayout>
  );
}
