import React, { useMemo, useCallback } from 'react';
import { startOfTomorrow } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton, TextField, CheckBox, QtyInput, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../../entities/Person';
import { PersonRequirement } from '../../../../entities/Requirement';
import { usePerson } from '../../../../hooks/store/usePerson';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import FormRow from '../../../layout/FormRow';

type PersonRequirementEditForm = {
  expireAt: Date;
  needsGlasses: boolean;
  detailsHR: boolean;
  notificationDays: number;
  notes: string;
};

type PersonRequirementEditDialogProps = {
  open: boolean;
  handleClose: (submitted: boolean) => void;
  person: Person;
  requirement: PersonRequirement;
};

const minDate = startOfTomorrow();
const schema = validate.object().shape({
  expireAt: validate.date().min(minDate).required(),
  notificationDays: validate.number().min(1).max(180).required(),
});

export default function PersonRequirementEditDialog(props: PersonRequirementEditDialogProps): JSX.Element {
  const { requirement, person, open, handleClose } = props;
  const { updatePersonRequirement, updatePersonRequirementClear, updatePersonRequirementRequest } = usePerson();

  const initialValues = useMemo(
    () => ({
      expireAt: requirement.expireAt as Date,
      needsGlasses: requirement.needsGlasses || false,
      detailsHR: requirement.detailsHR || false,
      notificationDays: requirement.notificationsDays,
      notes: requirement.notes || '',
    }),
    [requirement]
  );
  const handleSubmit = useCallback(
    async (values: PersonRequirementEditForm) => {
      const result = await updatePersonRequirement(person, {
        id: requirement.id,
        name: requirement.name,
        opticalRequirement: requirement.opticalRequirement,
        notificationsDays: values.notificationDays,
        expireAt: values.expireAt,
        notes: values.notes,
        code: requirement.code,
        needsGlasses: values.needsGlasses,
        detailsHR: values.detailsHR,
      });

      if (result?.id) {
        handleClose(true);
        updatePersonRequirementClear();
      }
    },
    [
      handleClose,
      person,
      requirement.code,
      requirement.id,
      requirement.name,
      requirement.opticalRequirement,
      updatePersonRequirement,
      updatePersonRequirementClear,
    ]
  );
  const { form, setValue, setTouched, onSubmit } = useForm<PersonRequirementEditForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        updatePersonRequirementClear();
      }
    },
    [handleClose, updatePersonRequirementClear]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>
        {requirement.name}
        {requirement.opticalRequirement ? ` (${requirement.opticalRequirement?.name})` : ''}
        {requirement.code ? ` (${requirement.code})` : ''} - {person ? ` ${person.name} ${person.lastname}` : ''}
      </DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <DatePicker
            label={requirement.opticalRequirement ? 'Scadenza visita' : 'Data scadenza'}
            value={form.values.expireAt}
            minDate={minDate}
            onChange={(value) => setValue(value, 'expireAt')}
            onBlur={() => setTouched('expireAt')}
            error={Boolean(form.errors.expireAt)}
            helperText={form.errors.expireAt?.message}
          />
          <QtyInput
            label="Invio notifiche"
            value={form.values.notificationDays}
            onChange={(value) => setValue(value, 'notificationDays')}
            onBlur={() => setTouched('notificationDays')}
            min={1}
            max={180}
            error={form.errors.notificationDays && form.touched.notificationDays}
            helperText={(form.touched.notificationDays && form.errors.notificationDays?.message) || 'Giorni prima'}
          />
        </FormRow>
        {requirement.opticalRequirement && (
          <FormRow>
            <CheckBox
              label="Necessita lenti correttive"
              value={form.values.needsGlasses}
              onChange={(value) => setValue(value, 'needsGlasses')}
              onBlur={() => setTouched('needsGlasses')}
            />
            <CheckBox
              label="Dettagli c/o HR"
              value={form.values.detailsHR}
              onChange={(value) => setValue(value, 'detailsHR')}
              onBlur={() => setTouched('detailsHR')}
            />
          </FormRow>
        )}
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        <ErrorAlert error={updatePersonRequirementRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={updatePersonRequirementRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
