import { Qualification } from '../entities/Qualification';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class QualificationRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/qualifications', token);
  }
  async getList(): Promise<Qualification[]> {
    const { data } = await this.axios.get<Qualification[]>('/');
    return data.map((qualification) => ({
      ...qualification,
      requirements: qualification.requirements || [],
    }));
  }
}
