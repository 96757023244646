import React from 'react';
import { RequestError } from '@top-solution/utils';

export interface ErrorMessageProps {
  error: RequestError | Error | null | undefined;
}

export default function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  const error = props.error as RequestError;

  if (!error) {
    return <></>;
  }

  return <span>{error.code ? `${error.code} ${error.message}` : error.message}</span>;
}
