import React, { useCallback } from 'react';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton, TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Duty } from '../../../../entities/Duty';
import { Person } from '../../../../entities/Person';
import { usePerson } from '../../../../hooks/store/usePerson';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';

type PersonDutyDeleteForm = {
  confirm: string;
};

const CONFIRM_TEXT = 'confermo eliminazione';

type PersonDutyDeleteDialogProps = {
  open: boolean;
  handleClose: (submitted: boolean) => void;
  person: Person;
  duty: Duty;
};

const initialValues = {
  confirm: '',
};

const schema = validate.object().shape({
  confirm: validate.string().equals([CONFIRM_TEXT]).required(),
});

export default function PersonDutyDeleteDialog(props: PersonDutyDeleteDialogProps): JSX.Element {
  const { duty, person, open, handleClose } = props;
  const { deletePersonDuty, deletePersonDutyClear, deletePersonDutyRequest } = usePerson();

  const handleSubmit = useCallback(async () => {
    const result = await deletePersonDuty(person, duty);
    if (result?.id) {
      handleClose(true);
      deletePersonDutyClear();
    }
  }, [handleClose, person, duty, deletePersonDuty, deletePersonDutyClear]);

  const { form, setValue, setTouched, onSubmit } = useForm<PersonDutyDeleteForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        deletePersonDutyClear();
      }
    },
    [deletePersonDutyClear, handleClose]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Eliminazione mansione</DialogTitle>
      <DialogContent dividers>
        <Alert severity="error">
          <AlertTitle>Attenzione</AlertTitle>
          <p>
            La mansione <em>{duty.qualification.name}</em>
            {person ? (
              <>
                {' '}
                associata a{' '}
                <em>
                  {person.name} {person.lastname}
                </em>{' '}
              </>
            ) : null}
            <strong>verrà eliminata e non sarà recuperabile</strong>.
          </p>
          <br />
          Per continuare digita “{CONFIRM_TEXT}”:
          <TextField
            value={form.values.confirm}
            onChange={(value) => setValue(value, 'confirm')}
            onBlur={() => setTouched('confirm')}
            error={form.errors.confirm && form.touched.confirm}
            disableClearable
            fullWidth
            required
          />
        </Alert>
        <ErrorAlert error={deletePersonDutyRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={deletePersonDutyRequest.inProgress}
        >
          Elimina
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
