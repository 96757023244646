import React from 'react';
import { Helmet } from 'react-helmet';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AppLayout from '../layout/Layout';
import { ReactComponent as UnauthorizedImage } from './unauthorized.svg';

export default function UnauthorizedPage(): React.ReactElement {
  const title = 'Accesso non autorizzato';

  return (
    <AppLayout>
      <Helmet>
        <title>{title} | QCPC</title>
      </Helmet>
      <Card sx={{ textAlign: 'center' }}>
        <CardHeader title={title} />
        <CardContent>
          <UnauthorizedImage />
        </CardContent>
      </Card>
    </AppLayout>
  );
}
