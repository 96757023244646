import React, { useCallback, useState } from 'react';
import { endOfToday } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton, TextField, CheckBox, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Duty } from '../../../../entities/Duty';
import { Person } from '../../../../entities/Person';
import { Plant } from '../../../../entities/Plant';
import { Program } from '../../../../entities/Program';
import { Qualification } from '../../../../entities/Qualification';
import { PersonRequirement } from '../../../../entities/Requirement';
import { Skill } from '../../../../entities/Skill';
import { Stamp } from '../../../../entities/Stamp';
import { usePerson } from '../../../../hooks/store/usePerson';
import { defaultMinDate } from '../../../../utils/date';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import IsMilitarySelect from '../../../inputs/IsMilitarySelect';
import PlantSelect from '../../../inputs/PlantSelect';
import ProgramSelect from '../../../inputs/ProgramSelect';
import QualificationSelect from '../../../inputs/QualificationSelect';
import SkillSelect from '../../../inputs/SkillSelect';
import StampListSelect from '../../../inputs/StampListSelect';
import FormRow from '../../../layout/FormRow';
import PersonDutyRequirements from './PersonDutyRequirements';

type PersonDutyAddForm = {
  plant: Plant | null;
  qualification: Qualification | null;
  programs: Program[];
  isMilitary: boolean | null;
  isProduction: boolean;
  isSafetyPart: boolean;
  training: boolean;
  from: Date;
  notes: string;
  stamp: Stamp | null;
  skills: Skill[];
};

type PersonDutyAddDialogProps = {
  open: boolean;
  handleClose: (submitted: boolean) => void;
  person: Person;
};

const initialValues = {
  plant: null as null | Plant,
  qualification: null as null | Qualification,
  programs: [] as Program[],
  isMilitary: null,
  isProduction: false,
  isSafetyPart: false,
  training: false,
  from: endOfToday(),
  notes: '',
  stamp: null as null | Stamp,
  skills: [] as Skill[],
};

const schema = validate.object().shape({
  plant: validate.mixed().required(),
  qualification: validate.mixed().required(),
  programs: validate.array().min(1).required(),
  isMilitary: validate.bool().nullable(),
  isSafetyPart: validate.bool().nullable(),
  isProduction: validate.bool().required(),
  training: validate.bool().required(),
  from: validate.date().min(defaultMinDate).required(),
});

export default function PersonDutyAddDialog(props: PersonDutyAddDialogProps): JSX.Element {
  const { person, open, handleClose } = props;
  const { createPersonDuty, createPersonDutyClear, createPersonDutyRequest } = usePerson();
  const [selectedRequirements, setSelectedRequirements] = useState([] as PersonRequirement[]);
  const [fullfilledRequirements, setFullfilledRequirements] = useState(false);
  const handleSubmit = useCallback(
    async (values: PersonDutyAddForm) => {
      const duty = {
        id: -1,
        plant: values.plant,
        qualification: values.qualification,
        programs: values.programs,
        isProduction: values.isProduction,
        isSafetyPart: values.isSafetyPart,
        skills: values.skills,
        isMilitary: values.isMilitary === null ? undefined : values.isMilitary,
        training: values.training,
        from: values.from,
        notes: values.notes,
        stamp: values.stamp,
        requirements: values.training ? undefined : selectedRequirements,
      } as Duty;
      const result = await createPersonDuty(person, duty);
      if (result?.id) {
        handleClose(true);
        createPersonDutyClear();
      }
    },
    [createPersonDuty, createPersonDutyClear, handleClose, person, selectedRequirements]
  );
  const { form, setValue, setTouched, onSubmit } = useForm<PersonDutyAddForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        createPersonDutyClear();
      }
    },
    [createPersonDutyClear, handleClose]
  );

  const handleChangeSelectedRequirements = useCallback(
    (selectedRequirements: PersonRequirement[], fullfilledRequirements: boolean) => {
      setSelectedRequirements(selectedRequirements);
      setFullfilledRequirements(fullfilledRequirements);
    },
    []
  );
  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>Nuova mansione</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <PlantSelect
            value={form.values.plant}
            onChange={(value) => setValue(value, 'plant')}
            onBlur={() => setTouched('plant')}
            error={form.errors.plant && form.touched.plant}
            helperText={form.touched.plant && form.errors.plant?.message}
            required
          />
          <IsMilitarySelect
            value={form.values.isMilitary}
            onChange={(value) => setValue(value, 'isMilitary')}
            onBlur={() => setTouched('isMilitary')}
            error={form.errors.isMilitary && form.touched.isMilitary}
            helperText={form.touched.isMilitary && form.errors.isMilitary?.message}
          />
          <DatePicker
            label="Inizio validità"
            value={form.values.from}
            onChange={(value) => setValue(value, 'from')}
            onBlur={() => setTouched('from')}
            error={form.errors.from && form.touched.from}
            helperText={form.touched.from && form.errors.from?.message}
            required
          />
        </FormRow>
        <FormRow>
          <QualificationSelect
            value={form.values.qualification}
            onChange={(value) => setValue(value, 'qualification')}
            onBlur={() => setTouched('qualification')}
            error={form.errors.qualification && form.touched.qualification}
            helperText={form.touched.qualification && form.errors.qualification?.message}
            required
          />
        </FormRow>
        <FormRow>
          <ProgramSelect
            multiple
            value={form.values.programs}
            onChange={(value) => setValue(value, 'programs')}
            onBlur={() => setTouched('programs')}
            error={form.errors.programs && form.touched.programs}
            helperText={form.touched.programs && form.errors.programs?.message}
            required
          />
        </FormRow>
        <FormRow>
          <CheckBox
            label="Solo produzione"
            color="primary"
            value={form.values.isProduction}
            onChange={(value) => setValue(value, 'isProduction')}
            onBlur={() => setTouched('isProduction')}
          />
          <CheckBox
            label="Safety part"
            color="primary"
            value={form.values.isSafetyPart}
            onChange={(value) => setValue(value, 'isSafetyPart')}
            onBlur={() => setTouched('isSafetyPart')}
          />
          <CheckBox
            label="In training"
            color="primary"
            value={form.values.training}
            onChange={(value) => setValue(value, 'training')}
            onBlur={() => setTouched('training')}
          />
        </FormRow>
        <FormRow>
          <SkillSelect
            multiple
            value={form.values.skills}
            onChange={(value) => setValue(value, 'skills')}
            onBlur={() => setTouched('skills')}
          />
        </FormRow>
        <FormRow>
          <StampListSelect
            stamps={person.stampHistory?.filter((record) => !record.to).map((record) => record as Stamp) || []}
            value={form.values.stamp}
            onChange={(value) => setValue(value, 'stamp')}
            onBlur={() => setTouched('stamp')}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        {!form.values.training && form.values.qualification && (
          <PersonDutyRequirements
            person={person}
            qualification={form.values.qualification}
            isProduction={form.values.isProduction}
            isSafetyPart={form.values.isSafetyPart}
            selectedRequirements={selectedRequirements}
            onChange={handleChangeSelectedRequirements}
          />
        )}
        <ErrorAlert error={createPersonDutyRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid || (!form.values.training && !fullfilledRequirements)}
          inProgress={createPersonDutyRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
