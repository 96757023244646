import React, { useMemo, useCallback } from 'react';
import { endOfToday } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton, TextField, QtyInput, DatePicker } from '@top-solution/mui-inputs';

import { useForm } from '@top-solution/use-form';
import { Person } from '../../../entities/Person';
import { Stamp } from '../../../entities/Stamp';
import { useStamp } from '../../../hooks/store/useStamp';
import { defaultMinDate } from '../../../utils/date';
import Spacer from '../../../utils/Spacer';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import PersonSelect from '../../inputs/PersonSelect';
import FormRow from '../../layout/FormRow';

type AssignStampForm = {
  person: Person | null;
  from: Date;
  qty: number;
  notes: string;
};

type AssignStampDialogProps = {
  open: boolean;
  handleClose: (reassigned: boolean) => void;
  stamp: Stamp;
};

export default function AssignStampDialog(props: AssignStampDialogProps): JSX.Element {
  const { open, handleClose, stamp } = props;

  const qtyMax = useMemo(() => (stamp.archivedQty || 0) + (stamp.owner?.qty || 0), [stamp.archivedQty, stamp.owner]);
  const minDate = useMemo(
    () => stamp.suspension?.to || stamp.owner?.to || stamp.owner?.from || defaultMinDate,
    [stamp.owner, stamp.suspension]
  );
  const { assignStamp, assignStampClear, assignStampRequest } = useStamp();
  const initialValues = useMemo(
    () => ({
      person: null,
      from: endOfToday(),
      qty: stamp.owner?.qty || 1,
      notes: '',
    }),
    [stamp.owner]
  );
  const schema = useMemo(
    () =>
      validate.object().shape({
        person: validate
          .object()
          .shape({
            id: validate.string().notOneOf([stamp.owner?.person.id], 'È già in possesso di questo timbro'),
          })
          .required(),
        from: validate.date().min(minDate).max(endOfToday()).required(),
        qty: validate.number().min(1).max(qtyMax).required(),
        notes: validate.string(),
      }),
    [minDate, qtyMax, stamp.owner]
  );
  const handleSubmit = useCallback(
    async (values: AssignStampForm) => {
      if (values.person) {
        const response = await assignStamp(stamp.id, {
          person: values.person,
          qty: values.qty,
          from: values.from,
          notes: values.notes,
        });
        if (response?.id) {
          handleClose(true);
          assignStampClear();
        }
      }
    },
    [assignStamp, assignStampClear, handleClose, stamp.id]
  );
  const { form, setValue, setTouched, onSubmit } = useForm<AssignStampForm>({ initialValues, schema, handleSubmit });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        assignStampClear();
      }
    },
    [assignStampClear, handleClose]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Assegna timbro</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <PersonSelect
            label="Possessore"
            value={form.values.person}
            onChange={(value) => setValue(value, 'person')}
            onBlur={() => setTouched('person')}
            error={form.errors.person && form.touched.person}
            helperText={form.touched.person && form.errors.person?.message}
            required
          />
          <DatePicker
            label="Inizio validità"
            minDate={minDate}
            value={form.values.from}
            onChange={(value) => setValue(value, 'from')}
            onBlur={() => setTouched('from')}
            error={form.errors.from && form.touched.from}
            helperText={form.touched.from && form.errors.from?.message}
            required
          />
          <QtyInput
            label="Quantità assegnata"
            value={form.values.qty}
            onChange={(value) => setValue(value, 'qty')}
            min={1}
            max={qtyMax}
            error={Boolean(form.errors.qty)}
            helperText={form.errors.qty?.message}
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        <ErrorAlert error={assignStampRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={assignStampRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
