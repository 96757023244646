import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestError } from '@top-solution/utils';
import { NotificationsEmailList } from '../../entities/NotificationsEmailList';

const initialState = {
  data: {
    global: { email: [] },
    byRequirement: [],
  } as unknown as NotificationsEmailList,
  request: {
    read: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    },
    set: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    },
  },
};

export type NotificationsState = typeof initialState;

export const notificationsEmailListSlice = createSlice({
  name: 'notificationsEmailList',
  initialState,
  reducers: {
    readRequestAction: (state) => {
      state.request.read.error = null;
      state.request.read.inProgress = true;
    },
    readSuccessAction: (state, action: PayloadAction<{ data: NotificationsEmailList }>) => {
      state.data = action.payload.data;
      state.request.read.inProgress = false;
      state.request.read.lastUpdate = Date.now();
    },
    readErrorAction: (state, action: PayloadAction<{ error: RequestError }>) => {
      state.request.read.error = action.payload.error;
      state.request.read.inProgress = false;
    },
    setRequestAction: (state) => {
      state.request.set.error = null;
      state.request.set.inProgress = true;
    },
    setSuccessAction: (state, action: PayloadAction<{ data: NotificationsEmailList }>) => {
      state.data = action.payload.data;
      state.request.set.inProgress = false;
      state.request.set.lastUpdate = Date.now();
    },
    setErrorAction: (state, action: PayloadAction<{ error: RequestError }>) => {
      state.request.set.error = action.payload.error;
      state.request.set.inProgress = false;
    },
  },
});

export const {
  readRequestAction,
  readSuccessAction,
  readErrorAction,
  setRequestAction,
  setSuccessAction,
  setErrorAction,
} = notificationsEmailListSlice.actions;

export default notificationsEmailListSlice.reducer;
