import React from 'react';
import { Autocomplete, AutocompleteProps } from '@top-solution/mui-inputs';

import { Program, PROGRAM_ALL_ID } from '../../entities/Program';
import { useProgramListResource } from '../../hooks/store/useProgram';

type ProgramSelectProps<Multiple extends boolean | undefined, DisableClearable extends boolean | undefined> = Omit<
  AutocompleteProps<string, Program, Multiple, DisableClearable>,
  'options' | 'request'
>;

export default function ProgramSelect<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(props: ProgramSelectProps<Multiple, DisableClearable>): JSX.Element {
  const { label, multiple, value, ...otherProps } = props;
  const [options, request] = useProgramListResource();
  const allOptionSelected =
    multiple && value && 'length' in value && (value as Program[]).find(({ id }) => id === PROGRAM_ALL_ID);

  const groupBy = function (option: Program): string {
    return option.id === PROGRAM_ALL_ID ? '' : 'Velivoli';
  };

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      label={label || 'Applicazione'}
      options={options}
      request={request}
      getOptionLabel={({ name }) => name}
      groupBy={groupBy}
      multiple={multiple}
      getOptionDisabled={({ id }) =>
        Boolean(
          multiple &&
            ((id === PROGRAM_ALL_ID &&
              value &&
              'length' in value &&
              (value as Program[]).length > 0 &&
              !allOptionSelected) ||
              (allOptionSelected && id !== PROGRAM_ALL_ID))
        )
      }
    />
  );
}
