import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestError } from '@top-solution/utils';
import { AppSettings } from '../../entities/CurrentUser';

const initialState = {
  data: {
    rowsPerPage: 10,
    miniDrawer: false,
    excludeEnded: false,
    excludeInTraining: false,
    hiddenColumnsByTable: {},
  } as AppSettings,
  request: {
    read: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    },
    set: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | number,
    },
  },
};

export type AppSettingsState = typeof initialState;

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    readRequestAction: (state) => {
      state.request.read.error = null;
      state.request.read.inProgress = true;
    },
    readSuccessAction: (state, action: PayloadAction<{ config: AppSettings }>) => {
      state.data = { ...state.data, ...action.payload.config };
      state.request.read.inProgress = false;
      state.request.read.lastUpdate = Date.now();
    },
    readErrorAction: (state, action: PayloadAction<{ error: RequestError }>) => {
      state.request.read.error = action.payload.error;
      state.request.read.inProgress = false;
    },
    setRequestAction: (state, action: PayloadAction<{ config: AppSettings }>) => {
      state.data = action.payload.config;
      state.request.set.error = null;
      state.request.set.inProgress = true;
    },
    setSuccessAction: (state) => {
      state.request.set.inProgress = false;
    },
    setErrorAction: (state, action: PayloadAction<{ error: RequestError }>) => {
      state.request.set.error = action.payload.error;
      state.request.set.inProgress = false;
    },
  },
});

export const {
  readRequestAction,
  readSuccessAction,
  readErrorAction,
  setRequestAction,
  setSuccessAction,
  setErrorAction,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
