import React, { useCallback } from 'react';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiTextField from '@material-ui/core/TextField';
import { ProgressButton, TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../entities/Person';
import { usePerson } from '../../../hooks/store/usePerson';
import Spacer from '../../../utils/Spacer';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import FormRow from '../../layout/FormRow';

type AddPersonDialogForm = {
  name: string;
  lastName: string;
  email: string;
  managerList: string[];
  id: string;
};

type AddPersonDialogProps = {
  open: boolean;
  handleClose: (created: Person | null) => void;
};

const schema = validate.object().shape({
  name: validate.string().required(),
  lastName: validate.string().required(),
  email: validate.string().email(),
  managerList: validate.array(validate.string().email()).min(1),
  id: validate.number().required().min(0).integer(),
});

const initialValues = {
  name: '',
  lastName: '',
  email: '',
  managerList: [],
  id: '',
};

export default function AddPersonDialog(props: AddPersonDialogProps): JSX.Element {
  const { open, handleClose } = props;
  const { createPersonClear, createPerson, createPersonRequest } = usePerson();
  const handleSubmit = useCallback(
    async (values: AddPersonDialogForm) => {
      const result = await createPerson({
        id: values.id,
        email: values.email || undefined,
        managerList: values.managerList,
        name: values.name,
        lastname: values.lastName,
      });
      if (result?.id) {
        handleClose(result);
        createPersonClear();
      }
    },
    [createPerson, createPersonClear, handleClose]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<AddPersonDialogForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(null);
        createPersonClear();
      }
    },
    [createPersonClear, handleClose]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Nuovo dipendente</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <TextField
            label="Cognome"
            value={form.values.lastName}
            onChange={(value) => setValue(value, 'lastName')}
            onBlur={() => setTouched('lastName')}
            error={form.errors.lastName && form.touched.lastName}
            helperText={form.touched.lastName ? form.errors.lastName?.message : ''}
            disableClearable
            required
          />
          <TextField
            label="Nome"
            value={form.values.name}
            onChange={(value) => setValue(value, 'name')}
            onBlur={() => setTouched('name')}
            error={form.errors.name && form.touched.name}
            helperText={form.touched.name ? form.errors.name?.message : ''}
            disableClearable
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Matricola"
            value={form.values.id}
            onChange={(value) => setValue(value, 'id')}
            onBlur={() => setTouched('id')}
            error={form.errors.id && form.touched.id}
            helperText={form.touched.id ? form.errors.id?.message : ''}
            style={{ maxWidth: '12ch' }}
            disableClearable
            required
          />
          <TextField
            label="Email"
            value={form.values.email}
            onChange={(value) => setValue(value, 'email')}
            onBlur={() => setTouched('email')}
            error={form.errors.email && form.touched.email}
            helperText={form.touched.email ? form.errors.email?.message : ''}
            disableClearable
          />
        </FormRow>
        <FormRow>
          <MuiAutocomplete
            multiple
            freeSolo
            autoSelect
            disableClearable
            options={[]}
            renderTags={(value: string[][], getTagProps) =>
              value.map((option: unknown, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip variant="outlined" label={option as string} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <MuiTextField
                {...params}
                label="Email manager"
                placeholder="Email"
                error={form.errors.managerList && (form.touched.managerList || form.values.managerList.length > 0)}
                helperText={
                  (form.touched.managerList || form.values.managerList.length > 0) && form.errors.managerList?.message
                }
              />
            )}
            value={form.values.managerList}
            onChange={(_, value) => setValue(value, 'managerList')}
            onBlur={() => setTouched('managerList')}
          />
        </FormRow>
        <ErrorAlert error={createPersonRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={createPersonRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
