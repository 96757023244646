import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from 'mdi-material-ui/Close';

import { usePerson } from '../../../../hooks/store/usePerson';
import Spacer from '../../../../utils/Spacer';
import AppLayout from '../../../layout/Layout';
import RequestResult from '../../../RequestResult';
import TabbedComponents from '../../../TabbedComponents';
import PersonData from './PersonData';
import PersonMissingRequirementList from './PersonMissingRequirementsList';
import PersonRequirementList from './PersonRequirementList';
import PersonRoster from './PersonRoster';
import PersonStampsList from './PersonStampsList';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    marginTop: theme.spacing(1),
    position: 'relative',
  },
}));

type RouteParams = {
  id: string;
};

export default function PersonDetails(): JSX.Element {
  const classes = useStyles();

  const { id } = useParams<RouteParams>();
  const { personDetails, readPersonDetails, readPersonDetailsRequest } = usePerson();

  const tabs = useMemo(
    () =>
      personDetails
        ? [
            {
              title: 'Anagrafica',
              slug: `/person/${id}/data`,
              children: <PersonData person={personDetails} />,
            },
            {
              title: 'Mansioni',
              slug: `/person/${id}/roster`,
              children: <PersonRoster person={personDetails} />,
            },
            {
              title: 'Requisiti',
              slug: `/person/${id}/requirements`,
              children: <PersonRequirementList person={personDetails} />,
            },
            {
              title: 'Requisiti mancanti',
              slug: `/person/${id}/missing-requirements`,
              children: <PersonMissingRequirementList person={personDetails} />,
            },
            {
              title: 'Timbri',
              slug: `/person/${id}/stamps`,
              children: <PersonStampsList person={personDetails} />,
            },
          ]
        : [],
    [id, personDetails]
  );

  useEffect(() => {
    if (readPersonDetailsRequest.id !== id && !readPersonDetailsRequest.error && !readPersonDetailsRequest.inProgress) {
      readPersonDetails(id);
    }
  }, [
    id,
    readPersonDetails,
    readPersonDetailsRequest.error,
    readPersonDetailsRequest.id,
    readPersonDetailsRequest.inProgress,
  ]);

  const title = useMemo(
    () =>
      personDetails
        ? `Dipendente ${personDetails.id} ${personDetails.lastname} ${personDetails.name}`
        : 'Dettagli Dipendente',
    [personDetails]
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{title} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false}>
        <RequestResult request={readPersonDetailsRequest} submitted={Boolean(readPersonDetailsRequest.id)}>
          {personDetails && (
            <>
              <Typography component="h2" variant="h6" className={classes.title}>
                {title}
                <Spacer />
                <IconButton component={Link} to={'/staff'}>
                  <CloseIcon />
                </IconButton>
              </Typography>
              <TabbedComponents components={tabs} className={classes.tabs} />
            </>
          )}
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
