import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from 'mdi-material-ui/Magnify';
import { ProgressButton } from '@top-solution/mui-inputs';

interface FilterFormProps {
  children: Array<React.ReactNode> | React.ReactNode;
  onSubmit: (event: React.FormEvent) => void;
  className?: string;
  request: {
    inProgress: boolean;
  };
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default function FilterForm(props: FilterFormProps): JSX.Element {
  const { children, onSubmit, className, request, disabled } = props;
  const classes = useStyles();
  return (
    <form className={clsx(classes.form, className)} onSubmit={onSubmit}>
      {children}
      <ProgressButton
        type="submit"
        variant="contained"
        color="primary"
        className="submitButton"
        startIcon={<SearchIcon />}
        inProgress={request.inProgress}
        disabled={disabled}
      >
        Cerca
      </ProgressButton>
    </form>
  );
}
