import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { endOfToday } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressButton, TextField, QtyInput, DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../entities/Person';
import { Stamp, StampType, StampShape } from '../../../entities/Stamp';
import { useStamp } from '../../../hooks/store/useStamp';
import { defaultMinDate } from '../../../utils/date';
import Spacer from '../../../utils/Spacer';
import validate from '../../../utils/validate';
import { ErrorAlert } from '../../Error';
import PersonSelect from '../../inputs/PersonSelect';
import StampShapeSelect from '../../inputs/StampShapeSelect';
import StampTypeSelect from '../../inputs/StampTypeSelect';
import FormRow from '../../layout/FormRow';

const useStyles = makeStyles({
  hidden: {
    visibility: 'hidden',
  },
});

type AddStampDialogForm = {
  code: string;
  shape: StampShape | null;
  type: StampType | null;
  owner: Person | null;
  archivedQty: number;
  ownerQty: number;
  ownedFrom: Date;
  notes: string;
};

type AddStampDialogProps = {
  open: boolean;
  handleClose: () => void;
  owner?: Person;
};

const today = endOfToday();
const schema = validate.object().shape({
  code: validate.string().required(),
  shape: validate.mixed().required(),
  type: validate.mixed().required(),
  owner: validate.object().nullable(),
  archivedQty: validate
    .number()
    .max(100)
    .when('owner', (owner: Person | null, schema: validate.NumberSchema) => (!owner ? schema.min(1) : schema.min(0)))
    .required(),
  ownerQty: validate
    .number()
    .max(100)
    .when('owner', (owner: Person | null, schema: validate.NumberSchema) => (owner ? schema.min(1) : schema.min(0)))
    .required(),
  ownedFrom: validate.date().min(defaultMinDate).max(today),
  notes: validate.string(),
});

export default function AddStampDialog(props: AddStampDialogProps): JSX.Element {
  const classes = useStyles();
  const { open, handleClose } = props;
  const { createStamp, createStampRequest, createStampClear } = useStamp();

  const handleSubmit = useCallback(
    async (values: AddStampDialogForm) => {
      const stamp = {
        code: values.code,
        shape: values.shape,
        type: values.type,
        owner: values.owner
          ? {
              person: values.owner,
              from: values.ownedFrom,
              qty: values.ownerQty,
              notes: values.notes,
            }
          : undefined,
        archivedQty: values.archivedQty,
      } as Stamp;

      const result = await createStamp(stamp);
      if (result?.id) {
        handleClose();
        createStampClear();
      }
    },
    [createStamp, createStampClear, handleClose]
  );
  const [initialValues] = useState({
    code: '',
    shape: null as null | StampShape,
    type: null as null | StampType,
    owner: props.owner || null,
    archivedQty: 1,
    ownerQty: 1,
    ownedFrom: today,
    notes: '',
  });
  const { form, setTouched, setValue, onSubmit } = useForm<AddStampDialogForm>({ initialValues, schema, handleSubmit });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose();
        createStampClear();
      }
    },
    [handleClose, createStampClear]
  );

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Nuovo timbro</DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <StampShapeSelect
            value={form.values.shape}
            onChange={(value) => setValue(value, 'shape')}
            onBlur={() => setTouched('shape')}
            error={form.errors.shape && form.touched.shape}
            helperText={form.touched.shape && form.errors.shape?.message}
          />
          <StampTypeSelect
            value={form.values.type}
            onChange={(value) => setValue(value, 'type')}
            onBlur={() => setTouched('type')}
            error={form.errors.type && form.touched.type}
            helperText={form.touched.type && form.errors.type?.message}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Numero"
            value={form.values.code}
            onChange={(value) => setValue(value, 'code')}
            onBlur={() => setTouched('code')}
            error={form.errors.code && form.touched.code}
            helperText={(form.touched.code && form.errors.code?.message) || ' '}
          />
          <QtyInput
            label="Quantità archiviata"
            style={{ maxWidth: '14ch' }}
            value={form.values.archivedQty}
            min={!form.values.owner ? 1 : 0}
            max={100}
            onChange={(value) => setValue(value, 'archivedQty')}
            onBlur={() => setTouched('archivedQty')}
            error={form.errors.archivedQty && form.touched.archivedQty}
            helperText={form.errors.archivedQty?.message}
          />
        </FormRow>
        <FormRow>
          <PersonSelect
            label="Possessore"
            value={form.values.owner}
            onChange={(value) => setValue(value, 'owner')}
            onBlur={() => setTouched('owner')}
            error={form.errors.owner && form.touched.owner}
            helperText={form.touched.owner && form.errors.owner?.message}
          />
          <DatePicker
            label="Data assegnazione"
            className={clsx({
              [classes.hidden]: !form.values.owner,
            })}
            style={{ maxWidth: '21ch' }}
            value={form.values.ownedFrom}
            disabled={!form.values.owner}
            onChange={(value) => setValue(value, 'ownedFrom')}
            onBlur={() => setTouched('ownedFrom')}
            error={form.errors.ownedFrom && form.touched.ownedFrom}
            helperText={form.touched.ownedFrom && form.errors.ownedFrom?.message}
          />
          <QtyInput
            label="Quantità assegnata"
            className={clsx({ [classes.hidden]: !form.values.owner })}
            style={{ maxWidth: '14ch' }}
            value={form.values.ownerQty}
            min={form.values.owner ? 1 : 0}
            max={100}
            disabled={!form.values.owner}
            onChange={(value) => setValue(value, 'ownerQty')}
            onBlur={() => setTouched('ownerQty')}
            error={form.errors.ownerQty && form.touched.ownerQty}
            helperText={form.errors.ownerQty?.message}
          />
        </FormRow>
        <FormRow className={clsx({ [classes.hidden]: !form.values.owner })}>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        <ErrorAlert error={createStampRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid}
          inProgress={createStampRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
