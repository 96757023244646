import React from 'react';
import Box from '@material-ui/core/Box';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useForm } from '@top-solution/use-form';
import { Qualification } from '../../../../entities/Qualification';
import { AbstractRequirement } from '../../../../entities/Requirement';
import validate from '../../../../utils/validate';
import QualificationSelect from '../../../inputs/QualificationSelect';
import RequirementSelect from '../../../inputs/RequirementSelect';

export type SearchRequirementExpirationFormData = {
  requirement: AbstractRequirement;
  qualification: Qualification | null;
};

const schema = validate.object().shape({
  requirement: validate.object(),
  qualification: validate
    .mixed<Qualification>()
    .when('requirement', (requirement, schema) => (requirement?.needsCode ? schema.required() : schema)),
});

type SearchRequirementExpirationFormProps = {
  initialValues: SearchRequirementExpirationFormData;
  onSubmit: (data: SearchRequirementExpirationFormData) => void;
  isLoading: boolean;
};

export function SearchRequirementExpirationForm(props: SearchRequirementExpirationFormProps): JSX.Element {
  const { form, setTouched, setValue, onSubmit } = useForm<SearchRequirementExpirationFormData>({
    schema,
    initialValues: props.initialValues,
    handleSubmit: props.onSubmit,
  });

  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <RequirementSelect
          label="Requisito da aggiornare"
          value={form.values.requirement}
          onChange={(value) => {
            setValue(value, 'requirement');
            setValue(null, 'qualification');
          }}
          onBlur={() => setTouched('requirement')}
          error={form.errors.requirement && form.touched.requirement}
          helperText={form.errors.requirement && form.touched.requirement ? form.errors.requirement.message : undefined}
          style={{ maxWidth: 300 }}
        />
        {form.values.requirement?.needsCode && (
          <QualificationSelect
            label="Mansione"
            value={form.values.qualification}
            onChange={(value) => setValue(value, 'qualification')}
            onBlur={() => setTouched('qualification')}
            error={form.errors.qualification && form.touched.qualification}
            helperText={
              form.errors.qualification && form.touched.qualification ? form.errors.qualification.message : undefined
            }
          />
        )}
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ minWidth: '10ch' }}
          disabled={!form.isValid}
          pending={props.isLoading}
        >
          Cerca
        </LoadingButton>
      </Box>
    </form>
  );
}
