import { RequestError, RequestState } from '@top-solution/utils';
import { AbstractRequirement } from '../../entities/Requirement';

export const READ_REQUIREMENT_LIST_REQUEST = 'READ_REQUIREMENT_LIST_REQUEST';
export const READ_REQUIREMENT_LIST_SUCCESS = 'READ_REQUIREMENT_LIST_SUCCESS';
export const READ_REQUIREMENT_LIST_FAILURE = 'READ_REQUIREMENT_LIST_FAILURE';

const initialState = {
  list: [] as Array<AbstractRequirement>,
  requests: {
    readList: {
      inProgress: false,
      error: null,
      lastUpdate: null,
    } as RequestState,
  },
};

export type RequirementState = typeof initialState;

export interface ReadRequirementListRequestAction {
  type: typeof READ_REQUIREMENT_LIST_REQUEST;
}
export interface ReadRequirementListSuccessAction {
  type: typeof READ_REQUIREMENT_LIST_SUCCESS;
  list: Array<AbstractRequirement>;
}
export interface ReadRequirementListFailureAction {
  type: typeof READ_REQUIREMENT_LIST_FAILURE;
  error: RequestError;
}

type ActionType =
  | ReadRequirementListRequestAction
  | ReadRequirementListSuccessAction
  | ReadRequirementListFailureAction;

export default function requirementReducer(state = initialState, action: ActionType): RequirementState {
  switch (action.type) {
    case READ_REQUIREMENT_LIST_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    }
    case READ_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
