import { OpticalRequirement } from '../entities/Requirement';
import DigitalRosterRepository from './DigitalRosterRepository';

export default class OpticalRequirementRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/opticalRequirements', token);
  }

  async getList(): Promise<OpticalRequirement[]> {
    const { data } = await this.axios.get<OpticalRequirement[]>('/');
    return data;
  }
}
