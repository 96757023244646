import { AbstractRequirement } from '../entities/Requirement';
import DigitalRosterRepository from './DigitalRosterRepository';

type RequirementFromApi = Omit<AbstractRequirement, 'defaultNotifications'> & {
  defaultNotifications?: string;
};

export default class RequirementRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/requirements', token);
  }
  async getList(): Promise<Array<AbstractRequirement>> {
    const { data } = await this.axios.get<Array<RequirementFromApi>>('/');
    return data.map((requirement) => ({
      ...requirement,
      defaultNotifications:
        requirement.defaultNotifications && requirement.defaultNotifications.length > 0
          ? requirement.defaultNotifications?.split(';').map((email) => email.trim())
          : [],
    }));
  }
}
