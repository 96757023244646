import React, { useCallback, useMemo } from 'react';
import { endOfToday } from 'date-fns';
import { CheckBox, DatePicker } from '@top-solution/mui-inputs';

import { useForm } from '@top-solution/use-form';
import { AppSettings } from '../../../entities/CurrentUser';
import { Stamp, StampShape } from '../../../entities/Stamp';
import { useAppSettings } from '../../../hooks/store/useAppSettings';
import { usePerson } from '../../../hooks/store/usePerson';
import { formatISODate, defaultMinDate } from '../../../utils/date';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import StampSelect from '../../inputs/StampSelect';
import StampShapeSelect from '../../inputs/StampShapeSelect';

type FilterByShapeForm = {
  date: Date;
  shape: StampShape | null;
  stamp: Stamp | null;
  excludeEnded: boolean;
};

const schema = validate.object().shape({
  shape: validate.mixed().required(),
  stamp: validate.mixed().required(),
  date: validate.date().min(defaultMinDate).max(endOfToday()).required(),
  excludeEnded: validate.boolean(),
});

type FilterByShapeProps = {
  appSettings: AppSettings;
};

export default function FilterByShape(props: FilterByShapeProps): JSX.Element {
  const { appSettings } = props;
  const { queryPerson, queryPersonRequest } = usePerson();
  const { setAppSettings } = useAppSettings();

  const initialValues = useMemo(
    () => ({
      date: endOfToday(),
      shape: null,
      stamp: null,
      excludeEnded: appSettings.excludeEnded,
    }),
    [appSettings.excludeEnded]
  );

  const handleSubmit = useCallback(
    (values: FilterByShapeForm) => {
      queryPerson({
        stamp: values.stamp?.id,
        date: formatISODate(values.date),
        excludeEnded: values.excludeEnded,
      });
    },
    [queryPerson]
  );
  const { form, setTouched, setValue, replaceValues, onSubmit } = useForm<FilterByShapeForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleShapeChange = useCallback(
    (shape: StampShape | null) => {
      replaceValues({
        shape,
        stamp: null,
      });
    },
    [replaceValues]
  );

  return (
    <FilterForm onSubmit={onSubmit} request={queryPersonRequest} disabled={!form.isValid}>
      <StampShapeSelect
        value={form.values.shape}
        onChange={handleShapeChange}
        onBlur={() => setTouched('shape')}
        error={form.errors.shape && form.touched.shape}
        helperText={form.touched.shape && form.errors.shape?.message}
      />
      <StampSelect
        style={{ flexGrow: 1, maxWidth: '16ch' }}
        shape={form.values.shape}
        value={form.values.stamp}
        onChange={(value) => setValue(value, 'stamp')}
        onBlur={() => setTouched('stamp')}
        error={form.errors.stamp && form.touched.stamp}
        helperText={form.touched.stamp && form.errors.stamp?.message}
      />
      <DatePicker
        label="In possesso il"
        value={form.values.date}
        onChange={(value) => setValue(value, 'date')}
        onBlur={() => setTouched('date')}
        error={form.errors.date && form.touched.date}
        helperText={form.errors.date?.message}
      />
      <CheckBox
        value={form.values.excludeEnded}
        onChange={(value) => {
          setValue(value, 'excludeEnded');
          setAppSettings({ ...appSettings, excludeEnded: value });
        }}
        label="Escludi congedati"
      />
    </FilterForm>
  );
}
