import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { endOfToday, subYears } from 'date-fns';
import { DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Plant } from '../../../entities/Plant';
import { AbstractRequirement } from '../../../entities/Requirement';
import { useExpiration } from '../../../hooks/store/useExpiration';
import { formatISODate, defaultMinDate } from '../../../utils/date';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PlantSelect from '../../inputs/PlantSelect';
import RequirementSelect from '../../inputs/RequirementSelect';
import { SubmitHandle } from '.';

type FilterByRequirementForm = {
  requirement: AbstractRequirement | null;
  from: Date;
  to: Date | null;
  plant: null | Plant;
};

const today = endOfToday();
const initialValues = {
  from: subYears(today, 1),
  to: null,
  requirement: null,
  plant: null as null | Plant,
};
const schema = validate.object().shape({
  requirement: validate.mixed().required(),
  from: validate
    .date()
    .min(defaultMinDate)
    .max(validate.ref('to'))
    .required()
    .when('to', (to: Date | null, schema: validate.DateSchema) => schema.max(to || today)),
  to: validate.date().min(validate.ref('from')).nullable(),
  plant: validate.mixed().nullable(),
});

export default forwardRef<SubmitHandle>(function FilterByProgram(_: unknown, ref) {
  const { queryExpiration, queryExpirationRequest } = useExpiration();
  const handleSubmit = useCallback(
    (values: FilterByRequirementForm) => {
      queryExpiration({
        requirementID: values.requirement?.id,
        from: formatISODate(values.from),
        to: values.to ? formatISODate(values.to) : undefined,
        plant: values.plant?.id,
      });
    },
    [queryExpiration]
  );

  const { form, setTouched, setValue, onSubmit } = useForm<FilterByRequirementForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  useImperativeHandle(ref, () => ({
    submit: onSubmit,
  }));

  return (
    <FilterForm
      onSubmit={onSubmit}
      request={queryExpirationRequest}
      disabled={!(form.values.requirement && form.values.from)}
    >
      <RequirementSelect
        label="Requisito"
        value={form.values.requirement}
        onChange={(value) => setValue(value, 'requirement')}
        onBlur={() => setTouched('requirement')}
        error={form.errors.requirement && form.touched.requirement}
        helperText={form.touched.requirement && form.errors.requirement?.message}
        style={{ flexGrow: 1, maxWidth: '36ch' }}
      />
      <PlantSelect
        value={form.values.plant}
        onChange={(value) => setValue(value, 'plant')}
        onBlur={() => setTouched('plant')}
        style={{ flexGrow: 1, maxWidth: '18ch' }}
      />
      <DatePicker
        label="Scadenze dal"
        value={form.values.from}
        onChange={(from) => setValue(from, 'from')}
        onBlur={() => setTouched('from')}
        error={form.errors.from && form.touched.from}
        helperText={form.touched.from && form.errors.from?.message}
      />
      <DatePicker
        label="Scadenze al"
        value={form.values.to}
        onChange={(to) => setValue(to, 'to')}
        onBlur={() => setTouched('to')}
        error={form.errors.to && form.touched.to}
        helperText={form.touched.to && form.errors.to?.message}
      />
    </FilterForm>
  );
});
