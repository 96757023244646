import React, { useCallback, useState, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from 'mdi-material-ui/Plus';
import { CheckBox } from '@top-solution/mui-inputs';

import { Person } from '../../../../entities/Person';
import { useAuth } from '../../../../hooks/store/useAuth';
import { usePerson } from '../../../../hooks/store/usePerson';
import { useTableHiddenColumns } from '../../../../hooks/store/useTableHiddenColumns';
import { useTableOptions, formatDateForSort, formatDateForDisplay } from '../../../../utils/table';
import StampShapeComponent from '../../../StampShape';
import AddStampDialog from '../../Stamps/AddStampDialog';

const useStyles = makeStyles((theme) => ({
  switch: {
    position: 'absolute',
    left: 32,
    bottom: 22,
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3 / 4),
    zIndex: 1,
  },
}));

type PersonStampsListProps = {
  person: Person;
};

export default function PersonStampsList(props: PersonStampsListProps): JSX.Element {
  const classes = useStyles();
  const { person } = props;
  const { isAdmin } = useAuth();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [showReturnedStamps, setShowReturnedStamps] = useState(false);
  const { readPersonDetails } = usePerson();
  const { hiddenColumns, setHiddenColumn } = useTableHiddenColumns('person_stamps_table');

  const handleAddDialogClick = useCallback(() => setAddDialogOpen(true), []);
  const handleAddDialogClose = useCallback(() => {
    setAddDialogOpen(false);
    readPersonDetails(person.id);
  }, [person.id, readPersonDetails]);

  const filteredPersonStamps = useMemo(
    () => (showReturnedStamps ? person.stampHistory : person.stampHistory?.filter(({ to }) => !to)) || [],
    [person, showReturnedStamps]
  );

  const { tableOptions } = useTableOptions(
    {
      elevation: 0,
      tableBodyMaxHeight: 'calc(100vh - 342px)',
      onViewColumnsChange: (column: string, action: string) => setHiddenColumn(column, action === 'remove'),
    },
    `${person.id} ${person.lastname} ${person.name} - export ricerca timbri.csv`
  );

  const columns = useMemo(() => {
    const columns = [
      { label: 'Tipo', name: 'type', options: { display: !hiddenColumns?.['type'] } },
      {
        label: 'Forma',
        name: 'shape',
        options: {
          display: !hiddenColumns?.['shape'],
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex: number) => (
            <StampShapeComponent shape={filteredPersonStamps[dataIndex].shape} />
          ),
        },
      },
      { label: 'Numero', name: 'code', options: { display: !hiddenColumns?.['code'] } },
      { label: 'Q.tà archivio', name: 'archivedQty', options: { display: !hiddenColumns?.['archivedQty'] } },
      { label: 'Q.tà assegnata', name: 'ownerQty', options: { display: !hiddenColumns?.['ownerQty'] } },
      {
        label: 'Data assegnazione',
        name: 'ownedFrom',
        options: {
          display: !hiddenColumns?.['ownedFrom'],
          customBodyRenderLite: (dataIndex: number) => formatDateForDisplay(filteredPersonStamps[dataIndex].from),
        },
      },
      {
        label: 'Data restituzione',
        name: 'ownedTo',
        options: {
          display: !hiddenColumns?.['ownedTo'],
          customBodyRenderLite: (dataIndex: number) => formatDateForDisplay(filteredPersonStamps[dataIndex].to),
        },
      },
      { label: 'Note', name: 'notes', options: { sort: false, display: !hiddenColumns?.['notes'] } },
    ];

    if (!isAdmin) {
      columns.splice(5, 2);
    }
    return columns;
  }, [filteredPersonStamps, hiddenColumns, isAdmin]);

  const data = useMemo(
    () =>
      filteredPersonStamps.map((stamp) => ({
        type: stamp.type.name,
        shape: stamp.shape.id,
        code: stamp.code,
        archivedQty: stamp.archivedQty,
        ownerQty: stamp.qty,
        ownedFrom: formatDateForSort(stamp.from),
        ownedTo: formatDateForSort(stamp.to),
        notes: stamp.notes || '–',
      })) || [],
    [filteredPersonStamps]
  );

  if (!person.stampHistory || person.stampHistory.length === 0) {
    return (
      <Alert severity="info" variant="outlined">
        Non sono stati trovati timbri per questo utente
      </Alert>
    );
  }

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAddDialogClick}
          className={classes.addButton}
        >
          Nuovo timbro
        </Button>
      )}
      {isAdmin && addDialogOpen && (
        <AddStampDialog open={addDialogOpen} handleClose={handleAddDialogClose} owner={person} />
      )}
      {!person.stampHistory || person.stampHistory.length === 0 ? (
        <Alert severity="info" variant="outlined">
          Non sono stati trovati timbri per questo utente
        </Alert>
      ) : (
        <MUIDataTable title="" columns={columns} data={data} options={tableOptions} />
      )}
      <CheckBox
        label="Mostra timbri restituiti"
        color="primary"
        className={classes.switch}
        name="showReturnedStamps"
        value={showReturnedStamps}
        onChange={(value) => setShowReturnedStamps(value)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </>
  );
}
