import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState, useResource } from '@top-solution/utils';
import PlantRepository from '../../api/PlantRepository';
import { Plant } from '../../entities/Plant';
import { RootState } from '../../store/reducers';
import {
  ReadPlantListFailureAction,
  ReadPlantListRequestAction,
  ReadPlantListSuccessAction,
  READ_PLANT_LIST_FAILURE,
  READ_PLANT_LIST_REQUEST,
  READ_PLANT_LIST_SUCCESS,
} from '../../store/reducers/plant';
import { useAuth } from './useAuth';

type UsePlant = {
  plantList: Plant[];
  readPlantListRequest: RequestState;
  readPlantList: () => Promise<void>;
};

export function usePlant(): UsePlant {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new PlantRepository(token), [token]);

  const plantList = useSelector((state: RootState) => state.plant.list);
  const readPlantListRequest = useSelector((state: RootState) => state.plant.requests.readList);

  const readPlantList = useCallback(() => {
    dispatch<ReadPlantListRequestAction>({ type: READ_PLANT_LIST_REQUEST });
    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadPlantListSuccessAction>({ type: READ_PLANT_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadPlantListFailureAction>({ type: READ_PLANT_LIST_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  return {
    plantList,
    readPlantListRequest,
    readPlantList,
  };
}

export function usePlantListResource(): [Plant[], RequestState, RequestError | null, () => void] {
  const { plantList, readPlantList, readPlantListRequest } = usePlant();

  return useResource<typeof plantList, RequestState>('PlantList', plantList, readPlantList, readPlantListRequest);
}
