import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestError, RequestState } from '@top-solution/utils';
import PeopleRepository, { PersonTraining, QueryPeopleParams } from '../../api/PeopleRepository';
import { Duty } from '../../entities/Duty';
import { Person } from '../../entities/Person';
import { PersonRequirement } from '../../entities/Requirement';
import { VisualRequirementVisit } from '../../entities/VisualRequirementVisit';
import { RootState } from '../../store/reducers';
import {
  CreatePersonClearAction,
  CreatePersonDutyClearAction,
  CreatePersonDutyFailureAction,
  CreatePersonDutyRequestAction,
  CreatePersonDutySuccessAction,
  CreatePersonFailureAction,
  CreatePersonRequestAction,
  CreatePersonRequirementClearAction,
  CreatePersonRequirementFailureAction,
  CreatePersonRequirementRequestAction,
  CreatePersonRequirementSuccessAction,
  CreatePersonSuccessAction,
  CREATE_PERSON_CLEAR,
  CREATE_PERSON_DUTY_CLEAR,
  CREATE_PERSON_DUTY_FAILURE,
  CREATE_PERSON_DUTY_REQUEST,
  CREATE_PERSON_DUTY_SUCCESS,
  CREATE_PERSON_FAILURE,
  CREATE_PERSON_REQUEST,
  CREATE_PERSON_REQUIREMENT_CLEAR,
  CREATE_PERSON_REQUIREMENT_FAILURE,
  CREATE_PERSON_REQUIREMENT_REQUEST,
  CREATE_PERSON_REQUIREMENT_SUCCESS,
  CREATE_PERSON_SUCCESS,
  DeletePersonDutyClearAction,
  DeletePersonDutyFailureAction,
  DeletePersonDutyRequestAction,
  DeletePersonDutySuccessAction,
  DeletePersonRequirementClearAction,
  DeletePersonRequirementFailureAction,
  DeletePersonRequirementRequestAction,
  DeletePersonRequirementSuccessAction,
  DELETE_PERSON_DUTY_CLEAR,
  DELETE_PERSON_DUTY_FAILURE,
  DELETE_PERSON_DUTY_REQUEST,
  DELETE_PERSON_DUTY_SUCCESS,
  DELETE_PERSON_REQUIREMENT_CLEAR,
  DELETE_PERSON_REQUIREMENT_FAILURE,
  DELETE_PERSON_REQUIREMENT_REQUEST,
  DELETE_PERSON_REQUIREMENT_SUCCESS,
  QueryPersonClearAction,
  QueryPersonFailureAction,
  QueryPersonRequestAction,
  QueryPersonSuccessAction,
  QUERY_PERSON_CLEAR,
  QUERY_PERSON_FAILURE,
  QUERY_PERSON_REQUEST,
  QUERY_PERSON_SUCCESS,
  ReadPersonDetailsFailureAction,
  ReadPersonDetailsRequestAction,
  ReadPersonDetailsSuccessAction,
  ReadPersonListClearAction,
  ReadPersonListFailureAction,
  ReadPersonListRequestAction,
  ReadPersonListSuccessAction,
  ReadPersonTrainingListClearAction,
  ReadPersonTrainingListFailureAction,
  ReadPersonTrainingListRequestAction,
  ReadPersonTrainingListSuccessAction,
  ReadPersonVisitPrescriptionListFailureAction,
  ReadPersonVisitPrescriptionListRequestAction,
  ReadPersonVisitPrescriptionListSuccessAction,
  READ_PERSON_DETAILS_FAILURE,
  READ_PERSON_DETAILS_REQUEST,
  READ_PERSON_DETAILS_SUCCESS,
  READ_PERSON_LIST_CLEAR,
  READ_PERSON_LIST_FAILURE,
  READ_PERSON_LIST_REQUEST,
  READ_PERSON_LIST_SUCCESS,
  READ_PERSON_LIST_TRAINING_CLEAR,
  READ_PERSON_LIST_TRAINING_FAILURE,
  READ_PERSON_LIST_TRAINING_REQUEST,
  READ_PERSON_LIST_TRAINING_SUCCESS,
  READ_PERSON_VISIT_PRESCRIPTION_FAILURE,
  READ_PERSON_VISIT_PRESCRIPTION_REQUEST,
  READ_PERSON_VISIT_PRESCRIPTION_SUCCESS,
  UpdatePersonClearAction,
  UpdatePersonDutyClearAction,
  UpdatePersonDutyFailureAction,
  UpdatePersonDutyRequestAction,
  UpdatePersonDutySuccessAction,
  UpdatePersonFailureAction,
  UpdatePersonIDClearAction,
  UpdatePersonIDFailureAction,
  UpdatePersonIDRequestAction,
  UpdatePersonIDSuccessAction,
  UpdatePersonRequestAction,
  UpdatePersonRequirementClearAction,
  UpdatePersonRequirementFailureAction,
  UpdatePersonRequirementRequestAction,
  UpdatePersonRequirementSuccessAction,
  UpdatePersonSuccessAction,
  UPDATE_PERSON_CLEAR,
  UPDATE_PERSON_DUTY_CLEAR,
  UPDATE_PERSON_DUTY_FAILURE,
  UPDATE_PERSON_DUTY_REQUEST,
  UPDATE_PERSON_DUTY_SUCCESS,
  UPDATE_PERSON_FAILURE,
  UPDATE_PERSON_ID_CLEAR,
  UPDATE_PERSON_ID_FAILURE,
  UPDATE_PERSON_ID_REQUEST,
  UPDATE_PERSON_ID_SUCCESS,
  UPDATE_PERSON_REQUEST,
  UPDATE_PERSON_REQUIREMENT_CLEAR,
  UPDATE_PERSON_REQUIREMENT_FAILURE,
  UPDATE_PERSON_REQUIREMENT_REQUEST,
  UPDATE_PERSON_REQUIREMENT_SUCCESS,
  UPDATE_PERSON_SUCCESS,
} from '../../store/reducers/person';
import { useAuth } from './useAuth';

export type UsePerson = {
  personList: Person[];
  personTrainingList: PersonTraining[];
  personDetails: Person | null;
  queryPersonResults: Person[];
  personVisitPrescriptionList: VisualRequirementVisit[];
  readPersonDetailsRequest: {
    id: string | null;
    inProgress: boolean;
    error: RequestError | null;
    data: Person | null;
  };
  readPersonListRequest: RequestState;
  queryPersonRequest: {
    params: QueryPeopleParams;
    inProgress: boolean;
    error: RequestError | null;
    results: Person[];
  };
  updatePersonRequest: {
    id: string | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  createPersonRequest: {
    person: Person | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  createPersonRequirementRequest: {
    person: Person | null;
    requirement: PersonRequirement | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  updatePersonRequirementRequest: {
    person: Person | null;
    requirement: PersonRequirement | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  deletePersonRequirementRequest: {
    person: Person | null;
    requirement: PersonRequirement | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  createPersonDutyRequest: {
    person: Person | null;
    duty: Duty | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  updatePersonDutyRequest: {
    person: Person | null;
    duty: Duty | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  deletePersonDutyRequest: {
    person: Person | null;
    duty: Duty | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  readPersonVisitPrescriptionListRequest: {
    personId: string | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  readPersonTrainingListRequest: {
    inProgress: boolean;
    error: RequestError | null;
    lastUpdate: number | null;
  };
  updatePersonIDRequest: {
    person: Person | null;
    newID: string | null;
    inProgress: boolean;
    error: RequestError | null;
  };
  queryPerson: (params: QueryPeopleParams) => Promise<void>;
  readPersonList: () => Promise<void>;
  readPersonDetails: (id: string) => Promise<Person | undefined>;
  updatePerson: (person: Person) => Promise<Person | undefined>;
  createPerson: (person: Person) => Promise<Person | undefined>;
  updatePersonClear: () => void;
  updatePersonIDClear: () => void;
  createPersonClear: () => void;
  createPersonRequirement: (person: Person, requirement: PersonRequirement) => Promise<Person | undefined>;
  updatePersonRequirement: (person: Person, requirement: PersonRequirement) => Promise<Person | undefined>;
  deletePersonRequirement: (person: Person, requirement: PersonRequirement) => Promise<Person | undefined>;
  createPersonRequirementClear: () => void;
  updatePersonRequirementClear: () => void;
  deletePersonRequirementClear: () => void;
  createPersonDuty: (person: Person, duty: Duty) => Promise<Person | undefined>;
  updatePersonDuty: (person: Person, duty: Duty) => Promise<Person | undefined>;
  deletePersonDuty: (person: Person, duty: Duty) => Promise<Person | undefined>;
  createPersonDutyClear: () => void;
  updatePersonDutyClear: () => void;
  deletePersonDutyClear: () => void;
  readPersonVisitPrescriptionList: (personId: string) => Promise<VisualRequirementVisit[] | undefined>;
  readPersonTrainingList: () => Promise<PersonTraining[] | undefined>;
  readPersonTrainingListClear: () => void;
  updatePersonID: (person: Person, newId: string) => Promise<Person | undefined>;
};

export function usePerson(): UsePerson {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const api = useMemo(() => new PeopleRepository(token), [token]);

  const personList = useSelector((state: RootState) => state.person.list);
  const personTrainingList = useSelector((state: RootState) => state.person.requests.training.list);
  const personDetails = useSelector((state: RootState) => state.person.requests.readDetails.data);
  const queryPersonResults = useSelector((state: RootState) => state.person.requests.query.results);
  const personVisitPrescriptionList = useSelector(
    (state: RootState) => state.person.requests.readPersonVisitPrescriptionList.visitPrescriptionList
  );

  const readPersonDetailsRequest = useSelector((state: RootState) => state.person.requests.readDetails);
  const readPersonListRequest = useSelector((state: RootState) => state.person.requests.readList);
  const readPersonTrainingListRequest = useSelector((state: RootState) => state.person.requests.training);
  const queryPersonRequest = useSelector((state: RootState) => state.person.requests.query);
  const updatePersonRequest = useSelector((state: RootState) => state.person.requests.update);
  const createPersonRequest = useSelector((state: RootState) => state.person.requests.create);
  const createPersonRequirementRequest = useSelector(
    (state: RootState) => state.person.requests.createPersonRequirement
  );
  const updatePersonRequirementRequest = useSelector(
    (state: RootState) => state.person.requests.updatePersonRequirement
  );
  const deletePersonRequirementRequest = useSelector(
    (state: RootState) => state.person.requests.deletePersonRequirement
  );
  const createPersonDutyRequest = useSelector((state: RootState) => state.person.requests.createPersonDuty);
  const updatePersonDutyRequest = useSelector((state: RootState) => state.person.requests.updatePersonDuty);
  const deletePersonDutyRequest = useSelector((state: RootState) => state.person.requests.deletePersonDuty);
  const readPersonVisitPrescriptionListRequest = useSelector(
    (state: RootState) => state.person.requests.readPersonVisitPrescriptionList
  );
  const updatePersonIDRequest = useSelector((state: RootState) => state.person.requests.updateID);

  const updatePersonClear = useCallback(() => {
    dispatch<UpdatePersonClearAction>({ type: UPDATE_PERSON_CLEAR });
  }, [dispatch]);

  const createPersonClear = useCallback(() => {
    dispatch<CreatePersonClearAction>({ type: CREATE_PERSON_CLEAR });
  }, [dispatch]);

  const createPersonRequirementClear = useCallback(() => {
    dispatch<CreatePersonRequirementClearAction>({ type: CREATE_PERSON_REQUIREMENT_CLEAR });
  }, [dispatch]);

  const updatePersonRequirementClear = useCallback(() => {
    dispatch<UpdatePersonRequirementClearAction>({ type: UPDATE_PERSON_REQUIREMENT_CLEAR });
  }, [dispatch]);

  const deletePersonRequirementClear = useCallback(() => {
    dispatch<DeletePersonRequirementClearAction>({ type: DELETE_PERSON_REQUIREMENT_CLEAR });
  }, [dispatch]);

  const createPersonDutyClear = useCallback(() => {
    dispatch<CreatePersonDutyClearAction>({ type: CREATE_PERSON_DUTY_CLEAR });
  }, [dispatch]);

  const updatePersonDutyClear = useCallback(() => {
    dispatch<UpdatePersonDutyClearAction>({ type: UPDATE_PERSON_DUTY_CLEAR });
  }, [dispatch]);

  const deletePersonDutyClear = useCallback(() => {
    dispatch<DeletePersonDutyClearAction>({ type: DELETE_PERSON_DUTY_CLEAR });
  }, [dispatch]);

  const readPersonTrainingListClear = useCallback(() => {
    dispatch<ReadPersonTrainingListClearAction>({ type: READ_PERSON_LIST_TRAINING_CLEAR });
  }, [dispatch]);

  const updatePersonIDClear = useCallback(() => {
    dispatch<UpdatePersonIDClearAction>({ type: UPDATE_PERSON_ID_CLEAR });
  }, [dispatch]);

  const readPersonList = useCallback(() => {
    dispatch<ReadPersonListRequestAction>({ type: READ_PERSON_LIST_REQUEST });

    const read = async () => {
      try {
        const list = await api.getList();
        dispatch<ReadPersonListSuccessAction>({ type: READ_PERSON_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadPersonListFailureAction>({ type: READ_PERSON_LIST_FAILURE, error: new RequestError(error) });
      }
    };
    return read();
  }, [api, dispatch]);

  const readPersonTrainingList = useCallback(() => {
    dispatch<ReadPersonTrainingListRequestAction>({ type: READ_PERSON_LIST_TRAINING_REQUEST });

    const read = async () => {
      try {
        const list = await api.getTraining();
        dispatch<ReadPersonTrainingListSuccessAction>({ type: READ_PERSON_LIST_TRAINING_SUCCESS, list });
        return list;
      } catch (error) {
        dispatch<ReadPersonTrainingListFailureAction>({
          type: READ_PERSON_LIST_TRAINING_FAILURE,
          error: new RequestError(error),
        });
      }
    };
    return read();
  }, [api, dispatch]);

  const queryPerson = useCallback(
    (params: QueryPeopleParams) => {
      dispatch<QueryPersonRequestAction>({ type: QUERY_PERSON_REQUEST, params });

      const read = async () => {
        try {
          const results = await api.query(params);
          dispatch<QueryPersonSuccessAction>({ type: QUERY_PERSON_SUCCESS, results });
        } catch (error) {
          dispatch<QueryPersonFailureAction>({ type: QUERY_PERSON_FAILURE, error: new RequestError(error) });
        }
      };

      return read();
    },
    [api, dispatch]
  );

  const readPersonDetails = useCallback(
    (id: string) => {
      dispatch<ReadPersonDetailsRequestAction>({ type: READ_PERSON_DETAILS_REQUEST, id });

      const read = async () => {
        try {
          const person = await api.getDetails(id);
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person });
          return person;
        } catch (error) {
          dispatch<ReadPersonDetailsFailureAction>({
            type: READ_PERSON_DETAILS_FAILURE,
            error: new RequestError(error),
          });
        }
      };

      return read();
    },
    [api, dispatch]
  );

  const updatePerson = useCallback(
    (person: Person) => {
      dispatch<UpdatePersonClearAction>({ type: UPDATE_PERSON_CLEAR });
      dispatch<UpdatePersonRequestAction>({ type: UPDATE_PERSON_REQUEST, id: person.id });

      const create = async () => {
        try {
          const data = await api.update(person);
          dispatch<UpdatePersonSuccessAction>({ type: UPDATE_PERSON_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          readPersonTrainingListClear();
          return data;
        } catch (error) {
          dispatch<UpdatePersonFailureAction>({ type: UPDATE_PERSON_FAILURE, error: new RequestError(error) });
          throw error;
        }
      };
      return create();
    },
    [api, dispatch, readPersonTrainingListClear]
  );

  const createPerson = useCallback(
    (person: Person) => {
      dispatch<CreatePersonClearAction>({ type: CREATE_PERSON_CLEAR });
      dispatch<CreatePersonRequestAction>({ type: CREATE_PERSON_REQUEST, person });

      const create = async () => {
        try {
          const data = await api.create(person);
          dispatch<CreatePersonSuccessAction>({ type: CREATE_PERSON_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          return data;
        } catch (error) {
          dispatch<CreatePersonFailureAction>({ type: CREATE_PERSON_FAILURE, error: new RequestError(error) });
        }
      };
      return create();
    },
    [api, dispatch]
  );

  const createPersonRequirement = useCallback(
    (person: Person, requirement: PersonRequirement) => {
      dispatch<CreatePersonRequirementClearAction>({ type: CREATE_PERSON_REQUIREMENT_CLEAR });
      dispatch<CreatePersonRequirementRequestAction>({ type: CREATE_PERSON_REQUIREMENT_REQUEST, person, requirement });

      const create = async () => {
        try {
          const data = await api.createRequirement(person, requirement);
          dispatch<CreatePersonRequirementSuccessAction>({ type: CREATE_PERSON_REQUIREMENT_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          return data;
        } catch (error) {
          dispatch<CreatePersonRequirementFailureAction>({
            type: CREATE_PERSON_REQUIREMENT_FAILURE,
            error: new RequestError(error),
          });
        }
      };
      return create();
    },
    [api, dispatch]
  );

  const updatePersonRequirement = useCallback(
    (person: Person, requirement: PersonRequirement) => {
      dispatch<UpdatePersonRequirementClearAction>({ type: UPDATE_PERSON_REQUIREMENT_CLEAR });
      dispatch<UpdatePersonRequirementRequestAction>({ type: UPDATE_PERSON_REQUIREMENT_REQUEST, person, requirement });

      const create = async () => {
        try {
          const data = await api.updateRequirement(person, requirement);
          dispatch<UpdatePersonRequirementSuccessAction>({ type: UPDATE_PERSON_REQUIREMENT_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          return data;
        } catch (error) {
          dispatch<UpdatePersonRequirementFailureAction>({
            type: UPDATE_PERSON_REQUIREMENT_FAILURE,
            error: new RequestError(error),
          });
          throw error;
        }
      };
      return create();
    },
    [api, dispatch]
  );

  const deletePersonRequirement = useCallback(
    (person: Person, requirement: PersonRequirement) => {
      dispatch<DeletePersonRequirementClearAction>({ type: DELETE_PERSON_REQUIREMENT_CLEAR });
      dispatch<DeletePersonRequirementRequestAction>({ type: DELETE_PERSON_REQUIREMENT_REQUEST, person, requirement });

      const create = async () => {
        try {
          const data = await api.deleteRequirement(person, requirement);
          dispatch<DeletePersonRequirementSuccessAction>({ type: DELETE_PERSON_REQUIREMENT_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          return data;
        } catch (error) {
          dispatch<DeletePersonRequirementFailureAction>({
            type: DELETE_PERSON_REQUIREMENT_FAILURE,
            error: new RequestError(error),
          });
        }
      };
      return create();
    },
    [api, dispatch]
  );

  const createPersonDuty = useCallback(
    (person: Person, duty: Duty) => {
      dispatch<CreatePersonDutyClearAction>({ type: CREATE_PERSON_DUTY_CLEAR });
      dispatch<CreatePersonDutyRequestAction>({ type: CREATE_PERSON_DUTY_REQUEST, person, duty });

      const create = async () => {
        try {
          const data = await api.createDuty(person, duty);
          dispatch<CreatePersonDutySuccessAction>({ type: CREATE_PERSON_DUTY_SUCCESS, person: data });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          if (duty.training) {
            readPersonTrainingListClear();
          }
          return data;
        } catch (error) {
          dispatch<CreatePersonDutyFailureAction>({ type: CREATE_PERSON_DUTY_FAILURE, error: new RequestError(error) });
        }
      };
      return create();
    },
    [api, dispatch, readPersonTrainingListClear]
  );

  const updatePersonDuty = useCallback(
    (person: Person, duty: Duty) => {
      dispatch<UpdatePersonDutyClearAction>({ type: UPDATE_PERSON_DUTY_CLEAR });
      dispatch<UpdatePersonDutyRequestAction>({ type: UPDATE_PERSON_DUTY_REQUEST, person, duty });

      const update = async () => {
        try {
          const data = await api.updateDuty(person, duty);

          dispatch<UpdatePersonDutySuccessAction>({ type: UPDATE_PERSON_DUTY_SUCCESS, duty });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          readPersonTrainingListClear();
          return data;
        } catch (error) {
          dispatch<UpdatePersonDutyFailureAction>({ type: UPDATE_PERSON_DUTY_FAILURE, error: new RequestError(error) });
        }
      };
      return update();
    },
    [api, dispatch, readPersonTrainingListClear]
  );

  const deletePersonDuty = useCallback(
    (person: Person, duty: Duty) => {
      dispatch<DeletePersonDutyClearAction>({ type: DELETE_PERSON_DUTY_CLEAR });
      dispatch<DeletePersonDutyRequestAction>({ type: DELETE_PERSON_DUTY_REQUEST, person, duty });

      const deleteDuty = async () => {
        try {
          const data = await api.deleteDuty(person, duty);

          dispatch<DeletePersonDutySuccessAction>({ type: DELETE_PERSON_DUTY_SUCCESS, duty });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          if (duty.training) {
            readPersonTrainingListClear();
          }
          return data;
        } catch (error) {
          dispatch<DeletePersonDutyFailureAction>({ type: DELETE_PERSON_DUTY_FAILURE, error: new RequestError(error) });
        }
      };
      return deleteDuty();
    },
    [api, dispatch, readPersonTrainingListClear]
  );

  const readPersonVisitPrescriptionList = useCallback(
    (personId: string) => {
      dispatch<ReadPersonVisitPrescriptionListRequestAction>({
        type: READ_PERSON_VISIT_PRESCRIPTION_REQUEST,
        personId,
      });

      const read = async () => {
        try {
          const visitPrescriptionList = await api.getVisitPrescription(personId);
          dispatch<ReadPersonVisitPrescriptionListSuccessAction>({
            type: READ_PERSON_VISIT_PRESCRIPTION_SUCCESS,
            visitPrescriptionList,
          });
          return personVisitPrescriptionList;
        } catch (error) {
          dispatch<ReadPersonVisitPrescriptionListFailureAction>({
            type: READ_PERSON_VISIT_PRESCRIPTION_FAILURE,
            error: new RequestError(error),
          });
        }
      };
      return read();
    },
    [api, dispatch, personVisitPrescriptionList]
  );

  const updatePersonID = useCallback(
    (person: Person, newID: string) => {
      dispatch<UpdatePersonIDRequestAction>({
        type: UPDATE_PERSON_ID_REQUEST,
        person,
        newID,
      });

      const update = async () => {
        try {
          const data = await api.changeID(person, newID);

          dispatch<UpdatePersonIDSuccessAction>({ type: UPDATE_PERSON_ID_SUCCESS });
          dispatch<ReadPersonListClearAction>({ type: READ_PERSON_LIST_CLEAR });
          dispatch<QueryPersonClearAction>({ type: QUERY_PERSON_CLEAR });
          dispatch<ReadPersonDetailsSuccessAction>({ type: READ_PERSON_DETAILS_SUCCESS, person: data });
          return data;
        } catch (error) {
          dispatch<UpdatePersonIDFailureAction>({ type: UPDATE_PERSON_ID_FAILURE, error: new RequestError(error) });
        }
      };
      return update();
    },
    [api, dispatch]
  );

  return {
    personList,
    personDetails,
    readPersonListRequest,
    readPersonList,
    personVisitPrescriptionList,
    queryPersonResults,
    queryPersonRequest,
    queryPerson,
    readPersonDetailsRequest,
    readPersonDetails,
    updatePerson,
    updatePersonRequest,
    updatePersonClear,
    createPerson,
    createPersonRequest,
    createPersonClear,
    createPersonRequirement,
    createPersonRequirementRequest,
    createPersonRequirementClear,
    updatePersonRequirement,
    updatePersonRequirementRequest,
    updatePersonRequirementClear,
    deletePersonRequirement,
    deletePersonRequirementRequest,
    deletePersonRequirementClear,
    createPersonDuty,
    createPersonDutyRequest,
    createPersonDutyClear,
    updatePersonDuty,
    updatePersonDutyRequest,
    updatePersonDutyClear,
    deletePersonDuty,
    deletePersonDutyRequest,
    deletePersonDutyClear,
    readPersonVisitPrescriptionList,
    readPersonVisitPrescriptionListRequest,
    readPersonTrainingList,
    readPersonTrainingListRequest,
    personTrainingList,
    readPersonTrainingListClear,
    updatePersonIDRequest,
    updatePersonID,
    updatePersonIDClear,
  };
}

export function usePersonListResource(): [
  personList: UsePerson['personList'],
  readPersonListRequest: UsePerson['readPersonListRequest']
] {
  const { personList, readPersonList, readPersonListRequest } = usePerson();

  useEffect(() => {
    if (!readPersonListRequest.inProgress && !readPersonListRequest.error && !readPersonListRequest.lastUpdate) {
      readPersonList();
    }
  }, [readPersonList, readPersonListRequest.error, readPersonListRequest.inProgress, readPersonListRequest.lastUpdate]);

  return [personList, readPersonListRequest];
}

export function usePersonTrainingListResource(): [
  personTrainingList: UsePerson['personTrainingList'],
  readPersonTrainingListRequest: UsePerson['readPersonTrainingListRequest']
] {
  const { personTrainingList, readPersonTrainingListRequest, readPersonTrainingList } = usePerson();

  useEffect(() => {
    if (
      !readPersonTrainingListRequest.inProgress &&
      !readPersonTrainingListRequest.error &&
      !readPersonTrainingListRequest.lastUpdate
    ) {
      readPersonTrainingList();
    }
  }, [
    readPersonTrainingList,
    readPersonTrainingListRequest.error,
    readPersonTrainingListRequest.inProgress,
    readPersonTrainingListRequest.lastUpdate,
  ]);

  return [personTrainingList, readPersonTrainingListRequest];
}
