import React, { useMemo, useCallback, useState } from 'react';
import { startOfMonth, sub } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton, TextField, DatePicker, CheckBox } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Duty } from '../../../../entities/Duty';
import { Person } from '../../../../entities/Person';
import { Plant } from '../../../../entities/Plant';
import { Program } from '../../../../entities/Program';
import { PersonRequirement } from '../../../../entities/Requirement';
import { Skill } from '../../../../entities/Skill';
import { Stamp } from '../../../../entities/Stamp';
import { usePerson } from '../../../../hooks/store/usePerson';
import { useQualificationListResource } from '../../../../hooks/store/useQualification';
import Spacer from '../../../../utils/Spacer';
import validate from '../../../../utils/validate';
import { ErrorAlert } from '../../../Error';
import IsMilitarySelect from '../../../inputs/IsMilitarySelect';
import PlantSelect from '../../../inputs/PlantSelect';
import ProgramSelect from '../../../inputs/ProgramSelect';
import SkillSelect from '../../../inputs/SkillSelect';
import StampListSelect from '../../../inputs/StampListSelect';
import FormRow from '../../../layout/FormRow';
import PersonDutyRequirements from './PersonDutyRequirements';

type PersonDutyEditForm = {
  plant: Plant;
  programs: Program[];
  isMilitary: boolean | null;
  isProduction: boolean;
  isSafetyPart: boolean;
  training: boolean;
  stamp: Stamp | null;
  skills: Skill[];
  to: Date | null;
  notes: string;
};

type PersonDutyEditDialogProps = {
  open: boolean;
  handleClose: (submitted: boolean) => void;
  person: Person;
  duty: Duty;
};

const minDate = sub(startOfMonth(new Date()), { years: 1 });
const schema = validate.object().shape({
  plant: validate.mixed().required(),
  to: validate.date().min(minDate).nullable(),
  programs: validate.array().min(1).required(),
  isMilitary: validate.bool().nullable(),
  isSafetyPart: validate.bool().nullable(),
  isProduction: validate.bool().required(),
  training: validate.bool().required(),
});

export default function PersonDutyEditDialog(props: PersonDutyEditDialogProps): JSX.Element {
  const { duty, person, open, handleClose } = props;
  const { updatePersonDuty, updatePersonDutyClear, updatePersonDutyRequest } = usePerson();
  const [selectedRequirements, setSelectedRequirements] = useState((duty.requirements || []) as PersonRequirement[]);
  const [fullfilledRequirements, setFullfilledRequirements] = useState(false);
  const [qualificationList] = useQualificationListResource();
  const initialValues = useMemo(
    () => ({
      plant: duty.plant,
      programs: duty.programs,
      isMilitary: duty.isMilitary === undefined ? null : duty.isMilitary,
      isProduction: duty.isProduction,
      isSafetyPart: duty.isSafetyPart,
      training: duty.training,
      stamp: duty.stamp || null,
      skills: duty.skills,
      to: duty.to || null,
      notes: duty.notes || '',
    }),
    [duty]
  );
  const handleSubmit = useCallback(
    async (values: PersonDutyEditForm) => {
      const result = await updatePersonDuty(person, {
        ...duty,
        plant: values.plant,
        programs: values.programs,
        isMilitary: values.isMilitary === null ? undefined : values.isMilitary,
        isProduction: values.isProduction,
        isSafetyPart: values.isSafetyPart,
        training: values.training,
        stamp: values.stamp || undefined,
        skills: values.skills,
        to: values.to || undefined,
        notes: values.notes,
        requirements: values.training ? undefined : selectedRequirements,
      });
      if (result?.id) {
        handleClose(true);
        updatePersonDutyClear();
      }
    },
    [updatePersonDuty, person, duty, selectedRequirements, handleClose, updatePersonDutyClear]
  );
  const { form, setValue, setTouched, onSubmit } = useForm<PersonDutyEditForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  const handleCancel = useCallback(
    (_: unknown, reason?: string) => {
      if (!reason) {
        handleClose(false);
        updatePersonDutyClear();
      }
    },
    [handleClose, updatePersonDutyClear]
  );

  const qualification = useMemo(
    () => qualificationList.find(({ id }) => id === duty.qualification?.id),
    [duty.qualification, qualificationList]
  );

  const handleChangeSelectedRequirements = useCallback(
    (selectedRequirements: PersonRequirement[], fullfilledRequirements: boolean) => {
      setSelectedRequirements(selectedRequirements);
      setFullfilledRequirements(fullfilledRequirements);
    },
    []
  );

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        {duty.qualification.name} – {person.name} {person.lastname}
      </DialogTitle>
      <DialogContent dividers>
        <FormRow>
          <PlantSelect
            value={form.values.plant}
            onChange={(value) => setValue(value, 'plant')}
            onBlur={() => setTouched('plant')}
            error={form.errors.plant && form.touched.plant}
            helperText={form.touched.plant && form.errors.plant?.message}
            required
          />
          <IsMilitarySelect
            value={form.values.isMilitary}
            onChange={(value) => setValue(value, 'isMilitary')}
            onBlur={() => setTouched('isMilitary')}
            error={form.errors.isMilitary && form.touched.isMilitary}
            helperText={form.touched.isMilitary && form.errors.isMilitary?.message}
          />
          <DatePicker label="Inizio validità" value={duty.from || null} disabled />
          <DatePicker
            label="Fine validità"
            value={form.values.to}
            minDate={minDate}
            onChange={(value) => setValue(value, 'to')}
            onBlur={() => setTouched('to')}
            error={Boolean(form.errors.to)}
            helperText={form.errors.to?.message}
          />
        </FormRow>
        <FormRow>
          <ProgramSelect
            multiple
            value={form.values.programs}
            onChange={(value) => setValue(value, 'programs')}
            onBlur={() => setTouched('programs')}
            error={form.errors.programs && form.touched.programs}
            helperText={form.touched.programs && form.errors.programs?.message}
            required
          />
        </FormRow>
        <FormRow>
          <CheckBox
            label="Solo produzione"
            color="primary"
            value={form.values.isProduction}
            onChange={(value) => setValue(value, 'isProduction')}
            onBlur={() => setTouched('isProduction')}
          />
          <CheckBox
            label="Safety part"
            color="primary"
            value={form.values.isSafetyPart}
            onChange={(value) => setValue(value, 'isSafetyPart')}
            onBlur={() => setTouched('isSafetyPart')}
          />
          <CheckBox
            label="In training"
            color="primary"
            value={form.values.training}
            onChange={(value) => setValue(value, 'training')}
            onBlur={() => setTouched('training')}
          />
        </FormRow>
        <FormRow>
          <SkillSelect
            multiple
            value={form.values.skills || []}
            onChange={(value) => setValue(value, 'skills')}
            onBlur={() => setTouched('skills')}
          />
        </FormRow>
        <FormRow>
          <StampListSelect
            stamps={person.stampHistory?.filter((record) => !record.to).map((record) => record as Stamp) || []}
            value={form.values.stamp}
            onChange={(value) => setValue(value, 'stamp')}
            onBlur={() => setTouched('stamp')}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Note"
            value={form.values.notes}
            onChange={(value) => setValue(value, 'notes')}
            onBlur={() => setTouched('notes')}
            disableClearable
          />
        </FormRow>
        {!form.values.training && qualification && (
          <PersonDutyRequirements
            person={person}
            qualification={qualification}
            isProduction={form.values.isProduction}
            isSafetyPart={form.values.isSafetyPart}
            selectedRequirements={selectedRequirements}
            onChange={handleChangeSelectedRequirements}
          />
        )}
        <ErrorAlert error={updatePersonDutyRequest.error} />
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button color="secondary" onClick={handleCancel}>
          Annulla
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!form.isValid || (!form.values.to && !form.values.training && !fullfilledRequirements)}
          inProgress={updatePersonDutyRequest.inProgress}
        >
          Salva
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
