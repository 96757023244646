import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { endOfToday, subYears } from 'date-fns';
import { DatePicker } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';

import { Person } from '../../../entities/Person';
import { useExpiration } from '../../../hooks/store/useExpiration';
import { formatISODate, defaultMinDate } from '../../../utils/date';
import validate from '../../../utils/validate';
import FilterForm from '../../FilterForm';
import PersonSelect from '../../inputs/PersonSelect';
import { SubmitHandle } from '.';

type FilterByPersonForm = {
  person: Person | null;
  from: Date;
  to: Date | null;
};

const today = endOfToday();
const initialValues = {
  from: subYears(today, 1),
  to: null,
  person: null,
};
const schema = validate.object().shape({
  person: validate.mixed().required(),
  from: validate
    .date()
    .min(defaultMinDate)
    .when('to', (to: Date | null, schema: validate.DateSchema) => (to ? schema.max(to) : schema))
    .required(),
  to: validate.date().min(validate.ref('from')).nullable(),
});

export default forwardRef<SubmitHandle>(function FilterByPerson(_: unknown, ref) {
  const { queryExpiration, queryExpirationRequest } = useExpiration();
  const handleSubmit = useCallback(
    (values: FilterByPersonForm) => {
      queryExpiration({
        personID: values.person?.id,
        from: formatISODate(values.from),
        to: values.to ? formatISODate(values.to) : undefined,
      });
    },
    [queryExpiration]
  );
  const { form, setTouched, setValue, onSubmit } = useForm<FilterByPersonForm>({
    initialValues,
    schema,
    handleSubmit,
  });

  useImperativeHandle(ref, () => ({
    submit: onSubmit,
  }));

  return (
    <FilterForm
      onSubmit={onSubmit}
      request={queryExpirationRequest}
      disabled={!(form.values.person && form.values.from)}
    >
      <PersonSelect
        label="Dipendente"
        value={form.values.person}
        style={{ flexGrow: 1, maxWidth: '50ch' }}
        onChange={(value) => setValue(value, 'person')}
        onBlur={() => setTouched('person')}
        error={form.errors.person && form.touched.person}
        helperText={form.touched.person && form.errors.person?.message}
      />
      <DatePicker
        label="Scadenze dal"
        value={form.values.from}
        onChange={(from) => setValue(from, 'from')}
        onBlur={() => setTouched('from')}
        error={form.errors.from && form.touched.from}
        helperText={form.touched.from && form.errors.from?.message}
      />
      <DatePicker
        label="Scadenze al"
        value={form.values.to}
        onChange={(to) => setValue(to, 'to')}
        onBlur={() => setTouched('to')}
        error={form.errors.to && form.touched.to}
        helperText={form.touched.to && form.errors.to?.message}
      />
    </FilterForm>
  );
});
