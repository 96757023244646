import React, { useMemo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';

import { Person } from '../../../entities/Person';
import { usePerson } from '../../../hooks/store/usePerson';
import { rosterSection as section } from '../../../utils/appSections';
import AppLayout from '../../layout/Layout';
import PersonRosterTable from '../../PersonRosterTable';
import RequestResult from '../../RequestResult';
import TabbedComponents from '../../TabbedComponents';
import FilterByPerson from './FilterByPerson';

const tableOptions = { tableBodyMaxHeight: 'calc(100vh - 360px)' };

export default function Roster(): JSX.Element {
  const { readPersonDetails, readPersonDetailsRequest, personDetails } = usePerson();

  const [from, setFrom] = useState(null as null | Date);
  const [to, setTo] = useState(null as null | Date);

  const onSubmit = useCallback(
    (person: Person, from: Date, to: Date) => {
      setFrom(from);
      setTo(to);
      readPersonDetails(person.id);
    },
    [readPersonDetails]
  );
  const tabs = useMemo(
    () => [{ title: 'Dipendente', slug: '/roster/by_person', children: <FilterByPerson onSubmit={onSubmit} /> }],
    [onSubmit]
  );

  const filteredPersonDetails = useMemo(() => {
    if (personDetails) {
      return {
        ...personDetails,
        roster: personDetails?.roster?.filter(
          (duty) => (!duty.from || !to || duty.from <= to) && (!duty.to || !from || duty.to >= from)
        ),
      };
    }
  }, [personDetails, from, to]);

  return (
    <AppLayout>
      <Helmet>
        <title>{section.fullName} | Digital Roster</title>
      </Helmet>
      <Container maxWidth={false}>
        <TabbedComponents components={tabs} />
        <RequestResult
          submitted={Boolean(readPersonDetailsRequest.id)}
          request={readPersonDetailsRequest}
          empty={!filteredPersonDetails?.roster?.length}
          emptyTitle="Nessun risultato"
          emptyDescription="Non sono state trovate abilitazioni per la persona selezionata"
        >
          {filteredPersonDetails && (
            <PersonRosterTable person={filteredPersonDetails} title={section.shortName} tableOptions={tableOptions} />
          )}
        </RequestResult>
      </Container>
    </AppLayout>
  );
}
