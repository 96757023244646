import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { parse } from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../../hooks/store/useAuth';
import Unauthorized from './Unauthorized';

type QueryParams = {
  token?: string;
  error?: string;
  path?: string;
};

export default function AuthCallback(): React.ReactElement {
  const { token, error, path } = parse(document.location.search) as QueryParams;
  const auth = useAuth();

  useEffect(() => {
    if (token && token !== auth.token) {
      auth.setToken(token);
    }
  }, [token, auth]);

  if (token === auth.token) {
    return <Redirect to={path || '/'} />;
  }

  if (error) {
    return <Unauthorized />;
  }

  return <CircularProgress color="secondary" />;
}
