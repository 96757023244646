import { RequestError, RequestState } from '@top-solution/utils';
import { StampSuspensionReason } from '../../entities/Stamp';

export const READ_STAMP_SUSPENSION_REASON_REQUEST = 'READ_STAMP_SUSPENSION_REASON_REQUEST';
export const READ_STAMP_SUSPENSION_REASON_SUCCESS = 'READ_STAMP_SUSPENSION_REASON_SUCCESS';
export const READ_STAMP_SUSPENSION_REASON_FAILURE = 'READ_STAMP_SUSPENSION_REASON_FAILURE';

const initialState = {
  list: [] as StampSuspensionReason[],
  requests: {
    readList: {
      inProgress: false,
      error: null,
      lastUpdate: null,
    } as RequestState,
  },
};

export type StampSuspensionReasonState = typeof initialState;

export interface ReadStampSuspensionReasonRequestAction {
  type: typeof READ_STAMP_SUSPENSION_REASON_REQUEST;
}
export interface ReadStampSuspensionReasonSuccessAction {
  type: typeof READ_STAMP_SUSPENSION_REASON_SUCCESS;
  list: Array<StampSuspensionReason>;
}
export interface ReadStampSuspensionReasonFailureAction {
  type: typeof READ_STAMP_SUSPENSION_REASON_FAILURE;
  error: RequestError;
}

type ActionType =
  | ReadStampSuspensionReasonRequestAction
  | ReadStampSuspensionReasonSuccessAction
  | ReadStampSuspensionReasonFailureAction;

export default function stampSuspensionReasonReducer(
  state = initialState,
  action: ActionType
): StampSuspensionReasonState {
  switch (action.type) {
    case READ_STAMP_SUSPENSION_REASON_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_STAMP_SUSPENSION_REASON_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date().getTime(),
          },
        },
      };
    }
    case READ_STAMP_SUSPENSION_REASON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
