import React from 'react';
import { Autocomplete, AutocompleteProps } from '@top-solution/mui-inputs';

import { Person } from '../../entities/Person';
import { usePersonListResource } from '../../hooks/store/usePerson';

type PersonSelectProps<DisableClearable extends boolean | undefined> = Omit<
  AutocompleteProps<string, Person, false, DisableClearable>,
  'options' | 'request'
>;

export default function PersonSelect<DisableClearable extends boolean | undefined>(
  props: PersonSelectProps<DisableClearable>
): JSX.Element {
  const { label, value, ...otherProps } = props;
  const [options, request] = usePersonListResource();

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      label={label || 'Mansione'}
      options={options}
      request={request}
      getOptionLabel={({ name, lastname, id }) => `${id} ${lastname} ${name}`}
    />
  );
}
