import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Select, SelectProps } from '@top-solution/mui-inputs';

import { StampShape } from '../../entities/Stamp';
import { useStampShapeListResource } from '../../hooks/store/useStampShape';
import StampShapeComponent from '../StampShape';

type StampShapeSelectProps = Omit<SelectProps<string, StampShape>, 'options' | 'request' | 'renderOption'>;

const useStyles = makeStyles((theme) => ({
  stampShapeSelect: {
    maxWidth: '70px',
    minWidth: '70px',
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1 / 2),
      paddingTop: theme.spacing(1 / 2),
      minHeight: theme.spacing(3),
    },
    '& .MuiInputBase-input svg': {
      margin: 0,
    },
  },
  stampShape: {
    width: '100%',
  },
}));

export default function StampShapeSelect(props: StampShapeSelectProps): JSX.Element {
  const classes = useStyles();
  const { className, label, disableClearable, ...otherProps } = props;
  const [options, request] = useStampShapeListResource();

  return (
    <Select
      {...otherProps}
      label={label || 'Forma'}
      className={clsx(className, classes.stampShapeSelect)}
      options={options}
      request={request}
      renderOption={(shape) => <StampShapeComponent shape={shape} className={classes.stampShape} />}
      disableClearable={disableClearable || true}
    />
  );
}
