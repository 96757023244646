import React from 'react';

import Alert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';

import { Stamp } from '../../../entities/Stamp';

const useStyles = makeStyles((theme) => ({
  alertTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    marginBottom: 0,
  },
  message: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(-1),
  },
  span: {
    whiteSpace: 'nowrap',
  },
}));

type StampSuspensionProps = {
  stamp: Stamp;
};

export default function StampSuspensionComponent(props: StampSuspensionProps): JSX.Element {
  const classes = useStyles();
  const { stamp } = props;

  return (
    <span className={classes.span}>
      {stamp.owner ? (
        `${stamp.owner.person.lastname} ${stamp.owner.person.name}`
      ) : stamp.suspension ? (
        <Alert severity="warning" className={classes.message}>
          <AlertTitle className={classes.alertTitle}>
            Timbro {stamp.suspension.reason.duration === -1 ? 'revocato' : 'sospeso'}
          </AlertTitle>
        </Alert>
      ) : (
        '–'
      )}
    </span>
  );
}
