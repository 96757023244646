export enum UserRole {
  ADMIN = 'admin',
}

export type User = {
  name: string;
  lastname: string;
  email: string;
  endAt: Date;
  id: number;
  serial: number;
};
