import { ObjectWithId } from '@top-solution/utils';
import {
  Stamp,
  StampAssignment,
  StampShape,
  StampSuspension,
  StampSuspensionReason,
  StampType,
} from '../entities/Stamp';
import { formatISODate, parseISODate } from '../utils/date';
import DigitalRosterRepository from './DigitalRosterRepository';
import { parsePersonResponse, PersonResponse } from './PeopleRepository';

export type QueryStampsParams = {
  owner?: string | null;
  from?: string | null;
  to?: string | null;
  shape?: string | null;
  code?: string | null;
};

type StampAssignmentResponse = {
  person: PersonResponse;
  qty: number;
  from?: string;
  to?: string;
  notes?: string;
};

type StampResponse = {
  id: number;
  code: string;
  shape: StampShape;
  type: StampType;
  owner?: StampAssignmentResponse;
  suspension?: {
    reason: StampSuspensionReason;
    from?: string;
    to?: string;
    notes?: string;
  };
  archivedQty: number;
  history?: Array<StampAssignmentResponse>;
};

type CreateStampPayload = {
  code: string;
  shapeID: string;
  typeID: number;
  owner?: {
    personID: string;
    from: string;
    qty: number;
    notes?: string;
  };
  archivedQty: number;
};

type SuspendStampPayload = {
  reason: number;
  from: string;
  notes?: string;
};

type AssignStampPayload = {
  person: string;
  from: string;
  qty: number;
  notes?: string;
};
interface UpdateStampPayload {
  owner?: {
    personID: string;
    from: string;
    qty: number;
    notes: string;
  };
  archivedQty: number;
}

function parseStampAssignmentResponse(assignment: StampAssignmentResponse): StampAssignment {
  return {
    ...assignment,
    person: parsePersonResponse(assignment.person),
    from: parseISODate(assignment.from),
    to: parseISODate(assignment.to),
  } as StampAssignment;
}

function parseStampPayload(stamp: StampResponse): Stamp {
  return {
    ...stamp,
    suspension: stamp.suspension
      ? {
          ...stamp.suspension,
          from: parseISODate(stamp.suspension.from),
          to: parseISODate(stamp.suspension.to),
        }
      : undefined,
    owner: stamp.owner ? parseStampAssignmentResponse(stamp.owner) : undefined,
    history: stamp.history ? stamp.history.map(parseStampAssignmentResponse) : undefined,
  } as Stamp;
}

export default class StampRepository extends DigitalRosterRepository {
  constructor(token: string) {
    super('/v1/stamps', token);
  }

  async getList(query: QueryStampsParams): Promise<Stamp[]> {
    let url = '/';
    let params;
    if (query.owner) {
      url = `/byOwner/${query.owner}`;
      params = {
        from: query.from,
        to: query.to,
      };
    } else if (query.shape) {
      url = `/byShape/${query.shape}`;
      params = {
        code: query.code,
      };
    }
    const { data } = await this.axios.get<Array<StampResponse>>(url, { params });
    return data.map(parseStampPayload);
  }

  async create(stamp: Stamp): Promise<ObjectWithId> {
    const payload = {
      code: stamp.code,
      shapeID: stamp.shape.id,
      typeID: stamp.type.id,
      owner: stamp.owner
        ? {
            personID: stamp.owner.person.id,
            from: formatISODate(stamp.owner?.from),
            qty: stamp.owner.qty,
            notes: stamp.owner.notes,
          }
        : undefined,
      archivedQty: stamp.archivedQty,
    } as CreateStampPayload;
    const { data } = await this.axios.post<ObjectWithId>('/', payload);
    return data;
  }

  async getDetails(stampId: number): Promise<Stamp> {
    const { data } = await this.axios.get<StampResponse>(`/${stampId}`);
    return parseStampPayload(data);
  }

  async suspend(stampId: number, suspension: StampSuspension): Promise<Stamp> {
    const { data } = await this.axios.put<StampResponse>(`/${stampId}/suspension`, {
      reason: suspension.reason.id,
      from: formatISODate(suspension.from),
      notes: suspension.notes,
    } as SuspendStampPayload);
    return parseStampPayload(data);
  }

  async assign(stampId: number, assignment: StampAssignment): Promise<Stamp> {
    const { data } = await this.axios.post<StampResponse>(`/${stampId}/assignments`, {
      person: assignment.person.id,
      qty: assignment.qty,
      from: formatISODate(assignment.from),
      notes: assignment.notes,
    } as AssignStampPayload);
    return parseStampPayload(data);
  }

  async update(stamp: Stamp): Promise<Stamp> {
    const { data } = await this.axios.patch<StampResponse>(`/${stamp.id}`, {
      archivedQty: stamp.archivedQty,
      owner: stamp.owner
        ? {
            personID: stamp.owner?.person.id,
            from: formatISODate(stamp.owner?.from),
            qty: stamp.owner.qty,
            notes: stamp.owner.notes,
          }
        : undefined,
    } as UpdateStampPayload);
    return parseStampPayload(data);
  }
}
