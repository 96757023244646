import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';

import { Stamp } from '../../entities/Stamp';
import TSInputProps from '../../entities/TSInputProps';
import StampShapeComponent from '../StampShape';

interface StampListSelectProps
  extends TSInputProps<Stamp, false, false>,
    Omit<StandardTextFieldProps, 'value' | 'name' | 'onChange' | 'onBlur'> {
  stamps: Stamp[];
}

export default function StampListSelect(props: StampListSelectProps): JSX.Element {
  const { label, name, value, onChange, onBlur, disabled, stamps, ...otherProps } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      onChange(stamps.find((stamp) => stamp.id === (event.target.value as number)) || null, name);
    }
  };

  return (
    <TextField
      select
      {...otherProps}
      label={label || 'Timbro'}
      value={value && stamps?.length > 0 ? value.id : ''}
      disabled={disabled || stamps?.length === 0}
      onChange={handleChange}
      onBlur={() => (onBlur ? onBlur(name) : undefined)}
      fullWidth
    >
      <MenuItem>&nbsp;</MenuItem>
      {stamps?.map((stamp) => (
        <MenuItem value={stamp.id} key={stamp.id}>
          <StampShapeComponent shape={stamp.shape} />
          <span>{stamp.code}</span>
        </MenuItem>
      ))}
    </TextField>
  );
}
